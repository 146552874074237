/* 
#########################
# Network Profile Dropdownn #
#########################
-   **Fetches based on**: Bearer token
-   **Inputs**:
    -   `selectedResourceGroupID`
        -   current selected resource group ID
    -   `setNetworkProfileID`
        -   Call back function to set the selected network profile ID
    -   `setError`
        -   Call back function to show error message while fetching list of network profiles
-   **Purpose**: Let users select and filter a network profile from a dropdown list
*/

// Libraries
import * as React from "react";
import { useEffect, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import FilterableDropdown from "./FilterableDropdown";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { NetworkProfile } from "../../../common/types";
import { useTranslation } from "react-i18next";
import useFetch from "../../Util/useFetch";
import { RequestLoggerEnum } from "../../../common/consts";

interface NetworkProfileDropdownProps {
    selectedResourceGroupID: string;
    virtualNetworkID: string;
    setNetworkProfileID: (networkProfileID: string) => void;
    setError: (error: any) => void;
}

const NetworkProfileDropdown = React.memo((props: NetworkProfileDropdownProps) => {
    const { t } = useTranslation();
    const { data: networkProfileList, loading: isNetworkProfilesLoading, error: networkProfileListError } = useFetch<NetworkProfile>(props.selectedResourceGroupID, RequestLoggerEnum.NetworkProfile_List);
    const networkProfiles = useMemo(() => networkProfileList || [], [networkProfileList]);

    const [selectedKey, setSelectedKey] = React.useState<string>("");

    useEffect(() => {
        if (networkProfileListError) {
            props.setError(networkProfileListError);
        }
    }, [networkProfileListError]); 

    useEffect(() => {
        props.setNetworkProfileID("");
        setSelectedKey("");
    }, [props.selectedResourceGroupID]); 

    // Build the set of dropdown items
    const options = useMemo(() => {
        const filteredNetworkProfiles = networkProfiles.filter(networkProfile =>
            (networkProfile.containerNetworkInterfaceConfigurations).indexOf(props.virtualNetworkID.toLowerCase()) != -1
        );

        filteredNetworkProfiles.sort((a,b)=> ( a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0));
        const items: IDropdownOption[] = [];

        for (const networkProfile of filteredNetworkProfiles) {
            items.push({
                key: networkProfile.id,
                text: networkProfile.name,
                data: networkProfile
            });
        }

        return items;
    }, [networkProfiles, props.virtualNetworkID]);

    return (
        <>
            <Shimmer isDataLoaded={!isNetworkProfilesLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={selectedKey}
                    onChange={(_event: React.FormEvent<HTMLDivElement>, networkProfileOption: any) => {
                        setSelectedKey(networkProfileOption.key as string);
                        props.setNetworkProfileID(networkProfileOption.key);
                    }}
                    placeholder={t("provideExistingNetworkProfile")}
                    disabled={props.selectedResourceGroupID === ""}
                    ariaLabel={t("networkProfileTitle")}
                />
            </Shimmer>
        </>
    );
});

NetworkProfileDropdown.displayName = "NetworkProfile-Dropdown"; // export name

export default NetworkProfileDropdown;
