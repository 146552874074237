import { useAccessTokenContext } from "../DataProvider/EventProvider";
import { getCloudName, guid } from "./Utilities";
import { useContext } from 'react';
import axios from "axios";
import { SessionIdContext } from "../DataProvider/IdProviders";

// Helper function to collect telemetry
async function collectTelemetry(armEndpoint: string, accessToken: string, data: object[], correlationId: string | null = null): Promise<void> {
    const response = await axios.post(`${armEndpoint}/providers/Internal.Telemetry/collect?api-version=2015-09-30-preview`, 
    JSON.stringify({ events: data }), 
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'x-ms-correlation-request-id': correlationId || guid()
        }
    }
    );
}

export function useLogger(armEndpoint: string, baseEventData: object = {}) {
  const { sessionId } = useContext(SessionIdContext);
  const { accessToken } = useAccessTokenContext();
  const cloud = getCloudName();
  
    const clientTelemetry = async (eventName: string, eventProperties: object, eventMeasurements: object, durationInMs: number, correlationId: string | null = null) => {
        const eventData = { ...eventProperties, ...eventMeasurements, ...baseEventData, cloudLocation: cloud };
        try {
            await collectTelemetry(
                armEndpoint,
                accessToken,
                [{
                    eventType: 'telemetry',
                    eventId: sessionId,
                    eventTimestamp: new Date().toISOString(),
                    eventName: 'ACC.UX2.' + eventName,
                    eventData: eventData,
                    durationInMilliseconds: durationInMs
                }],
                correlationId
            );
        } catch (e) {
            console.log(e);
        }
    };  
    
    const clientRequest = async (eventName: string, eventData: object, durationInMs: number, httpMethod: string, targetUri: string, apiVersion: string, clientRequestId: string, serviceRequestId: string, contentLength: number, httpStatusCode: number, correlationId: string | null = null) => {
        eventData = { ...eventData, ...baseEventData, cloudLocation: cloud };
        try {
            await collectTelemetry(
                armEndpoint,
                accessToken,
                [{
                    eventType: 'request',
                    eventId: sessionId,
                    eventTimestamp: new Date().toISOString(),
                    eventName: 'ACC.UX2.' + eventName,
                    eventData: eventData,
                    durationInMilliseconds: durationInMs,
                    httpMethod: httpMethod,
                    targetUri: targetUri,
                    apiVersion: apiVersion,
                    clientRequestId: clientRequestId,
                    serviceRequestId: serviceRequestId,
                    contentLength: contentLength,
                    httpStatusCode: httpStatusCode
                }],
                correlationId
            );
        } catch (e) {
            console.log(e);
        }
    };

    // Make sure to return the new functions from your hook
    return { clientTelemetry, clientRequest };
}