/* 
#########################
# File Share Dropdown #
#########################
-   **Allows user to create new element**: `true`
-   **Fetches based on**: Storage Account ID
-   **Inputs**:
    -   `isFileSharesLoading`
        -   Boolean to show or hide the loading animation
    -   `selectedStorageAccountID`
        -   current selected storage account
    -   `fileShareList`
        -   An ajax response json of file shares
     -   `fileShareID`
        -   the selected file share ID
    -   `setFileShareID`
        -   Call back function to set the selected file share ID
    -   `createdFileShare`
        -   the newly created storage account
    -   `setCreatedFileShare`
        -   Call back function to set the newly created storage account
-   **Purpose**: Let users select and filter a file share from a dropdown list and create a new file share if needed
*/

// Libraries
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import FilterableDropdown from "./FilterableDropdown";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { useNonInitialEffect } from "../../Util/useNonInitialEffect";
import { FileShare } from "../../../common/types";
import { useTranslation } from "react-i18next";
import { Link } from "@fluentui/react/lib/Link";
import { Callout } from "@fluentui/react/lib/Callout";
import { Separator } from "@fluentui/react/lib/Separator";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import useFetch from "../../Util/useFetch";
import { MountState, RequestLoggerEnum } from "../../../common/consts";
import { DelayedRender, Label, Text } from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";

interface FileShareDropdownProps {
    selectedStorageAccountID: string;
    setFileShareName: (fileShare: string) => void;
    updateDeploymentTypeAndShowError: (deploymentType: MountState, error: any) => void;
}

const CreateCallout = (props: {
    linkRef: any;
    dismiss: (fileShare?: string) => void;
    existingFileShareNames: string[];
}) => {
    // This function makes the visuals for the callout
    const { t } = useTranslation();
    const textFieldId = useId('fileShareInput');

    const [fileShareName, setFileShareName] = useState<string>("");
    const [valid, setValid] = useState<boolean>(false);
    // Input validation
    const validateFileShare = (
        existingFileShareNames: string[],
        fileShareName: string,
    ) => {
        if (fileShareName === "") {
            return "";
        }
        if (existingFileShareNames.includes(fileShareName)) {
            return t("fileShareExists");
        }
        return "";
    };

    return (
        <Callout
            preventDismissOnResize={true} // This prevents the callout from dismissing on resize for mobile
            target={props.linkRef} // Where the callout should appear
            onDismiss={() => props.dismiss()}
            style={{ maxWidth: "370px" }}
            setInitialFocus
            styles={{ root: { zIndex: 1000 } }}
            ariaLabel={t("fileShareCalloutARIALabel")}
            role="alert"
        >
            <div style={{ padding: "20px" }}>
                <div style={{ marginTop: '5px', marginBottom: '8px' }}>
                    <DelayedRender>
                        <Text variant="medium"> {t("fileShareDescription")}</Text>
                    </DelayedRender>
                </div>

                <Label htmlFor={textFieldId} required>{t("nameLabel")}</Label>
                <TextField
                    id={textFieldId}
                    aria-required
                    validateOnLoad={false}
                    ariaLabel={t("fileShareInputARIALabel")}
                    onChange={(_event, fileShareName) =>
                        setFileShareName(fileShareName || "")
                    }
                    onGetErrorMessage={() => {
                        const msg = validateFileShare(
                            props.existingFileShareNames,
                            fileShareName
                        );
                        if (msg === "") {
                            setValid(true);
                        } else {
                            setValid(false);
                        }
                        return msg;
                    }}
                />
            </div>
            <Separator styles={{ root: { padding: 0, height: "1px" } }} />
            <div style={{ padding: "20px" }}>
                <PrimaryButton
                    text="Ok"
                    disabled={!fileShareName || !valid}
                    style={{ marginRight: "10px" }}
                    onClick={() => props.dismiss(fileShareName)}
                />
                <DefaultButton text={t("cancelButton")} onClick={() => props.dismiss()} />
            </div>
        </Callout>
    );
};

const FileShareDropdown = React.memo((fileShareDropdownProps: FileShareDropdownProps) => {
    const { t } = useTranslation();
    const [linkRef, setLinkRef] = useState<HTMLElement | null>(null as unknown as HTMLElement);
    const { data: fileShareList, loading: isFileSharesLoading, error: fileShareListError } = useFetch<FileShare>(fileShareDropdownProps.selectedStorageAccountID || "", RequestLoggerEnum.FileShare_List);
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const fileShares = useMemo(() => fileShareList || [], [fileShareList]);
    const [createdFileShare, setCreatedFileShare] = useState<FileShare | null>(null);
    const [selectedKey, setSelectedKey] = React.useState<string>("");

    // Build the set of dropdown items
    const options = useMemo(() => {
        const items: IDropdownOption[] = [];

        for (const fileShare of fileShares) {
            items.push({
                key: fileShare.id,
                text: fileShare.name,
                data: fileShare
            });
        }

        // if there is newly created resource group, add it to the beginning of the dropdown
        if (createdFileShare) {
            items.unshift({
                key: createdFileShare.id,
                text: t("new") + " " + createdFileShare.name,
                data: createdFileShare
            });
        }

        return items;
    }, [fileShares, createdFileShare]);

    useEffect(() => {
        if (fileShareListError) {
            fileShareDropdownProps.updateDeploymentTypeAndShowError(MountState.AdvancedMount, fileShareListError);
        }
    }, [fileShareListError]); 

    useNonInitialEffect(() => {
        // Make sure we clear the previous selection for a new storage account ID
        setCreatedFileShare(null);
        fileShareDropdownProps.setFileShareName("");
        setSelectedKey("");
    }, [fileShareDropdownProps.selectedStorageAccountID]);

    return (
        <>
            <Shimmer isDataLoaded={!isFileSharesLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={selectedKey}
                    onChange={(_event: React.FormEvent<HTMLDivElement>, fileShareOption: any) => {
                        setSelectedKey(fileShareOption.key as string);
                        const fileShareName = fileShareList.find(fileShare => fileShare.id === fileShareOption.key)?.name || "";
                        fileShareDropdownProps.setFileShareName(fileShareName);
                    }}
                    placeholder={t("provideExistingFileShare")}
                    disabled={fileShareDropdownProps.selectedStorageAccountID === ""}
                    ariaLabel={t("fileShareLabel")}
                />
            </Shimmer>
            <Link
                onClick={() => setCalloutVisible(true)} 
                disabled={fileShareDropdownProps.selectedStorageAccountID === ""}
            >
                <span ref={setLinkRef}>{t("createFileShare")}</span>
            </Link>
            {calloutVisible && (
                <CreateCallout
                    dismiss={(createdFileShareName) => {
                        if (createdFileShareName) {
                            const createdFileShareId = `${fileShareDropdownProps.selectedStorageAccountID}/fileServices/default/shares/${createdFileShareName}`;
                            const createdFileShare = {
                                id: createdFileShareId,
                                name: createdFileShareName
                            };
                            setCreatedFileShare(createdFileShare);
                            setSelectedKey(createdFileShare.id);
                            fileShareDropdownProps.setFileShareName(createdFileShare.name);
                        }
                        setCalloutVisible(false);
                    }}
                    linkRef={linkRef}
                    existingFileShareNames={fileShares.map((fileShare) => fileShare.name)}
                />
            )}
        </>
    );
});

FileShareDropdown.displayName = "FileShare-Dropdown"; // export name

export default FileShareDropdown;
