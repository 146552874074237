/*
############################
# Deployment Error Dialog #
##################

-   **Inputs**:
    -   `currentTheme`
        -   Theme object
    -   `showOnboardingError`
        -   Boolean to hide and show
    -   `selectedSubscriptionName`
        -   Subscription name
    -   `selectedResourceGroup`
        -   Resource group name
    -   `storageAccountName`
        -   Storage account name
    -   `errorDetails`
        -   Error code and message
    -   `Quit`
        -  Show confirm exit dialog
    -   `goToPrevious`
        -  Go back to original page
-   **Purpose**: Show error messages while onboarding
*/

// External Components
import * as React from "react";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    Theme,
    Stack,
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useTranslation } from "react-i18next";
import { ErrorDetails, OnboardingInfo } from "../../common/types";
import { CorrelationIdContext } from "../DataProvider/IdProviders";
import { ReactComponent as ErrorIcon } from "../../common/images/Error.svg";
import { DeploymentInfo } from "../Onboarding/Dialog/DeploymentInProgress";
import { smallResolutionDialogStyles } from "../../common/styles";

// Interface
interface DeploymentErrorDialogProps {
    currentTheme: Theme;
    showOnboardingError: boolean; // variable to display or not
    selectedSubscriptionName?: string | undefined;
    onboardingInfo: OnboardingInfo | null;
    errorDetails: ErrorDetails;
    Quit: () => void; // call back function that can close the application
    goToPrevious: () => void;
}

// Component
const DeploymentErrorDialog = (deploymentErrorDialogProps: DeploymentErrorDialogProps) => {
    const { t } = useTranslation();
    const correlationId = React.useContext(CorrelationIdContext);

    const contentStyles = mergeStyleSets({
        container: {
            minWidth: 590,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            selectors: smallResolutionDialogStyles
        },
        header: [
            {
                flex: "1 1 auto",
                fontSize: 18,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "5px 5px 0px 24px",
            },
        ],
        body: {
            padding: "0 0px 0px 24px",
            overflowY: "hidden",
        },
    });

    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={"titleId"}
            isOpen={deploymentErrorDialogProps.showOnboardingError}
            onDismiss={deploymentErrorDialogProps.Quit}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={"titleId"}>
                    <ErrorIcon 
                        height={21} 
                        width={21} 
                        style={{
                            marginLeft: "2px",
                            marginRight: "10px",
                            marginTop: "2px",
                        }}
                    />
                    {t("deploymentErrorHeader")}
                </header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: deploymentErrorDialogProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            // change close icon when hovered
                            color: deploymentErrorDialogProps.currentTheme.semanticColors
                                .bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={deploymentErrorDialogProps.Quit}
                />
            </div>

            <div className={contentStyles.body}>
                <DeploymentInfo
                    selectedSubscriptionName={deploymentErrorDialogProps.selectedSubscriptionName}  
                    onboardingInfo={deploymentErrorDialogProps.onboardingInfo}
                />           
                <p style={{ marginTop: '3px', marginBottom: '5px' }}>
                    <span style={
                        {
                            fontWeight: FontWeights.semibold
                        }
                    }>
                        {t("errorDetails")}
                    </span>
                        {
                            deploymentErrorDialogProps.errorDetails.code !== "" &&
                            <>
                                <br></br>
                                {t("code")}: {deploymentErrorDialogProps.errorDetails.code}
                            </>
                        }
                        <>
                            <br></br>
                            {t("message")}: {deploymentErrorDialogProps.errorDetails.message || t("unknownFailureHeader") + t("unknownFailureContent")}
                        </>
                        <br></br>
                        {t("correlationId")}: {correlationId.correlationId}
                </p>
                <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { paddingBottom: 10 } }}>
                    <PrimaryButton
                            onClick={() => {
                                deploymentErrorDialogProps.Quit();
                            }}
                            text={t("closeButton")}
                    />   
                    <DefaultButton
                        onClick={() => {
                            deploymentErrorDialogProps.goToPrevious();
                        }}
                        text={t("previousButton")}
                    />
                </Stack>
            </div>
        </Modal>
    );
};

export default DeploymentErrorDialog;