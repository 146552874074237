/*
##################
# Advanced Mount #
##################

-   **Inputs**:
    -   `currentTheme`
        -   A theme object that contains the current colour variables
    -   `hideMountStorage`
        -   A boolean variable for displaying or hiding the `MountStorage` component
    -   `setMountState`
        -   Show a different modal
    -   `Quit`
        -   A call back function that can close the application
        -   It should be the same as `OSSelect`
-   **Purpose**: This component lets users select their setting in a first experience run.
*/

// Imported packages
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
    Modal,
    Stack,
    StackItem,
    Theme,
    IStackItemStyles,
    IStackTokens,
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";

// Handmade components
import SubscriptionDropdown from "../Selector/SubscriptionDropdown";
import ResourceGroupDropdown from "../Selector/ResourceGroupDropdown";
import { FileStorage, LocalStorageKey, MountState, NetworkType, SessionType } from "../../../common/consts";
import FormLabel from "../FormLabel";
import { useTranslation } from "react-i18next";
import { OnboardingInfo, ResourceGroup, StorageAccount, Subscription, VNetInfo, VirtualNetwork } from "../../../common/types";
import StorageAccountDropdown from "../Selector/StorageAccountDropdown";
import { contentStyles } from "./StorageCreation";
import { useContext, useMemo, useState } from "react";
import FileShareDropdown from "../Selector/FileShareDropdown";
import VNetOptions from "./VNetOptions";
import { UserSettingsContext } from "../../DataProvider/UserSettingsProvider";
import { addToLocalStorage } from "../../Util/Utilities";

// Interface components
interface MountStorageProps {
    currentTheme: Theme;
    isResourceGroupsLoading: boolean;
    setDeploymentType: (deploymentType: MountState) => void;
    showMountStorage: boolean; // variable to display or not
    subscriptionsList: Subscription[];
    resourceGroupList: ResourceGroup[];
    currentMountState: MountState;
    setMountState: (state: MountState) => void;
    Quit: () => void; // call back function that can close the application
    selectedResourceGroup: string;
    selectedSubscription: Subscription | null;
    setSelectedResourceGroup: (resourceGroup: string) => void;
    setSelectedSubscription: (subscription: Subscription | null) => void; 
    applyOnboardingInfo: (mountState: MountState, onboardingInfo: OnboardingInfo) => void;
    updateDeploymentTypeAndShowError: (deploymentType: MountState, error: any) => void;
}

const customStackItemStyles: Partial<IStackItemStyles> = {
    root: {
        // Make the items take up the full width of the parent by default
        flex: "1 1 100%",
        minWidth: 0,

        selectors: {
            // 600px is an arbitrary width, use whatever is best for your use case
            "@media screen and (min-width: 600px)": {
                // At larger viewport widths allow the items to be side-by-side
                flex: 1, // shorthand for '1 1 0%'
            },
        },
    },
};

const StorageProfileOptions = (props: {
    selectedResourceGroup: string,
    setSelectedStorageAccount: (storageAccount: StorageAccount | null) => void,
    selectedStorageAccount: StorageAccount | null,
    setFileShareName: (fileShare: string) => void,
    updateDeploymentTypeAndShowError: (deploymentType: MountState, error: any) => void;
}) => {
    // This function makes the visuals for the callout
    const { t } = useTranslation();

    return (
        <>
            <StackItem>
                <FormLabel
                    aria-label="storageAccountName"
                    displayValue={t("storageAccountName")}
                    required
                    nested
                >
                    <StorageAccountDropdown
                        selectedResourceGroup={props.selectedResourceGroup}
                        setSelectedStorageAccount={props.setSelectedStorageAccount} 
                        updateDeploymentTypeAndShowError={props.updateDeploymentTypeAndShowError}
                    />
                </FormLabel>
            </StackItem>
            <StackItem>
                <FormLabel
                    aria-label="fileShareName"
                    displayValue={t("fileShareLabel")}
                    required
                    nested
                >
                    <FileShareDropdown
                        selectedStorageAccountID={props.selectedStorageAccount?.id || ""}
                        setFileShareName={props.setFileShareName}
                        updateDeploymentTypeAndShowError={props.updateDeploymentTypeAndShowError} 
                    />
                </FormLabel>
            </StackItem>
        </>
    );
};

// Component
const AdvancedMount = (mountStorageProps: MountStorageProps) => {
    const titleId = useId("Advanced Mount");
    const { t } = useTranslation();
    const { userSettingsState } = useContext(UserSettingsContext);
    const [selectedStorageAccount, setSelectedStorageAccount] = useState<StorageAccount | null>(null);
    const [fileShareName, setFileShareName] = useState<string>("");
    const [virtualNetwork, setVirtualNetwork] = useState<VirtualNetwork | null>(null);
    const [networkProfileID, setNetworkProfileID] = useState<string>("");
    const [relayNamespaceID, setRelayNamespaceID] = useState<string>("");

    const isVNET = useMemo(() => userSettingsState.properties.networkType === NetworkType.Isolated, [userSettingsState.properties.networkType]);
    const isMounted = useMemo(() => userSettingsState.properties.sessionType === SessionType.Mounted, [userSettingsState.properties.sessionType]);

    const isSelectButtonDisabled = () => {
        const isSubAndRGEmpty = mountStorageProps.selectedSubscription === null || mountStorageProps.selectedResourceGroup === "";
        const isVNETOptionsEmpty = virtualNetwork === null || networkProfileID === "" || relayNamespaceID === "";
        const isStorageProfileEmpty = selectedStorageAccount == null || fileShareName === "";

        if (isMounted && isVNET) { // Mounted + VNET
            return isSubAndRGEmpty || isVNETOptionsEmpty || isStorageProfileEmpty;
        } else if (isVNET) { // Ephemeral + VNET
            return isSubAndRGEmpty || isVNETOptionsEmpty;
        } else { // Mounted + Default
            return isSubAndRGEmpty || isStorageProfileEmpty;
        }
    }

    const MountedDefaultStackTokens: IStackTokens = {
        childrenGap: "20",
        padding: "0px 60px 0px 0"
    };

    const VNETStackTokens: IStackTokens = {
        childrenGap: "0",
        padding: "0px 5px 0px 0"
    };
    
    const EphemeralStackTokens: IStackTokens = {
        childrenGap: "28",
        padding: "0px 20px 0px 0"
    };

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={mountStorageProps.showMountStorage}
            onDismiss={mountStorageProps.Quit}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={titleId}>{isVNET ? t("vnetSelectionTitle") : t("selectStorageAccount")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: mountStorageProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            color: mountStorageProps.currentTheme.semanticColors.bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={mountStorageProps.Quit}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack horizontal wrap>
                    <StackItem grow={1} styles={customStackItemStyles}>
                        <Stack tokens={!isMounted ? EphemeralStackTokens : isVNET ? VNETStackTokens : MountedDefaultStackTokens}>
                            <StackItem>
                                <FormLabel
                                    aria-label="subscriptionLabel"
                                    displayValue={t("subscriptionLabel")}
                                    required
                                    nested
                                >
                                    <SubscriptionDropdown
                                        subscriptionList={mountStorageProps.subscriptionsList}
                                        selectedSubscription={mountStorageProps.selectedSubscription}
                                        setSelectedSubscription={mountStorageProps.setSelectedSubscription}
                                    />
                                </FormLabel>
                            </StackItem>
                            <StackItem>
                                <FormLabel
                                    aria-label="resourceGroupLabel"
                                    displayValue={t("resourceGroupLabel")}
                                    required={true}
                                    disabled={false}
                                    nested
                                >
                                    <ResourceGroupDropdown
                                        allowRGCreation={false}
                                        isResourceGroupsLoading={mountStorageProps.isResourceGroupsLoading}
                                        resourceGroupList={mountStorageProps.resourceGroupList}
                                        selectedResourceGroupID={mountStorageProps.selectedResourceGroup}
                                        selectedSubscriptionID={mountStorageProps.selectedSubscription?.subscriptionId || ""}
                                        setSelectedResourceGroup={mountStorageProps.setSelectedResourceGroup}
                                    />
                                </FormLabel>
                            </StackItem>
                            {
                                isMounted && 
                                <StorageProfileOptions 
                                    selectedResourceGroup={mountStorageProps.selectedResourceGroup}
                                    setSelectedStorageAccount={setSelectedStorageAccount}
                                    selectedStorageAccount={selectedStorageAccount}
                                    setFileShareName={setFileShareName}
                                    updateDeploymentTypeAndShowError={mountStorageProps.updateDeploymentTypeAndShowError}
                                />
                            }
                        </Stack>
                    </StackItem>
                    {       
                        isVNET &&
                            <StackItem grow={1} styles={customStackItemStyles}>
                                <Stack tokens={isMounted ? VNETStackTokens : EphemeralStackTokens}>
                                    <VNetOptions 
                                        selectedResourceGroupID={mountStorageProps.selectedResourceGroup}
                                        virtualNetworkID={virtualNetwork?.id || ""}
                                        setVirtualNetwork={setVirtualNetwork}
                                        setNetworkProfileID={setNetworkProfileID}
                                        setRelayNamespaceID={setRelayNamespaceID}
                                        updateDeploymentTypeAndShowError={mountStorageProps.updateDeploymentTypeAndShowError}
                                    />
                                </Stack>
                            </StackItem>
                    }
                </Stack>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        marginTop: "5px",
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            const storageLocation = selectedStorageAccount?.location || "";
                            const storageAccountName = selectedStorageAccount?.name || "";
                            const diskSize = selectedStorageAccount?.sku.tier === FileStorage.Performance.Premium ? 100 : 5;

                            const onboardingInfo: OnboardingInfo = {
                                location: isVNET ? virtualNetwork!.location : storageLocation,
                                resourceGroupID: mountStorageProps.selectedResourceGroup,
                                fileShareName: fileShareName,
                                storageAccountName: storageAccountName,
                                diskSize: diskSize,
                            };

                            if (isVNET) {
                                const vNetInfo: VNetInfo = {
                                    networkProfileResourceId: networkProfileID,
                                    relayNamespaceResourceId: relayNamespaceID,
                                    location: virtualNetwork?.location || ""
                                }
                                onboardingInfo.vNetInfo = vNetInfo;
                                addToLocalStorage(LocalStorageKey.VirtualNetwork, virtualNetwork?.name || "");
                            } else {
                                addToLocalStorage(LocalStorageKey.VirtualNetwork, "");
                            }

                            mountStorageProps.applyOnboardingInfo(MountState.AdvancedMount, onboardingInfo);
                        }}
                        disabled={isSelectButtonDisabled()} 
                        text={t("select")}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={() => {
                            if (isVNET) {
                                mountStorageProps.setMountState(MountState.SubscriptionOnly);
                            } else {
                                mountStorageProps.setMountState(MountState.IntermediateMount);
                            }
                        }}
                        text={t("previousButton")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AdvancedMount;
