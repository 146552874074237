import * as React from "react";
import {
    StackItem,
    TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

import FormLabel from "../FormLabel";


interface FileShareProps {
    fileShare: string;
    setFileShare: (fileShare: string) => void;
}

// Component
const FileShare = (fileShareProps: FileShareProps) => {
    const { t } = useTranslation();

    return (
        <StackItem>
            <FormLabel
                aria-label="fileShareLabel"
                displayValue={t("fileShareLabel")}
                required
                nested={true}
            >
                <TextField
                    aria-required
                    id="fileShare"
                    ariaLabel={t("fileShareLabel")}
                    value={fileShareProps.fileShare}
                    onChange={(event) => fileShareProps.setFileShare((event.target as HTMLInputElement).value)}
                    placeholder={t("provideNewNameToCreate")}
                />
            </FormLabel>
        </StackItem>
    );
};

export default FileShare;

