import * as React from "react";
import {
    StackItem,
    TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

import FormLabel from "../FormLabel";
import { getARMEndpoint } from "../../Util/Utilities";
import { useAccessTokenContext } from "../../DataProvider/EventProvider";
import axios from "axios";

interface StorageAccountProps {
    selectedSubscriptionID: string;
    createdStorageAccount: string;
    setCreatedStorageAccount: (createdStorageAccount: string) => void;
    setValid: (valid: boolean) => void;
}

// Component
const StorageAccount = (storageAccountProps: StorageAccountProps) => {
    const { t } = useTranslation();
    const { accessToken } = useAccessTokenContext();
    const language = useTranslation().i18n.language;
    
    const getErrorMessage = (storageAccountName: string): Promise<string> => {
        // checks that the storage account name is valid and is not already in use
        const targetUri = `${getARMEndpoint()}/subscriptions/${storageAccountProps.selectedSubscriptionID}/providers/Microsoft.Storage/checkNameAvailability?api-version=2023-01-01`;

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language
        };

        return axios.post(targetUri, {
            name: storageAccountName,
            type: "Microsoft.Storage/storageAccounts",
        }, { headers })
        .then(res => {
            if (!res.data.nameAvailable) {
                storageAccountProps.setValid(false);
                return res.data.message;
            } else {
                storageAccountProps.setValid(true);
                return "";
            }
        }).catch(() => {
            // if the request fails, ARM will do additional checks
            storageAccountProps.setValid(true);
            return "";
        }); 
    };

    const onCreatedStorageAccountChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined
    ) => {
        storageAccountProps.setCreatedStorageAccount((event.target as HTMLInputElement).value);
    };

    return (
        <StackItem>
            <FormLabel
                aria-label="storageAccountName"
                displayValue={t("storageAccountName")}
                required
                nested
            >
                <TextField
                    id="createdStorageAccount"
                    aria-required
                    ariaLabel={t("storageAccountName")}
                    value={storageAccountProps.createdStorageAccount}
                    onChange={onCreatedStorageAccountChange}
                    placeholder={t("provideNewNameToCreate")}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false} //validate only on input change, not on render
                />
            </FormLabel>
        </StackItem>
    );
};

export default StorageAccount;