/*
#############
# VNetOptions #
#############
*/

import * as React from "react";
import { StackItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import FormLabel from "../FormLabel";
import VirtualNetworkDropdown from "../Selector/VirtualNetworkDropdown";
import NetworkProfileDropdown from "../Selector/NetworkProfileDropdown";
import RelayNamespaceDropdown from "../Selector/RelayNamespaceDropdown";
import { VirtualNetwork } from "../../../common/types";
import { MountState } from "../../../common/consts";
import { useEffect, useState } from "react";

// Interface
interface VNetOptionsProps {
    selectedResourceGroupID: string;
    virtualNetworkID: string;
    setVirtualNetwork: (virtualNetwork: VirtualNetwork | null) => void;
    setNetworkProfileID: (networkProfileID: string) => void;
    setRelayNamespaceID: (relayNamespaceID: string) => void;
    updateDeploymentTypeAndShowError: (deploymentType: MountState, error: any) => void;
}

// Components
const VNetOptions = (props: VNetOptionsProps) => {
    const { t } = useTranslation();
    const [error, setError] = useState<any>("");

    useEffect(() => {
        if (error) {
            props.updateDeploymentTypeAndShowError(MountState.AdvancedMount, error);
        }
    }, [error]); 

    return (
        <>
            <StackItem>
                <FormLabel
                    aria-label="virtualNetworkTitle"
                    displayValue={t("virtualNetworkTitle")}
                    required
                    nested
                >
                    <VirtualNetworkDropdown
                        selectedResourceGroupID={props.selectedResourceGroupID}
                        setVirtualNetwork={props.setVirtualNetwork}
                        setError={setError} />
                </FormLabel>
            </StackItem>
            <StackItem>
                <FormLabel
                    aria-label="networkProfileTitle"
                    displayValue={t("networkProfileTitle")}
                    required
                    nested
                >
                    <NetworkProfileDropdown
                        virtualNetworkID={props.virtualNetworkID}
                        selectedResourceGroupID={props.selectedResourceGroupID}
                        setNetworkProfileID={props.setNetworkProfileID}
                        setError={setError} />
                </FormLabel>
            </StackItem>
            <StackItem>
                <FormLabel
                    aria-label="relayNamespaceTitle"
                    displayValue={t("relayNamespaceTitle")}
                    required
                    nested
                >
                    <RelayNamespaceDropdown
                        selectedResourceGroupID={props.selectedResourceGroupID}
                        setRelayNamespaceID={props.setRelayNamespaceID}
                        setError={setError} />
                </FormLabel>
            </StackItem>
        </>
    );
};

export default VNetOptions;
