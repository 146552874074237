/* 
###########################
# Resource Group Dropdown #
###########################

-   **Based on**
    -   React View Subscription dropdown
        -   [Code](https://msazure.visualstudio.com/One/_git/AzureUX-PortalFx?path=/src/SDK/Framework.ReactView/azureportal-reactview/src/ResourceGroupDropdown.tsx&_a=contents&version=GBdev)
        -   [Demo](https://ms.portal.azure.com/?Microsoft_Azure_Playground=true#view/Microsoft_Azure_Playground/ReactControlsBlade/~/ResourceGroupDropdown)
-   **Uses user specific data**: `true`
-   **Allows user to create new element**: `true`
-   **Fetch based on**: Subscription ID
-   **Inputs**
    -   `allowRGCreation`
        -   Boolean to hide or show a href that presets a callout
    -   `isResourceGroupsLoading`
        -   Boolean to show or hide the loading animation
    -   `createdResourceGroup`
        -   The newly created resource group
    -   `setCreatedResourceGroup`
        -   Call back function to set the newly created resource group
    -   `resourceGroupList`
        -   An ajax response json of resource groups
    -   `selectedResourceGroupID`
        -   the selected resource group ID
    -   `selectedSubscriptionID`
        -   current selected subscription
    -   `setSelectedResourceGroup`
        -   Call back function to set the selected resource group ID
*/

// Libraries
import * as React from "react";
import { useMemo, useState } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Callout } from "@fluentui/react/lib/Callout";
import { Link } from "@fluentui/react/lib/Link";
import { TextField } from "@fluentui/react/lib/TextField";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { ResourceGroup } from "../../../common/types";
import { useTranslation } from "react-i18next";
import FilterableDropdown from "./FilterableDropdown";
import { useNonInitialEffect } from "../../Util/useNonInitialEffect";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { Label } from "@fluentui/react/lib/Label";
import { useId } from "@uifabric/react-hooks";
import { DelayedRender, Text } from "@fluentui/react";

interface ResourceGroupProps {
    allowRGCreation: boolean;
    isResourceGroupsLoading: boolean;
    createdResourceGroup?: ResourceGroup | null;
    setCreatedResourceGroup?: (createdResourceGroup: ResourceGroup | null) => void;
    resourceGroupList: ResourceGroup[];
    selectedResourceGroupID: string;
    selectedSubscriptionID: string;
    setSelectedResourceGroup: (location: string) => void;
}

const CreateCallout = (props: {
    linkRef: any;
    dismiss: (resourceGroup?: string) => void;
    subscriptionId: string;
    existingResourceGroupNames: string[];
}) => {
    // This function makes the visuals for the callout
    const { t } = useTranslation();
    const textFieldId = useId('RGInput');
    
    // Input validation
    const validateResourceGroup = (
        existingResourceGroupNames: string[],
        resourceGroupName: string
    ) => {
        if (resourceGroupName === "") {
            return "";
        }
        if (existingResourceGroupNames.includes(resourceGroupName)) {
            return t("resourceGroupExists");
        }
        return "";
    };

    const [resourceGroupName, setResourceGroupName] = useState<string>("");
    const [valid, setValid] = useState<boolean>(false);
    
    return (
        <Callout
            preventDismissOnResize={true} // This prevents the callout from dismissing on resize for mobile
            target={props.linkRef} // Where the callout should appear
            onDismiss={() => props.dismiss()}
            style={{ maxWidth: "370px" }}
            setInitialFocus
            styles={{ root: { zIndex: 1000 } }}
            ariaLabel={t("resourceGroupCalloutARIALabel")}
            role="alert"
        >
            <div style={{ padding: "20px" }}>
                <div style={{ marginTop: '5px', marginBottom: '8px' }}>
                    <DelayedRender>
                        <Text variant="medium"> {t("resourceGroupDefinition")}</Text>
                    </DelayedRender>
                </div>
                <Label htmlFor={textFieldId} required>{t("nameLabel")}</Label>
                <TextField
                    id={textFieldId}
                    aria-required
                    validateOnLoad={false}
                    ariaLabel={t("resourceGroupInputARIALabel")}
                    onChange={(_event, resourceGroupName) =>
                        setResourceGroupName(resourceGroupName || "")
                    }
                    onGetErrorMessage={() => {
                        const msg = validateResourceGroup(
                            props.existingResourceGroupNames,
                            resourceGroupName
                        );
                        if (msg === "") {
                            setValid(true);
                        } else {
                            setValid(false);
                        }
                        return msg;
                    }}
                />
            </div>
            <div style={{ padding: "20px" }}>
                <PrimaryButton
                    text="Ok"
                    disabled={!resourceGroupName || !valid}
                    style={{ marginRight: "10px" }}
                    onClick={() => props.dismiss(resourceGroupName)}
                />
                <DefaultButton text={t("cancelButton")} onClick={() => props.dismiss()} />
            </div>
        </Callout>
    );
};

const ResourceGroupDropdown = React.memo((props: ResourceGroupProps) => {
    const { t } = useTranslation();
    const [linkRef, setLinkRef] = useState<HTMLElement | null>(null as unknown as HTMLElement);
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const resourceGroups = useMemo(() => props.resourceGroupList || [], [props.resourceGroupList]);
    
    // Build the set of dropdown items
    const options = useMemo(() => {
        resourceGroups.sort((a,b)=> ( a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0));
        const items: IDropdownOption[] = [];
        const uniqueIds = new Set<string>();

        for (const resourceGroup of resourceGroups) {
            if (!uniqueIds.has(resourceGroup.id)) {
                uniqueIds.add(resourceGroup.id);
                items.push({
                    key: resourceGroup.id,
                    text: resourceGroup.name,
                    data: resourceGroup
                });
            }
        }

        // if there is newly created resource group, add it to the beginning of the dropdown
        if (props.createdResourceGroup) {
            items.unshift({
                key: props.createdResourceGroup.id,
                text: t("new") + " " + props.createdResourceGroup.name,
                data: props.createdResourceGroup
            });
        }
        return items;
    }, [resourceGroups, props.createdResourceGroup]);

    const tryOnChangeCall = (
        resourceGroup: any
    ) => {
        props.setSelectedResourceGroup(resourceGroup.key);
    };

    useNonInitialEffect(() => {
        // Make sure we clear the previous selection for a new subscription
        props.setCreatedResourceGroup && props.setCreatedResourceGroup(null);
        props.setSelectedResourceGroup("");
    }, [props.selectedSubscriptionID]);

    return (
        <>
            <Shimmer isDataLoaded={!props.isResourceGroupsLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={props.selectedResourceGroupID}
                    onChange={(_event, option) => {
                        tryOnChangeCall(option);
                    }}
                    ariaLabel={t("resourceGroupLabel")}
                />
            </Shimmer>
            {props.allowRGCreation && (
                <Link onClick={() => setCalloutVisible(true)}>
                    <span ref={setLinkRef}>{t("createResourceGroup")}</span>
                </Link>
            )}

            {calloutVisible && props.selectedSubscriptionID && (
                <CreateCallout
                    dismiss={(createdResourceGroupName) => {
                        if (createdResourceGroupName) {
                            const createdResourceGroupId = `/subscriptions/${props.selectedSubscriptionID}/resourceGroups/${createdResourceGroupName}`;
                            const createdResourceGroup = {
                                id: createdResourceGroupId,
                                location: "",
                                name: createdResourceGroupName
                            };
                            props.setCreatedResourceGroup && props.setCreatedResourceGroup(createdResourceGroup);
                            props.setSelectedResourceGroup(createdResourceGroup.id);
                        }
                        setCalloutVisible(false);
                    }}
                    linkRef={linkRef}
                    subscriptionId={props.selectedSubscriptionID}
                    existingResourceGroupNames={resourceGroups.map((rg: ResourceGroup) => rg.name)}
                />
            )}
        </>
    );
});

ResourceGroupDropdown.displayName = "ResourceGroup-Dropdown"; // export name

export default ResourceGroupDropdown;

