import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface SaveBeforeExitDialogProps {
    hideDialog: boolean;
    toggleDialog: () => void;
    onSave: () => void;
    onDontSave: () => void;
}

const SaveBeforeExitDialog = (props: SaveBeforeExitDialogProps) => {
    const { t } = useTranslation();
    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={props.toggleDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: t("closeEditorText"),
                subText: t("unsavedFileWarning"),
                closeButtonAriaLabel: t("cancelButton"),
                showCloseButton: true
            }}>
                <div data-testid="dirtyDialog"></div>
                <DialogFooter>
                    <PrimaryButton data-testid="saveButtonDirtyDialog" onClick={props.onSave} text={t("saveButton")} role="button" aria-label={t("saveButton")}/>
                    <DefaultButton data-testid="dontSaveButtonDirtyDialog" onClick={props.onDontSave} text={t("dontSaveButton")} role="button" aria-label={t("dontSaveButton")} />
                </DialogFooter>
            </Dialog>
    );
};

export default SaveBeforeExitDialog;