/*
#############
# CONFIRM RELOAD #
#############

Parent: `Session`
Purpose: User confirmation screen for reloading terminal for command injection  
Takes:
 - `hideConfirmReloadDialog`
 - `toggleConfirmReloadDialog`
 - `ChangeShellType`
Returns:
 - Makes a call to `toggleConfirmReloadDialog()` which hides and shows the dialog

*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { useParentMessageContext } from "../../DataProvider/EventProvider";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface ConfirmReloadDialogProps {
    // These are the call back functions and data from parent
    hideConfirmReloadDialog: boolean; // Variable for hiding or showing dialog
    toggleConfirmReloadDialog: () => void; // Callback function to hide and show
    reloadTerminal: () => void; // Callback function to reload terminal
}

// Component
const ConfirmReloadDialog = (confirmReloadDialogProps: ConfirmReloadDialogProps) => {
    const { t } = useTranslation();
    const labelId: string = useId("restartDialogLabel");
    const subTextId: string = useId("restartSubTextLabel");
    const { commands, setCommands } = useParentMessageContext();

    const modalProps = React.useMemo(
        // Overrides default dialog styles
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { minwidth: 450 } },
        }),
        [labelId, subTextId]
    );

    const handleConfirmReloadDialogDismissed = () => {
        confirmReloadDialogProps.toggleConfirmReloadDialog();
        setCommands("");
    }

    return (
        <>
            <Dialog
                hidden={confirmReloadDialogProps.hideConfirmReloadDialog} //variable to hide
                onDismiss={confirmReloadDialogProps.toggleConfirmReloadDialog} // function that hides
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("commandReloadDialogHeader"),
                    closeButtonAriaLabel: t("cancelButton"),
                    subText: t("commandReloadDialogMessage"),
                }}
                modalProps={modalProps}
            >
                <DialogFooter className={dialogFooterStyles}>
                    <PrimaryButton
                        onClick={confirmReloadDialogProps.reloadTerminal} // Callsback for restart, does not hide by itself
                        text={t("commandReloadButtonTitle")}
                    />
                    <DefaultButton
                        onClick={handleConfirmReloadDialogDismissed}
                        text={t("cancelButton")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ConfirmReloadDialog;
