import React from "react";

const EditorTitlebarMenuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" xmlSpace="preserve" width="100%" height="27">
            <defs>
                <style>
                    {`
                        .icon-canvas-transparent,
                        .icon-vs-out {
                            fill: #252526;
                        }

                        .icon-canvas-transparent {
                            opacity: 0;
                        }

                        .icon-vs-bg {
                            fill: #c5c5c5;
                        }
                    `}
                </style>
                <style>
                    {`
                        .icon-canvas-transparent,
                        .icon-vs-out {
                            fill: #252526;
                        }

                        .icon-canvas-transparent {
                            opacity: 0;
                        }

                        .icon-vs-bg {
                            fill: #c5c5c5;
                        }
                    `}   
                </style>
            </defs>
            <g id="canvas">
                <path className="icon-canvas-transparent" d="M16,0V16H0V0Z"/>
            </g>
            <g id="outline" style={{display: "none"}}>
                <path className="icon-vs-out" 
                        d="M6,7.5A2.5,2.5,0,1,1,3.5,5,2.5,2.5,0,0,1,6,7.5ZM8.5,5A2.5,2.5,0,1,0,11,7.5,2.5,2.5,0,0,0,8.5,5Zm5,0A2.5,2.5,0,1,0,16,7.5,2.5,2.5,0,0,0,13.5,5Z"
                        style={{display: "none"}}/>
            </g>
            <g id="iconBg">
                <path className="icon-vs-bg" d="M5,7.5A1.5,1.5,0,1,1,3.5,6,1.5,1.5,0,0,1,5,7.5ZM8.5,6A1.5,1.5,0,1,0,10,7.5,1.5,1.5,0,0,0,8.5,6Zm5,0A1.5,1.5,0,1,0,15,7.5,1.5,1.5,0,0,0,13.5,6Z"/>
            </g>
        </svg>
    );
};
export default EditorTitlebarMenuIcon;