import axios, { AxiosResponse } from 'axios';

export interface IHttpClient {
    get: <T>(url: string, headers: any) => Promise<T>;
    put: <T>(url: string, data: any, headers: any) => Promise<T>;
}

export class AxiosHttpClient implements IHttpClient {
    async get<AxiosResponse>(url: string, headers: any): Promise<AxiosResponse> {
        const response: AxiosResponse = await axios.get(url, headers);
        return response;
    }

    async put<AxiosResponse>(url: string, data: string, headers: any): Promise<AxiosResponse> {
        const response: AxiosResponse = await axios.put(url, JSON.stringify(data), headers);
        return response;
    }
}