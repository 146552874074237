/*
######################
# Subscription Mount #
######################

-   **Inputs**
    -   `currentTheme`
        -   A theme
    -   `showSubscriptionStorage`
    -   `mountSettings`
    -   `setMountState`
    -   `Quit`
-   **Purpose**: Lets a user select between an ephemeral or mounted session using only a subscription.
*/

// Imported packages
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    Stack,
    StackItem,
    Theme,
    Link,
    IChoiceGroupOption,
    ChoiceGroup,
    TooltipHost,
    Checkbox,
    MessageBar,
    MessageBarType,
    ITooltipProps,
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";

// Handmade components
import SubscriptionDropdown from "../Selector/SubscriptionDropdown";
import { CloudName, MountState, NetworkType, SessionType, SwitchToV1MethodEnum, TelemetryLoggerEnum, UserSettingsActionType } from "../../../common/consts";
import FormLabel from "../FormLabel";
import { useTranslation } from "react-i18next";
import { useLogger } from "../../Util/Logger";
import { UserSettingsContext } from "../../DataProvider/UserSettingsProvider";
import { getARMEndpoint, getCloudName } from "../../Util/Utilities";
import { Subscription, UserSetting } from "../../../common/types";
import { useCallback, useState } from "react";
import { smallResolutionDialogStyles } from "../../../common/styles";

// Interface components
interface SubscriptionMountProps {
    currentTheme: Theme;
    showSubscriptionStorage: boolean; // variable to display or not
    mountUserSettings: (userSettings: UserSetting) => void; // call back function which update usersettings
    setMountState: (state: MountState) => void; // call back function to advace mount state
    Quit: () => void; // call back function that can close the application
    selectedSubscription: Subscription | null;
    setSelectedSubscription: (subscription: Subscription | null) => void;
    setDeploymentType: (deploymentType: MountState) => void;
    subscriptionsList: Subscription[];
    setSwitchToV1Method: (switchToV1Method: string) => void;
}


// Component
const SubscriptionMount = (subscriptionMountProps: SubscriptionMountProps) => {
    const titleId = useId("Mount Storage");
    const { t } = useTranslation();
    const { userSettingsState, userSettingsDispatch } = React.useContext(UserSettingsContext);
    const [isVnet, setIsVnet] = useState(false);
    const [showSharedSubWarning, setShowSharedSubWarning] = useState(false);

    // Pivot info
    const contentStyles = mergeStyleSets({
        container: {
            maxHeight: 850,
            maxWidth: 800,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            selectors: smallResolutionDialogStyles,
        },
        header: [
            {
                flex: "1 1 auto",
                fontSize: 18,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "5px 5px 0px 24px",
            },
        ],
        body: {
            padding: "0 22px 0px 26px",
            overflowY: "hidden",
        },
    });

    const tooltipProps: ITooltipProps = {
        onRenderContent: () => (
        <span>
            {t("deleteAllFilesNote")}{' '}
            <Link href="https://aka.ms/CloudShell/docs/ephemeral" target="_blank">
                {t("learnMoreLink")}
            </Link>
          </span>
        ),
    };

    const mountOptions: IChoiceGroupOption[] = [
        {
            key: SessionType.Ephemeral,
            onRenderField: (props, render) => (
                <>
                    {render!(props)}
                    <TooltipHost tooltipProps={tooltipProps}>
                        <IconButton
                            ariaLabel={t("deleteAllFilesNote")}
                            iconProps={{
                                iconName: "info",
                                style: {
                                    fontSize: "12px",
                                    height: "12px",
                                    width: "12px",
                                    color: subscriptionMountProps.currentTheme.semanticColors
                                        .bodyText,
                                },
                            }}
                            styles={{
                                root: {
                                    height: "20px",
                                    selectors: {
                                        ":hover": {
                                            background: "none !important",
                                        },
                                    },
                                    width: "20px",
                                },
                            }}
                        />
                    </TooltipHost>
                </>
            ),
            text: t("noStorageAccountRequired"),
        },
        {
            key: SessionType.Mounted,
            onRenderField: (props, render) => (
                <>
                    {render!(props)}
                    <TooltipHost content={t("savedFilesBePersistedNote")}>
                        <IconButton
                            ariaLabel={t("savedFilesBePersistedNote")}
                            iconProps={{
                                iconName: "info",
                                style: {
                                    fontSize: "12px",
                                    height: "12px",
                                    width: "12px",
                                    color: subscriptionMountProps.currentTheme.semanticColors
                                        .bodyText,
                                },
                            }}
                            styles={{
                                root: {
                                    color: "gray",
                                    height: "20px",
                                    selectors: {
                                        ":hover": {
                                            background: "none !important",
                                        },
                                    },
                                    width: "20px",
                                },
                            }}
                        />
                    </TooltipHost>
                </>
            ),
            text: t("mountStorageAccount"),
        },
    ];
    const [selectedSessionType, setSessionType] = React.useState<string | undefined>(
        SessionType.Ephemeral
    );
    const displayValue = React.useMemo(() => {
        return selectedSessionType === SessionType.Mounted
        ? t("storageAccountSubscription")
        : t("subscriptionLabel")
    }, [selectedSessionType])

    const onSessionTypeChange = React.useCallback((ev: any, option: any) => {
        setSessionType(option.key);
        if (option.key === SessionType.Mounted) {
            setShowSharedSubWarning(true);
        } else {
            setShowSharedSubWarning(false);
        }
    }, []);

    const logger = useLogger(getARMEndpoint(), {});

    const handleVNetOnChange = useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
          setIsVnet(!!checked);
        },
        [],
    );

    const choiceGroupStyles = {
        flexContainer: {
            selectors: {
                '.ms-ChoiceField': {
                  margin: 0
                },
            },
        }
    };

    const renderVNetLabelWithLink = () => {
        return (
          <span>
            {t("vNetCheckboxLabel")}{' '}
            <Link href="https://aka.ms/CloudShell/docs/vnet" target="_blank">
                {t("learnMoreLink")}
            </Link>
          </span>
        );
    }
    const sharedSubWarningStyles = {
        root: {
          backgroundColor: subscriptionMountProps.currentTheme.semanticColors.bodyBackground,
          border: 'none',
          marginLeft: '-7px',
          FontSize: '12px'
        }
    };

    const SharedSubWarning = () => (
        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={true}
          styles={sharedSubWarningStyles}
        >
          {t("sharedSubWarning")}
          <Link href="https://aka.ms/CloudShell/docs/storage#securing-storage-access" target="_blank" styles={{ root: { textDecoration: 'none !important' } }}>
            {t("learnMoreLink")}
          </Link>
        </MessageBar>
    );

    // Handles OS Selection
    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={titleId}
            isOpen={subscriptionMountProps.showSubscriptionStorage}
            onDismiss={subscriptionMountProps.Quit}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={titleId}>{t("gettingStarted")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: subscriptionMountProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            // change close icon when hovered
                            color: subscriptionMountProps.currentTheme.semanticColors
                                .bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={subscriptionMountProps.Quit}
                />
            </div>

            <div className={contentStyles.body}>
                <p style={{marginBottom: '5px', marginTop: '0px'}}>
                    {t("storageNotRequiredNote")}{" "}
                    <Link href="https://aka.ms/CloudShell/docs/storage" target="_blank">
                        {t("learnMoreLink")}
                    </Link>
                </p>

                <Stack tokens={{ childrenGap: "0", padding: "0px 0 10px 0 " }}>
                    <StackItem>
                        <ChoiceGroup
                            selectedKey={selectedSessionType}
                            options={mountOptions}
                            onChange={onSessionTypeChange}
                            styles={choiceGroupStyles}
                        />
                    </StackItem>
                    
                    { showSharedSubWarning && 
                        <SharedSubWarning /> 
                    }
                    
                    <StackItem>
                        <FormLabel
                            required
                            stackVertical
                            displayValue={displayValue}
                        >
                            <SubscriptionDropdown
                                subscriptionList={subscriptionMountProps.subscriptionsList}
                                selectedSubscription={subscriptionMountProps.selectedSubscription}
                                setSelectedSubscription={subscriptionMountProps.setSelectedSubscription}
                                ariaLabel={displayValue}
                            />
                        </FormLabel>
                    </StackItem>
                    <StackItem styles={{ root: { marginTop: '10px' } }}>
                        <div>
                            <Checkbox
                                checked={isVnet}
                                onChange={handleVNetOnChange}
                                onRenderLabel={renderVNetLabelWithLink}
                            />
                        </div>
                    </StackItem>
                </Stack>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        marginBottom: '13px'
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            if (selectedSessionType === SessionType.Mounted) {
                                if (userSettingsState.properties.sessionType != SessionType.Mounted) {
                                    userSettingsDispatch({
                                        type: UserSettingsActionType.UpdateSessionType,
                                        payload: SessionType.Mounted
                                    });
                                }
                                if (isVnet) {
                                    if (userSettingsState.properties.networkType != NetworkType.Isolated) {
                                        userSettingsDispatch({
                                            type: UserSettingsActionType.UpdateNetworkType,
                                            payload: NetworkType.Isolated
                                        });
                                    }
                                    subscriptionMountProps.setMountState(MountState.AdvancedMount);                                 
                                } else {
                                    if (userSettingsState.properties.networkType != NetworkType.Default) {
                                        userSettingsDispatch({
                                            type: UserSettingsActionType.UpdateNetworkType,
                                            payload: NetworkType.Default
                                        });
                                    }
                                    subscriptionMountProps.setMountState(MountState.IntermediateMount);
                                }
                            } else {
                                // if ephemral
                                if (userSettingsState.properties.sessionType != SessionType.Ephemeral) {
                                    userSettingsDispatch({
                                        type: UserSettingsActionType.UpdateSessionType,
                                        payload: SessionType.Ephemeral
                                    });
                                }
                                // store the deployment type to go back to the correct state if any error occurs
                                subscriptionMountProps.setDeploymentType(MountState.SubscriptionOnly);
                                if (isVnet) {
                                    if (userSettingsState.properties.networkType != NetworkType.Isolated) {
                                        userSettingsDispatch({
                                            type: UserSettingsActionType.UpdateNetworkType,
                                            payload: NetworkType.Isolated
                                        });
                                    }                       
                                    subscriptionMountProps.setMountState(MountState.AdvancedMount);                                   
                                } else {
                                    const newUserSettings = {
                                        ...userSettingsState.properties,
                                        networkType: NetworkType.Default,
                                        sessionType: SessionType.Ephemeral,  
                                        userSubscription: subscriptionMountProps.selectedSubscription?.subscriptionId,
                                        storageProfile: null
                                    }
                                    subscriptionMountProps.mountUserSettings(newUserSettings);  
                                    logger.clientTelemetry(TelemetryLoggerEnum.Onboarding_Ephemeral, { isVnet: false }, {}, 0, null);
                                }
                            }
                        }}
                        text={t("applyButton")}
                        disabled={subscriptionMountProps.selectedSubscription == null} // disable if no subscription selected
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={() => {
                            subscriptionMountProps.setMountState(MountState.OSSelect);
                        }}
                        text={t("previousButton")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SubscriptionMount;
