/*
#####################
# SwitchToV1 Dialog #
#####################

Purpose: Switch to UX V1 for features which is not yet aviable in UX V2
*/

// Imported packages
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    useTheme
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useTranslation } from "react-i18next";
import { useLogger } from "../../Util/Logger";
import { getARMEndpoint, goToV1 } from "../../Util/Utilities";
import { UserSettingsContext } from "../../DataProvider/UserSettingsProvider";
import { TelemetryLoggerEnum } from "../../../common/consts";
import { useContext } from "react";

// Interface components
interface SwitchToV1Props {
    showSwitchToV1: boolean; // variable to display or not
    closeSwitchToV1?: () => void; // function to close the dialog
    subscriptionId?: string;
    switchToV1Method: string;
}


// Component
const SwitchToV1 = (switchToV1Props: SwitchToV1Props) => {
    const titleId = useId("SwitchToV1");
    const { t } = useTranslation();
    const theme = useTheme();
    const { userSettingsState } = useContext(UserSettingsContext);
    const logger = useLogger(getARMEndpoint(), {});
    
    // Pivot info
    const contentStyles = mergeStyleSets({
        container: {
            maxWidth: 590,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            {
                flex: "1 1 auto",
                fontSize: 18,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "10px 10px 0px 24px",
            },
        ],
        body: {
            padding: "20px 22px 20px 26px",
            overflowY: "hidden",
        },
    });

    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={titleId}
            isOpen={switchToV1Props.showSwitchToV1}
            onDismiss={switchToV1Props.closeSwitchToV1}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={titleId}>{t("switchToV1Header")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: theme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            // change close icon when hovered
                            color: theme.semanticColors
                                .bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={switchToV1Props.closeSwitchToV1}
                />
            </div>

            <div className={contentStyles.body}>
                <p style={{marginBottom: '10px', marginTop: '5px'}}>
                    {t("featureNotAvailable")}
                </p>
                <p style={{marginBottom: '5px', marginTop: '5px'}}>
                    {t("gotoV1Prompt")}
                </p>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        paddingTop: '25px',
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            logger.clientTelemetry(TelemetryLoggerEnum.V1OrV2_GoToV1, { UXVersion: 'v1', method: switchToV1Props.switchToV1Method }, {}, 0, null);
                            goToV1(switchToV1Props.subscriptionId || userSettingsState.properties.userSubscription, userSettingsState.properties.preferredShellType);
                        }}
                        text={t("switchButtonTitle")}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={switchToV1Props.closeSwitchToV1}
                        text={t("cancelButton")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default SwitchToV1;
