/*
##################
# Restart Dialog #
##################

Parent: `Session` 
Purpose: User confirmation screen for restarting  
Takes:
 - `hideRestartDialog`
 - `RestartProcessing`
 - `Restart` 
Returns:
 - Makes a call to `toggleRestartDialog()` which hides and shows the dialog
 - Makes a call to `Restart()` that will switch the operating system fromBash to PowerShell or vice versa

*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { useAccessTokenContext } from "../../DataProvider/EventProvider";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface RestartDialogProps {
    // These are the call back functions and data from parent
    hideRestartDialog: boolean;
    toggleRestartDialog: () => void;
    Restart: () => void;
}

// Component
const RestartDialog = (restartDialogProps: RestartDialogProps) => {
    const { t } = useTranslation();
    const { accessToken } = useAccessTokenContext();
    const [restartTerminal, setRestartTerminal] = React.useState(false); 
    const labelId: string = useId("restartDialogLabel");
    const subTextId: string = useId("restartSubTextLabel");
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { minwidth: 450 } },
        }),
        [labelId, subTextId]
    );

    React.useEffect(() => {
        if (accessToken != "" && restartTerminal) {
            restartDialogProps.Restart();
            setRestartTerminal(false);
        }
    }, [restartTerminal, accessToken]);
    
    return (
        // Normal dialog screen
        <>
            <Dialog
                hidden={restartDialogProps.hideRestartDialog} //variable to hide
                onDismiss={restartDialogProps.toggleRestartDialog} // function that hides
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("terminalRestartTitle"),
                    closeButtonAriaLabel: t("cancelButton"),
                    subText: t("restartDialogMessage1"),
                }}
                modalProps={modalProps}
            >
                <DialogFooter className={dialogFooterStyles}>
                    <PrimaryButton
                        onClick={() => setRestartTerminal(true)} // Callsback for restart, does not hide by itself
                        text={t("restartButtonTitle")}
                    />
                    <DefaultButton
                        onClick={restartDialogProps.toggleRestartDialog}
                        text={t("cancelButton")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default RestartDialog;
