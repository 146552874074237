import aciConnectResourceCs from "./locales/cs/aci-connect-resource.json";
import aciConnectResourceDe from "./locales/de/aci-connect-resource.json";
import aciConnectResourceEn from "./aci-connect-resource.json";
import aciConnectResourceEs from "./locales/es/aci-connect-resource.json";
import aciConnectResourceFr from "./locales/fr/aci-connect-resource.json";
import aciConnectResourceHu from "./locales/hu/aci-connect-resource.json";
import aciConnectResourceId from "./locales/id/aci-connect-resource.json";
import aciConnectResourceIt from "./locales/it/aci-connect-resource.json";
import aciConnectResourceJa from "./locales/ja/aci-connect-resource.json";
import aciConnectResourceKo from "./locales/ko/aci-connect-resource.json";
import aciConnectResourceNl from "./locales/nl/aci-connect-resource.json";
import aciConnectResourcePl from "./locales/pl/aci-connect-resource.json";
import aciConnectResourcePtbr from "./locales/pt-br/aci-connect-resource.json";
import aciConnectResourcePtpt from "./locales/pt-pt/aci-connect-resource.json";
import aciConnectResourceQpsPloc from "./locales/qps-ploc/aci-connect-resource.json";
import aciConnectResourceRu from "./locales/ru/aci-connect-resource.json";
import aciConnectResourceSv from "./locales/sv/aci-connect-resource.json";
import aciConnectResourceTr from "./locales/tr/aci-connect-resource.json";
import aciConnectResourceZhhans from "./locales/zh-hans/aci-connect-resource.json";
import aciConnectResourceZhhant from "./locales/zh-hant/aci-connect-resource.json";

import cloudShellResourceCs from "./locales/cs/cloudshell-resource.json";
import cloudShellResourceDe from "./locales/de/cloudshell-resource.json";
import cloudShellResourceEn from "./cloudshell-resource.json";
import cloudShellResourceEs from "./locales/es/cloudshell-resource.json";
import cloudShellResourceFr from "./locales/fr/cloudshell-resource.json";
import cloudShellResourceHu from "./locales/hu/cloudshell-resource.json";
import cloudShellResourceId from "./locales/id/cloudshell-resource.json";
import cloudShellResourceIt from "./locales/it/cloudshell-resource.json";
import cloudShellResourceJa from "./locales/ja/cloudshell-resource.json";
import cloudShellResourceKo from "./locales/ko/cloudshell-resource.json";
import cloudShellResourceNl from "./locales/nl/cloudshell-resource.json";
import cloudShellResourcePl from "./locales/pl/cloudshell-resource.json";
import cloudShellResourcePtbr from "./locales/pt-br/cloudshell-resource.json";
import cloudShellResourcePtpt from "./locales/pt-pt/cloudshell-resource.json";
import cloudShellResourceQpsPloc from "./locales/qps-ploc/cloudshell-resource.json";
import cloudShellResourceRu from "./locales/ru/cloudshell-resource.json";
import cloudShellResourceSv from "./locales/sv/cloudshell-resource.json";
import cloudShellResourceTr from "./locales/tr/cloudshell-resource.json";
import cloudShellResourceZhhans from "./locales/zh-hans/cloudshell-resource.json";
import cloudShellResourceZhhant from "./locales/zh-hant/cloudshell-resource.json";

import serialConsoleResourceCs from "./locales/cs/serialconsole-resource.json";
import serialConsoleResourceDe from "./locales/de/serialconsole-resource.json";
import serialConsoleResourceEn from "./serialconsole-resource.json";
import serialConsoleResourceEs from "./locales/es/serialconsole-resource.json";
import serialConsoleResourceFr from "./locales/fr/serialconsole-resource.json";
import serialConsoleResourceHu from "./locales/hu/serialconsole-resource.json";
import serialConsoleResourceId from "./locales/id/serialconsole-resource.json";
import serialConsoleResourceIt from "./locales/it/serialconsole-resource.json";
import serialConsoleResourceJa from "./locales/ja/serialconsole-resource.json";
import serialConsoleResourceKo from "./locales/ko/serialconsole-resource.json";
import serialConsoleResourceNl from "./locales/nl/serialconsole-resource.json";
import serialConsoleResourcePl from "./locales/pl/serialconsole-resource.json";
import serialConsoleResourcePtbr from "./locales/pt-br/serialconsole-resource.json";
import serialConsoleResourcePtpt from "./locales/pt-pt/serialconsole-resource.json";
import serialConsoleResourceQpsPloc from "./locales/qps-ploc/serialconsole-resource.json";
import serialConsoleResourceRu from "./locales/ru/serialconsole-resource.json";
import serialConsoleResourceSv from "./locales/sv/serialconsole-resource.json";
import serialConsoleResourceTr from "./locales/tr/serialconsole-resource.json";
import serialConsoleResourceZhhans from "./locales/zh-hans/serialconsole-resource.json";
import serialConsoleResourceZhhant from "./locales/zh-hant/serialconsole-resource.json";

const resources = {
    "cs": {
        aciConnectResource: aciConnectResourceCs,
        cloudShellResource: cloudShellResourceCs,
        serialConsoleResource: serialConsoleResourceCs,
    },
    "de": {
        aciConnectResource: aciConnectResourceDe,
        cloudShellResource: cloudShellResourceDe,
        serialConsoleResource: serialConsoleResourceDe,
    },
    "en": {
        aciConnectResource: aciConnectResourceEn,
        cloudShellResource: cloudShellResourceEn,
        serialConsoleResource: serialConsoleResourceEn,
    },
    "es": {
        aciConnectResource: aciConnectResourceEs,
        cloudShellResource: cloudShellResourceEs,
        serialConsoleResource: serialConsoleResourceEs,
    },
    "fr": {
        aciConnectResource: aciConnectResourceFr,
        cloudShellResource: cloudShellResourceFr,
        serialConsoleResource: serialConsoleResourceFr,
    },
    "hu": {
        aciConnectResource: aciConnectResourceHu,
        cloudShellResource: cloudShellResourceHu,
        serialConsoleResource: serialConsoleResourceHu,
    },
    "id": {
        aciConnectResource: aciConnectResourceId,
        cloudShellResource: cloudShellResourceId,
        serialConsoleResource: serialConsoleResourceId,
    },
    "it": {
        aciConnectResource: aciConnectResourceIt,
        cloudShellResource: cloudShellResourceIt,
        serialConsoleResource: serialConsoleResourceIt,
    },
    "ja": {
        aciConnectResource: aciConnectResourceJa,
        cloudShellResource: cloudShellResourceJa,
        serialConsoleResource: serialConsoleResourceJa,
    },
    "ko": {
        aciConnectResource: aciConnectResourceKo,
        cloudShellResource: cloudShellResourceKo,
        serialConsoleResource: serialConsoleResourceKo,
    },
    "nl": {
        aciConnectResource: aciConnectResourceNl,
        cloudShellResource: cloudShellResourceNl,
        serialConsoleResource: serialConsoleResourceNl,
    },
    "pl": {
        aciConnectResource: aciConnectResourcePl,
        cloudShellResource: cloudShellResourcePl,
        serialConsoleResource: serialConsoleResourcePl,
    },
    "pt-BR": {
        aciConnectResource: aciConnectResourcePtbr,
        cloudShellResource: cloudShellResourcePtbr,
        serialConsoleResource: serialConsoleResourcePtbr,
    },
    "pt-PT": {
        aciConnectResource: aciConnectResourcePtpt,
        cloudShellResource: cloudShellResourcePtpt,
        serialConsoleResource: serialConsoleResourcePtpt,
    },
    "qps-PLOC": {
        aciConnectResource: aciConnectResourceQpsPloc,
        cloudShellResource: cloudShellResourceQpsPloc,
        serialConsoleResource: serialConsoleResourceQpsPloc,
    },
    "ru": {
        aciConnectResource: aciConnectResourceRu,
        cloudShellResource: cloudShellResourceRu,
        serialConsoleResource: serialConsoleResourceRu,
    },
    "sv": {
        aciConnectResource: aciConnectResourceSv,
        cloudShellResource: cloudShellResourceSv,
        serialConsoleResource: serialConsoleResourceSv,
    },
    "tr": {
        aciConnectResource: aciConnectResourceTr,
        cloudShellResource: cloudShellResourceTr,
        serialConsoleResource: serialConsoleResourceTr,
    },
    "zh-Hans": {
        aciConnectResource: aciConnectResourceZhhans,
        cloudShellResource: cloudShellResourceZhhans,
        serialConsoleResource: serialConsoleResourceZhhans,
    },
    "zh-Hant": {
        aciConnectResource: aciConnectResourceZhhant,
        cloudShellResource: cloudShellResourceZhhant,
        serialConsoleResource: serialConsoleResourceZhhant,
    }
};

export default resources;
