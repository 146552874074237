/*
############
# useNonInitialEffect #
############
-   **Purpose**: The custom hook is to prevent useEffect callback during the initial render.
-   **Note**: 
      - It accepts a callback function and an array of dependencies as arguments. 
      - The callback function doesn’t get called during the initial render.
*/

import React from "react"

export const useNonInitialEffect :typeof React.useEffect = (cb, dep) => {
    const initializeRef = React.useRef<boolean>(false);
  
    React.useEffect((...args) => {
      if (initializeRef.current) {
        cb(...args);
      } else {
        initializeRef.current = true;
      }
    }, dep)
}