/*
#################
# User Settings #
#################

-   **Inputs**: 
    -   `currentTheme`
        -   A theme object that includes design standardization
    -   `userLocation`
        -   user location name and code from Header
    -   `verifyUserSettingsError`
        -   error message from verifyUserSettings and usersettings invalid
-   **Purpose**:  This component handles user settings restoration and creation.   
-   **To do**:
    -   Silent remount 
-   **Notes**:
    -   This is the parent component for `MountStorage` and `OSSelect`. 
    -   This component should attempt a quiet remount before displaying a first run experience.

*/

// External Components
import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { Theme } from "@fluentui/react";

// CloudShell Components
import ShellTypeSelect from "./Dialog/ShellTypeSelect";
import { BlobStorage, FileStorage, HttpMethod, MissingSubscriptionRegistration, MountState, MountedOption, NetworkType, ProvisioningState, ResourceGroupNotFound, ResourceNotFound, SessionType, ShellType, StorageData, UserSettingsActionType, storageApiVersion, timeoutForGetStorageAccount } from "../../common/consts";
import SubscriptionMount from "./Dialog/SubscriptionMount";
import AdvancedMount from "./Dialog/AdvancedMount";
import StorageCreation from "./Dialog/StorageCreation";
import CloseConfirmDialog from "./Dialog/CloseConfirm";
import IntermediateMount from "./Dialog/IntermediateMount";
import { getARMEndpoint, getAzureConsoleProviderLocation, getStorageLocationMapping, isSecureCloud } from "../Util/Utilities";
import { armApiVersion, RequestLoggerEnum, TelemetryLoggerEnum } from "../../common/consts";
import { UserSettingsContext } from "../DataProvider/UserSettingsProvider";
import { ErrorDetails, Subscription, UserSetting, UserLocation, OnboardingInfo, ResourceGroup } from "../../common/types";
import DeploymentInProgressDialog from "./Dialog/DeploymentInProgress";
import { useARMHelper } from "../Util/useARMHelper";
import { useLogger } from "../Util/Logger";
import DeploymentErrorDialog from "../Error/DeploymentError";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import ErrorDialog from "../Error/Error";
import SwitchToV1 from "./Dialog/SwitchToV1";
import useFetch from "../Util/useFetch";

// Interface
interface UserSettingProps {
    currentTheme: Theme;
    userLocation: UserLocation;
    verifyStorageAccountError: string;
    setVerifyStorageAccountError: (verifyStorageAccountError: string) => void;
}

// Components
const UserSettings = (userSettingProps: UserSettingProps) => {
    // There are three possible states
    // 1. Loading (show nothing and maybe skip the following)
    // 2. Get OS State
    // 3. The actual settings

    // View functions
    const [currentMountState, setMountState] = React.useState<MountState>(MountState.OSSelect);
    const [deploymentType, setDeploymentType] = React.useState<MountState>(currentMountState);
    const [hideCloseDialog, { toggle: toggleCloseDialog }] = useBoolean(true);

    // Hook variables
    const [selectedSubscription, setSelectedSubscription] = React.useState<Subscription | null>(null);
    const [selectedResourceGroup, setSelectedResourceGroup] = React.useState("");
   
    const { userSettingsState, userSettingsDispatch } = React.useContext(UserSettingsContext);
    const [ errorDetails, setErrorDetails ] = React.useState<ErrorDetails>({code:'', message:''});
    const { getResourceGroup, getStorageAccount, putResourceGroup, putStorageAccount, updateStorageAccountTags, getSubscriptionsHelper, updateUserSettings, registerStorageResourceProvider } = useARMHelper();
    const logger = useLogger(getARMEndpoint(), {});
    const { t } = useTranslation();
    const { verifyCloudShellProviderRegistration } = useARMHelper();
    const [subscriptionsList, setSubscriptionsList] = useState<Subscription[]>([]);
    const [subscriptionErrorMessages, setSubscriptionErrorMessages] = useState({header: '', content: ''});
    const [onboardingInfo, setOnboardingInfo] = useState<OnboardingInfo | null>(null);
    const { data: resourceGroupList, loading: isResourceGroupsLoading, error: resourceGroupListError} = useFetch<ResourceGroup>(selectedSubscription?.subscriptionId || "", RequestLoggerEnum.ResourceGroup_List);
    const sessionType = useMemo(() => userSettingsState.properties.sessionType, [userSettingsState.properties.sessionType]); 

    const [switchToV1Method, setSwitchToV1Method] = useState("");

    // Callback functions
    const setShellType = (newShellType: ShellType) => {
        // this function i
        setMountState(MountState.SubscriptionOnly);
    };

    const showDeploymentError = (error: any, customErrorMessage?:string) => {
        setErrorDetails({code: error?.response?.data?.error?.code || error?.respnse?.status || '', message: customErrorMessage || error?.response?.data?.error?.message || JSON.stringify(error?.response?.data) || JSON.stringify(error) || ''});
        setMountState(MountState.Error);
    }

    // Update the state of the user settings and make API call
    const mountUserSettings = async (userSettings: UserSetting) => {
        let newUserSettingsState;
        if (userSettings.preferredLocation === "") {
            newUserSettingsState = { 
                properties: {
                    ...userSettings,
                    //If Ephemeral session, use the default location
                    preferredLocation: userSettingProps.userLocation.name
                }, 
                initialized: true
            };
        } else {
            newUserSettingsState = { 
                properties: userSettings, 
                initialized: true
            };
        }

        if (selectedSubscription != null) {
            verifyCloudShellProviderRegistration(selectedSubscription.subscriptionId);
        } else {
            logger.clientTelemetry(TelemetryLoggerEnum.SubscriptionRegistration_Invalid, { "sessionType": newUserSettingsState.properties.sessionType, "storageProfile": newUserSettingsState.properties.storageProfile }, {}, 0);
            console.log("SessionType: '" + newUserSettingsState.properties.sessionType + "'Either StorageProfile subscription or UserSubscription: Null.")
        }

        if (userSettingProps.verifyStorageAccountError != "") {
            //clear error message after re-onboarding
            userSettingProps.setVerifyStorageAccountError("");
        }
        let error: any = null;
        try {
            error = await updateUserSettings(newUserSettingsState.properties);
            if(error) {
                //show error dialog when failed to update user settings
                showDeploymentError(error);
            } else {
                //otherwise update user settings state and show terminal
                userSettingsDispatch({
                    type: UserSettingsActionType.PutUserSettings,
                    payload: newUserSettingsState
                });
            }
        }
        catch (e: any) {
            showDeploymentError(error);
        }
    };

    const createUserSettings = (onboardingInfo: OnboardingInfo) => {
        const storageLocationMapping = getStorageLocationMapping();
        let location = storageLocationMapping[onboardingInfo.location];
        const providerLocation = getAzureConsoleProviderLocation();
        if (providerLocation === "centralus") {
          location = "centraluseuap";
        }
        if (providerLocation === "eastus2") {
          location = "eastus2euap";
        }

        const networkType = onboardingInfo.vNetInfo ? NetworkType.Isolated : NetworkType.Default;

        const storageProfile = {
            storageAccountResourceId: onboardingInfo.resourceGroupID + '/providers/Microsoft.Storage/storageAccounts/' + onboardingInfo.storageAccountName,
            fileShareName: onboardingInfo.fileShareName,
            diskSizeInGB: onboardingInfo.diskSize
        };

        const vnetSettings = {
            networkProfileResourceId: onboardingInfo.vNetInfo?.networkProfileResourceId || "",
            relayNamespaceResourceId: onboardingInfo.vNetInfo?.relayNamespaceResourceId || "",
            isolatedStorageProfile: sessionType === SessionType.Ephemeral ? null : storageProfile,
            location: onboardingInfo.vNetInfo?.location || ""
        }

        const usersettings = {
            ...userSettingsState.properties,
            preferredLocation: location,
            networkType: networkType,
            sessionType: sessionType,
            userSubscription: selectedSubscription?.subscriptionId,
            storageProfile: sessionType === SessionType.Ephemeral ? null : storageProfile,
            vnetSettings: networkType === NetworkType.Isolated ? vnetSettings : null,
        }
        
        mountUserSettings(usersettings)
    }

    const createStorageAccount = async (onboardingInfo: OnboardingInfo) => {
        const storageAccountResourceId = onboardingInfo.resourceGroupID + '/providers/Microsoft.Storage/storageAccounts/' + onboardingInfo.storageAccountName;
        const targetUri = getARMEndpoint() + storageAccountResourceId + '?api-version=' + storageApiVersion;

        try {
            // try to get the storage account
            const {storageAccountData, error} = await getStorageAccount(storageAccountResourceId);
            // if any issue
            if (error) {
                // if not found
                if (error.response.status == 404 && error.response.data && error.response.data.error && error.response.data.error.code === ResourceNotFound) {
                    try {
                        // create storage account
                        const data = {
                            location: onboardingInfo.location,
                            sku: { name: StorageData.SKU },
                            kind: StorageData.Kind,
                            tags: { [StorageData.Tags.Key]: StorageData.Tags.Value },
                            properties: {
                                encryption: {
                                services: {
                                    blob: { 'enabled': true },
                                    file: { 'enabled': true }
                                },
                                keySource: StorageData.KeySource
                                },
                                supportsHttpsTrafficOnly: true,
                                allowBlobPublicAccess: false,
                                //USNat and USSec doesn't support minimum TLS version
                                ...!isSecureCloud() && {minimumTlsVersion: StorageData.MinimumTlsVersion}
                            }
                        };
    
                        const putStorageAccountResponse = await putStorageAccount(targetUri, JSON.stringify(data));
                        if (putStorageAccountResponse.status === 202) {
                            setTimeout(async () => {
                                //verify the storage account is created successfully
                                const {storageAccountData, error} = await getStorageAccount(storageAccountResourceId, 0);
                                if (storageAccountData === ProvisioningState.Succeeded) {
                                    createUserSettings(onboardingInfo);
                                } else {
                                    //provisioning failed or reached the retry limit
                                    showDeploymentError(error || '', t("provisionFailed"));
                                }
                            }, timeoutForGetStorageAccount);
                        } else {
                            createUserSettings(onboardingInfo);
                        }
                    } catch (error: any) {
                        if (error.response.status == 409 && error.response.data && error.response.data.error && error.response.data.error.code === MissingSubscriptionRegistration) {
                            try {
                                const error = await registerStorageResourceProvider(selectedSubscription!.subscriptionId);
                                if (error) {
                                    showDeploymentError(error);
                                } else {
                                    //create storage account again after registration
                                    createStorageAccount(onboardingInfo);
                                }
                            } catch (error){
                                // show unknown registration error details
                                showDeploymentError(error);
                            }
                        } else {
                            // show unknown PUT Storage Account error details
                            showDeploymentError(error);
                        }
                    }
                } else {
                    // show unknown GET Storage Account error details
                    showDeploymentError(error);
                }
            } else {
                // if the storage account kind is blob, show error message
                if (isBlobStorage(storageAccountData.kind, storageAccountData.sku.tier === FileStorage.Performance.Premium)) {
                    showDeploymentError('', t('blobError'));
                } else {
                    //if the storage account has the wrong tag, update the tag
                    if (storageAccountData.tags[StorageData.Tags.Key] !== StorageData.Tags.Value) {
                        const updatedData = {
                            tags: {
                                [StorageData.Tags.Key]: StorageData.Tags.Value
                            }
                        };
                        await updateStorageAccountTags(targetUri, JSON.stringify(updatedData));
                        createUserSettings(onboardingInfo);
                    }
                    else {
                        createUserSettings(onboardingInfo);
                    }
                }
            }
        } catch (error: any) {
            showDeploymentError(error);
        }
    }

    const isBlobStorage = (kind: string, isPremium: boolean) => {
        if (isPremium && kind != FileStorage.AccountType.FileStorage) {
            return true;
        }
        if (kind === BlobStorage) {
            return true;
        }
        return false;
    };

    const createResourceGroup = async (onboardingInfo: OnboardingInfo) => {
        const targetUri = getARMEndpoint() + onboardingInfo.resourceGroupID + '?api-version=' + armApiVersion;
        
        const start = Date.now();
        
        try {
            // try to get the resource group
            await getResourceGroup(targetUri)

            // if the resource group exists, create storage account
            createStorageAccount(onboardingInfo);
        } catch (error: any) {
            // if not found
            if (error.response.status == 404 && error.response.data && error.response.data.error && error.response.data.error.code === ResourceGroupNotFound) {
                try {
                    // create resource group
                    await putResourceGroup(targetUri, JSON.stringify({location: onboardingInfo.location }));
                    // then create storage account
                    createStorageAccount(onboardingInfo);
                } catch (error: any) {
                    // show PUT Resource Group error details
                    showDeploymentError(error);
                }
            } else {
                // show unknown GET Resource Group error details
                showDeploymentError(error);
                logger.clientRequest(RequestLoggerEnum.ResourceGroup_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, null);
            }
        }
    }

    const getSubscriptions = async () => {
        try {
            const {subscriptions, error} = await getSubscriptionsHelper();
            if (error) {
                //show unknown failure
                setSubscriptionErrorMessages({header: '', content: JSON.stringify(error.response.data)});
                setMountState(MountState.SubscriptionListError)
            } else {
                if (subscriptions.length === 0) {
                    setSubscriptionErrorMessages({header: t("noSubscriptionHeader"), content: t("noSubscriptionMessage")});
                    setMountState(MountState.SubscriptionListError);
                } else {
                    setSubscriptionsList(subscriptions);
                }
            }
        } catch (error: any) {
            //show unknown failure
            setSubscriptionErrorMessages({header: '', content: error.toString() || ''});
            setMountState(MountState.SubscriptionListError);
        }
    }

    const applyOnboardingInfo = (mountState: MountState, onboardingInfo: OnboardingInfo) => {
        const isVnet = onboardingInfo.vNetInfo ? true : false;
        let mountedOption = MountedOption.AutoCreation;
        if (mountState === MountState.AdvancedMount) {
            mountedOption = MountedOption.ExistingStorageAccount;
            if (isVnet && sessionType === SessionType.Ephemeral) {
                mountedOption = MountedOption.EphemeralVNET;
            }
            if (isVnet && sessionType === SessionType.Mounted) {
                mountedOption = MountedOption.MountedVNET;
            }
        }
        if (mountState === MountState.StorageCreation) {
            mountedOption = MountedOption.NewStorageAccount;
        }

        if (mountedOption === MountedOption.EphemeralVNET) {
            logger.clientTelemetry(TelemetryLoggerEnum.Onboarding_Ephemeral, { isVnet: true }, {}, 0, null);
        } else {
            logger.clientTelemetry(TelemetryLoggerEnum.Onboarding_Mounted, { isVnet: isVnet, mountedOption: mountedOption }, {}, 0, null);
        }
        
        // store the deployment type to go back to the correct state if any error occurs
        setDeploymentType(mountState);
        setOnboardingInfo(onboardingInfo);
        if (mountState === MountState.StorageCreation || mountState === MountState.IntermediateMount) {
            createResourceGroup(onboardingInfo);
        } 
        if (mountState === MountState.AdvancedMount) {
            if (sessionType === SessionType.Ephemeral) {
                createUserSettings(onboardingInfo);
            } else {
                createStorageAccount(onboardingInfo);
            }
        }
        setMountState(MountState.DeploymentInProgress);
    }

    const updateDeploymentTypeAndShowError = (mountState: MountState, error: any) => {
        setDeploymentType(mountState);
        showDeploymentError(error);
    }

    React.useEffect(() => {
        if (subscriptionsList.length === 0 && currentMountState === MountState.SubscriptionOnly) {
            getSubscriptions();
        }
    }, [currentMountState]); 

    React.useEffect(() => {
        if (resourceGroupListError) {
            updateDeploymentTypeAndShowError(currentMountState, resourceGroupListError);
        }
    }, [resourceGroupListError]); 

    // Return component
    return (
        <>
            <ShellTypeSelect
                currentTheme={userSettingProps.currentTheme}
                showShellTypeSelect={currentMountState === MountState.OSSelect}
                setShellType={setShellType}
                verifyStorageAccountError={userSettingProps.verifyStorageAccountError}
            />
            <SubscriptionMount
                currentTheme={userSettingProps.currentTheme}
                showSubscriptionStorage={currentMountState === MountState.SubscriptionOnly}
                mountUserSettings={mountUserSettings}
                setMountState={setMountState}
                Quit={toggleCloseDialog}
                selectedSubscription={selectedSubscription}
                setSelectedSubscription={setSelectedSubscription}
                setDeploymentType={setDeploymentType}
                subscriptionsList={subscriptionsList}
                setSwitchToV1Method={setSwitchToV1Method}
            />

            {            
                currentMountState === MountState.AdvancedMount
                &&
                <AdvancedMount
                    currentTheme={userSettingProps.currentTheme}
                    setDeploymentType={setDeploymentType}
                    isResourceGroupsLoading={isResourceGroupsLoading}
                    selectedSubscription={selectedSubscription}
                    setSelectedSubscription={setSelectedSubscription}
                    selectedResourceGroup={selectedResourceGroup}
                    setSelectedResourceGroup={setSelectedResourceGroup}
                    subscriptionsList={subscriptionsList}
                    resourceGroupList={resourceGroupList}
                    currentMountState={currentMountState}
                    showMountStorage={currentMountState === MountState.AdvancedMount}
                    setMountState={setMountState}
                    applyOnboardingInfo={applyOnboardingInfo}
                    Quit={toggleCloseDialog}
                    updateDeploymentTypeAndShowError={updateDeploymentTypeAndShowError}
                />
            }

            <StorageCreation
                currentTheme={userSettingProps.currentTheme}
                isResourceGroupsLoading={isResourceGroupsLoading}
                subscriptionsList={subscriptionsList}
                resourceGroupList={resourceGroupList}
                selectedSubscription={selectedSubscription}
                setSelectedSubscription={setSelectedSubscription}
                selectedResourceGroup={selectedResourceGroup}
                setSelectedResourceGroup={setSelectedResourceGroup}
                showStorageCreation={currentMountState === MountState.StorageCreation}
                setMountState={setMountState}
                applyOnboardingInfo={applyOnboardingInfo}
                Quit={toggleCloseDialog}
            />

            <IntermediateMount
                currentTheme={userSettingProps.currentTheme}
                userLocation={userSettingProps.userLocation}
                subscriptionId={selectedSubscription?.subscriptionId || ''}
                showIntermediateMount={currentMountState === MountState.IntermediateMount}
                setMountState={setMountState}
                applyOnboardingInfo={applyOnboardingInfo}
                Quit={toggleCloseDialog}
            />
            <CloseConfirmDialog
                hideCloseDialog={hideCloseDialog}
                toggleCloseDialog={toggleCloseDialog}
            />
            <DeploymentErrorDialog
                currentTheme={userSettingProps.currentTheme}             
                showOnboardingError={currentMountState === MountState.Error}
                selectedSubscriptionName={selectedSubscription?.displayName}
                onboardingInfo={onboardingInfo}
                errorDetails={errorDetails}
                Quit={toggleCloseDialog}
                goToPrevious={() => setMountState(deploymentType)}
            />
            <DeploymentInProgressDialog
                currentTheme={userSettingProps.currentTheme}
                showDeploymentInProgress={currentMountState === MountState.DeploymentInProgress}
                selectedSubscriptionName={selectedSubscription?.displayName}
                onboardingInfo={onboardingInfo}
            />
            <SwitchToV1 
                showSwitchToV1={currentMountState === MountState.SwitchToV1}
                closeSwitchToV1={() => setMountState(MountState.SubscriptionOnly)}
                subscriptionId={selectedSubscription?.subscriptionId || ''}
                switchToV1Method={switchToV1Method}
            />

            {           
                currentMountState === MountState.SubscriptionListError
                &&
                <div style={{ height: "100%", width: "auto", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <ErrorDialog
                        hideErrorDialog={currentMountState != MountState.SubscriptionListError}
                        errorMessages={subscriptionErrorMessages}
                    />
                </div>
            }
        </>
    );
};

export default UserSettings;
