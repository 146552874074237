/* 
#########################
# Subscription Dropdown #
#########################

-   **Based on**
    -   React View Subscription dropdown
        -   [Code](https://msazure.visualstudio.com/One/_git/AzureUX-PortalFx?path=/src/SDK/Framework.ReactView/azureportal-reactview/src/SubscriptionDropdown.tsx&_a=contents&version=GBdev)
        -   [Demo](https://ms.portal.azure.com/?Microsoft_Azure_Playground=true#view/Microsoft_Azure_Playground/ReactControlsBlade/~/SubscriptionDropdown)
-   **Uses user specific data**: `true`
    -   Subscription list should be unique to every user.
-   **Allows user to create new element**: `false`
-   **Fetches based on**: Bearer token
-   **Inputs**:
    -   `subscriptionList`
        -   An ajax response json of subscriptions
    -   `selectedSubscription`
        -   current selected subscription
    -   `setSelectedSubscription`
        -   Call back function to set the selected subscription
-   **Purpose**: Let users select and filter a subscription from a dropdown lit
-   **Note**:
    -   This element should **always** be present in a first experience run
    -   This could be made more general so it could be recycled for storage account and VNET dropdowns as it takes an array and does not allow for resource creation.
*/

// Libraries
import * as React from "react";
import { useMemo } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Subscription } from "../../../common/types";
import FilterableDropdown from "./FilterableDropdown";
import { useTranslation } from "react-i18next";

interface SubscriptionDropdownProps {
    subscriptionList: Subscription[];
    selectedSubscription: Subscription | null;
    setSelectedSubscription: (subscription: Subscription | null) => void;
    ariaLabel?: string;
}

const SubscriptionDropdown = React.memo((subscriptionProps: SubscriptionDropdownProps) => {
    const { t } = useTranslation();
    const subscriptions = useMemo(() => subscriptionProps.subscriptionList || [], [subscriptionProps.subscriptionList]);
    
    // Build the set of dropdown items
    const options = useMemo(() => {
        subscriptions.sort(
            (subscriptionA: Subscription, subscriptionB: Subscription) => {
                return subscriptionA.displayName < subscriptionB.displayName ? -1 : 1;
            }
        );
        const items: IDropdownOption[] = [];

        for (const subscription of subscriptions) {
            items.push({
                key: subscription.subscriptionId,
                text: subscription.displayName,
                data: subscription
            });
        }

        return items;
    }, [subscriptions]);

    return (
        <>
            <FilterableDropdown
                options={options}
                selectedKey={subscriptionProps.selectedSubscription?.subscriptionId}
                onChange={(_event: React.FormEvent<HTMLDivElement>, subscriptionOption: any) => {
                    const newSubscription: Subscription = {
                        subscriptionId: subscriptionOption.key,
                        displayName: subscriptionOption.text,
                    };
                    subscriptionProps.setSelectedSubscription(newSubscription);
                }}
                ariaLabel={subscriptionProps.ariaLabel || t("subscriptionLabel")}
            />
        </>
    );
});

SubscriptionDropdown.displayName = "Subscription-Dropdown"; // export name

export default SubscriptionDropdown;
