/*
##################
# UserSettingsProvider.tsx #
##################

-   **Purpose**: This component is used to set up the userSetting Context and Reducer.
-   **Note**:
    -   If the user setting is mounted, display the session; otherwise, display the onboarding components
    
*/
import { AzureThemeLight } from '@fluentui/azure-themes';
import { Theme, ThemeProvider } from '@fluentui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState }  from 'react';
import { FC, useReducer } from "react";
import { UserLocation, UserSetting, UserSettingsActions, UserSettingsContextType, UserSettingsState } from "../../common/types";
import { DefaultLocation, FontSize, HttpMethod, Linux, NetworkType, RetryLimit, SessionType, ShellType, UserSettingsActionType, storageApiVersion} from "../../common/consts";
import Session from '../Experience/Session';
import { useAccessTokenContext } from './EventProvider';
import { getARMEndpoint, getAzureConsoleProviderLocation, getStorageProfile, getUserSettingsSubscriptionId } from '../Util/Utilities';
import { consoleApiVersion, RequestLoggerEnum, TelemetryLoggerEnum } from "../../common/consts";
import UserSettings from '../Onboarding/UserSettings';
import { useARMHelper } from '../Util/useARMHelper';
import { useLogger } from '../Util/Logger';
import ErrorDialog from '../Error/Error';
import { CorrelationIdContext } from './IdProviders';
import { useTranslation } from 'react-i18next';

const initialUserSetting: UserSettingsState = {
    properties: {
        preferredOsType: Linux,
        preferredLocation: "",
        storageProfile: null,
        "terminalSettings": {
            "fontSize": FontSize.Medium,
            "fontStyle": "monospace",
        },
        "vnetSettings": null,
        "userSubscription": "",
        "sessionType": SessionType.Ephemeral,
        "networkType": NetworkType.Default,
        "preferredShellType": ShellType.Bash
    },
    initialized: false
};

export const UserSettingsContext = React.createContext<UserSettingsContextType>({
        userSettingsState: initialUserSetting,
        userSettingsDispatch: () => undefined,
    }
);

/**  The userSettingReducer allows us to manage the usersetting state */
const userSettingsReducer = (state: UserSettingsState, action:UserSettingsActions) => {
    const actionType = action.type;
    switch (actionType) {
        //Get the usersetting by the axios calls
        //Set the initialized property to be true if the user setting is mounted
        case UserSettingsActionType.GetUserSettings:
            return {
                properties: action.payload,
                initialized: true
            };
        case UserSettingsActionType.PutUserSettings:
            return action.payload;
        case UserSettingsActionType.DeleteUserSettings:
            return {
                ...initialUserSetting,
                properties: {
                    ...initialUserSetting.properties,
                    terminalSettings: state.properties.terminalSettings
                }
            };
        case UserSettingsActionType.UpdateFontSize:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    terminalSettings: {
                        ...state.properties.terminalSettings,
                        fontSize: action.payload
                    }
                }
            };
        case UserSettingsActionType.UpdateFontStyle:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    terminalSettings: {
                        ...state.properties.terminalSettings,
                        fontStyle: action.payload
                    }
                }
            };
        case UserSettingsActionType.UpdateShellType:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    preferredShellType: action.payload
                }
            };
        case UserSettingsActionType.UpdateSessionType:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    sessionType: action.payload
                }
            }
        case UserSettingsActionType.UpdateNetworkType:
            return {
                ...state,
                properties: {
                    ...state.properties,
                    networkType: action.payload
                }
            };
        default:
            //todo: should be logged into kusto
            throw new Error(`No case for type ${actionType} found in userSettingReducer.`);
    }
}

export const UserSettingsProvider: FC = ({ children }) => {
    //useReducer returns an array that holds the current userSetting state value
    //The dispatch method logically achieves the same goal as setState, updating the state.
    const [userSettingsState, userSettingsDispatch] = useReducer(userSettingsReducer, initialUserSetting);
    const { accessToken } = useAccessTokenContext();
    const [loadingUserSettings, setLoadingUserSettings] = useState(true);
    const [currentTheme, setTheme] = useState<Theme>(AzureThemeLight); //  default to light
    const [userLocation, setUserLocation] = useState<UserLocation>({name: DefaultLocation.Name, code: DefaultLocation.Code});
    const [verifyStorageAccountError, setVerifyStorageAccountError] = useState("");
    const { verifyCloudShellProviderRegistration, updateUserSettings } = useARMHelper();
    const logger = useLogger(getARMEndpoint(), {});
    const [hideErrorDialog, setHideErrorDialog] = useState(true);
    const [errorMessages, setErrorMessages] = useState({header: '', content: ''});
    const { correlationId } = useContext(CorrelationIdContext);
    const language = useTranslation().i18n.language;

    function verifyStorageAccount(userSettings: UserSetting) {
        const storageProfile = getStorageProfile(userSettings);
        const storageAccountResourceId = storageProfile?.storageAccountResourceId;
        const targetUri = getARMEndpoint() + storageAccountResourceId + '?api-version=' + storageApiVersion;
        
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language,
        };

        axios.get(targetUri, { headers })
            .then(() => {
                if (!userSettings.sessionType) {
                    userSettings.sessionType = SessionType.Mounted;
                    updateUserSettings(userSettings, true);
                }
                userSettingsDispatch({
                    type: UserSettingsActionType.GetUserSettings,
                    payload: userSettings,
                });
            }).catch(error => {
                setVerifyStorageAccountError(error.response.data.error.message);
            }).finally(() => {
                setLoadingUserSettings(false);
            });
    }

    function getUserSettings(retryCount = 0) {
        const targetUri = getARMEndpoint() + '/providers/Microsoft.Portal' + getAzureConsoleProviderLocation() + '/userSettings/cloudconsole?api-version=' + consoleApiVersion;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language
        };
        
        const start = Date.now();
        axios.get(targetUri, { headers })
        .then(response => {
            const locationName = response.headers["x-ms-request-id"]?.split(':')[0].toLowerCase() || DefaultLocation.Name;
            const locationCode = response.headers["x-ms-console-required-location-code"] || DefaultLocation.Code;
            setUserLocation({name: locationName, code: locationCode});
            const userSettings = response.data.properties;
            const userSettingsSubscriptionId = getUserSettingsSubscriptionId(userSettings);
            if (userSettingsSubscriptionId != null) {
                verifyCloudShellProviderRegistration(userSettingsSubscriptionId)
            } else {
                logger.clientTelemetry(TelemetryLoggerEnum.SubscriptionRegistration_Invalid, { "sessionType": userSettings.sessionType, "storageProfile": userSettings.storageProfile }, {}, 0);
                console.log("SessionType: '" + userSettings.sessionType + "'Either StorageProfile subscription or UserSubscription: Null.")
            }
            const ephemeralSession = userSettings.sessionType == SessionType.Ephemeral;
            if (ephemeralSession) {
                userSettingsDispatch({
                    type: UserSettingsActionType.GetUserSettings,
                    payload: userSettings,
                });
                setLoadingUserSettings(false);
            } else {
                verifyStorageAccount(userSettings);
            }
        }).catch(error => {
            if (error.response.status === 404) {
                //if no usersettyings mounted
                //save location and show the onboarding dialogs
                const locationName = error.response.headers["x-ms-console-required-location"].replace(/ /g, "").toLowerCase();
                const locationCode = error.response.headers["x-ms-console-required-location-code"];
                setUserLocation({name: locationName, code: locationCode});
                setLoadingUserSettings(false);
            } else if (error.response.status > 399 && error.response.status < 500) {
                setLoadingUserSettings(false);
                //show error dialog
                setHideErrorDialog(false);
                setErrorMessages({header: '', content: error.response.data?.error?.message || ''});
                logger.clientRequest(RequestLoggerEnum.UserSettings_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, correlationId);
            } else {
                if (retryCount < RetryLimit.RequestRetryLimit) {
                    //retry
                    getUserSettings(retryCount + 1);
                } else {
                    setLoadingUserSettings(false);
                    //show error dialog if reach retry limit
                    setHideErrorDialog(false);
                    setErrorMessages({header:'', content: JSON.stringify(error.response.data) || ''});
                    logger.clientRequest(RequestLoggerEnum.UserSettings_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, correlationId);
                }
            }
        });
    }
    useEffect(() => {
        if (accessToken != "") {
            getUserSettings();
        }
    }, [accessToken]);

    if (loadingUserSettings) {
        return <div 
            style={{ 
                height: "100%", 
                width: "auto",
                backgroundColor: "#000000",
            }} 
        />;
    }

    return (
        <>
        {
            hideErrorDialog
            ?
            <UserSettingsContext.Provider value={{ userSettingsState, userSettingsDispatch }}>
                <ThemeProvider theme={currentTheme}>
                    {
                        userSettingsState.initialized //If mounted
                        ?
                        //Display main exeperience (i.e. Terminal and others)
                        <Session
                            currentTheme={currentTheme}
                            setTheme={setTheme}
                            setVerifyStorageAccountError={setVerifyStorageAccountError} 
                        />
                        :
                        // Attempt to mount storage (Try quietly, and if that fails display first experience)
                        <UserSettings
                            currentTheme={currentTheme}
                            userLocation={userLocation}
                            verifyStorageAccountError={verifyStorageAccountError}
                            setVerifyStorageAccountError={setVerifyStorageAccountError} 
                        />
                    }
                </ThemeProvider>
            </UserSettingsContext.Provider>
            :
            <div
                style={{ height: "100%", width: "auto", backgroundColor: "rgba(0, 0, 0, 0.5)" }} 
            >
                <ErrorDialog
                    hideErrorDialog={hideErrorDialog} 
                    errorMessages={errorMessages}
                />
            </div>
        }
        </>
    );
};
