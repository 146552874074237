/*
#############
# ShellType Select #
#############

-   **Inputs**:
    -   `currentTheme`
        -   A theme object that contains design standardization
    -   `showShellTypeSelect`
        -   Boolean to hide or show the component
    -   `setShellType`
        -   Sets a ShellType type for the other components
    -   `Quit`
        -   Call back function that can close the application
        -   Should be the same as `MountStorage`
-   **Purpose**: This component lets users pick between Bash and PowerShell for their terminal
-   **To do**:
    -   Backend communication
*/

// External Components
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import { mergeStyleSets, FontWeights, Modal, Theme } from "@fluentui/react";
import { IconButton, DefaultButton } from "@fluentui/react/lib/Button";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { mergeStyles, registerIcons } from "@fluentui/react/lib/Styling";
import { PostMessageHelperType, ShellType, UserSettingsActionType } from "../../../common/consts";
import { useTranslation } from "react-i18next";
import { UserSettingsContext } from "../../DataProvider/UserSettingsProvider";
import { postMessageHelper } from "../../Util/Utilities";
import { smallResolutionDialogStyles } from "../../../common/styles";

// Icons
registerIcons({
    icons: {
        "cloudshell-svg": (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <defs>
                    <linearGradient
                        id="ba88ea91-3b3a-4da0-9b97-9a10ebaa3e50"
                        x1="12.805"
                        y1="15.237"
                        x2="12.805"
                        y2="7.962"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stopColor="#0078d4" />
                        <stop offset=".156" stopColor="#1380da" />
                        <stop offset=".528" stopColor="#3c91e5" />
                        <stop offset=".822" stopColor="#559cec" />
                        <stop offset="1" stopColor="#5ea0ef" />
                    </linearGradient>
                </defs>
                <path
                    d="M15.6 5.7H.067V2.99a.226.226 0 0 1 .226-.226H15.37a.227.227 0 0 1 .227.226z"
                    fill="#949494"
                />
                <path
                    d="M.063 5.7h15.53a0 0 0 0 1 0 0v9.254a.231.231 0 0 1-.231.231H.294a.231.231 0 0 1-.231-.231V5.7a0 0 0 0 1 0 0z"
                    fill="#bfbfbf"
                />
                <path fill="#005ba1" d="M1.135 6.5h13.37v7.57H1.135z" />
                <g fill="#fff">
                    <rect x="4.576" y="10.738" width="2.837" height=".527" rx=".263" />
                    <path d="M2.675 11.215L4.35 9.54a.169.169 0 0 0 0-.243l-1.7-1.633a.17.17 0 0 0-.244.01L2.28 7.8a.17.17 0 0 0 .01.236L3.623 9.3a.171.171 0 0 1 0 .242l-1.3 1.333a.171.171 0 0 0 0 .239l.1.1a.17.17 0 0 0 .252.001z" />
                </g>
                <path
                    d="M17.937 12.958a2.3 2.3 0 0 0-2-2.216 2.906 2.906 0 0 0-2.994-2.78A2.982 2.982 0 0 0 10.092 9.9a2.752 2.752 0 0 0-2.42 2.648 2.793 2.793 0 0 0 2.89 2.684l.255-.01H15.5a.465.465 0 0 0 .124-.019 2.333 2.333 0 0 0 2.313-2.244z"
                    fill="url(#ba88ea91-3b3a-4da0-9b97-9a10ebaa3e50)"
                />
            </svg>
        ),
    },
});

// Interface
interface ShellTypeSelectProps {
    currentTheme: Theme;
    showShellTypeSelect: boolean;
    setShellType: (newShellType: ShellType) => void;
    verifyStorageAccountError: string
}

// Components
const ShellTypeSelect = (shellTypeSelectProps: ShellTypeSelectProps) => {
    const { userSettingsState, userSettingsDispatch } = React.useContext(UserSettingsContext);
    const titleId = useId("OS Selector");
    const { t, i18n } = useTranslation();
    // Modal styles
    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
            maxWidth: "700px",
            selectors: smallResolutionDialogStyles
        },
        header: [
            {
                fontSize: shellTypeSelectProps.currentTheme.fonts.xLargePlus.fontSize,
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "5px 5px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });
    const iconClass = mergeStyles({
        fontSize: 50,
        height: 50,
        width: 50,
        margin: "0 25px",
        style: { color: shellTypeSelectProps.currentTheme.semanticColors.bodyText },
    });

    // Handles OS Selection
    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={titleId}
            isOpen={shellTypeSelectProps.showShellTypeSelect}
            onDismiss={() => postMessageHelper(PostMessageHelperType.Close, '')}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <FontIcon
                    aria-label="Cloud Shell Logo"
                    iconName="cloudshell-svg"
                    className={iconClass}
                />

                <header role="heading" id={titleId}>{t("welcomeMessage")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: shellTypeSelectProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            color: shellTypeSelectProps.currentTheme.semanticColors.bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={() => postMessageHelper(PostMessageHelperType.Close, '')}
                />
            </div>
            <div className={contentStyles.body}>
                {shellTypeSelectProps.verifyStorageAccountError != "" && <p style={{ color: 'red' }}>{shellTypeSelectProps.verifyStorageAccountError}.</p>}
                <p>{t("osTypeSelectionPrompt")}</p>
                
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <DefaultButton
                        onClick={() => {
                            shellTypeSelectProps.setShellType(ShellType.Bash);
                            if (userSettingsState.properties.preferredShellType != ShellType.Bash) {
                                userSettingsDispatch({
                                    type: UserSettingsActionType.UpdateShellType,
                                    payload: ShellType.Bash,
                                });
                            }
                        }}
                        text={t("bashStartOption")}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={() => {
                            shellTypeSelectProps.setShellType(ShellType.PowerShell);
                            if (userSettingsState.properties.preferredShellType != ShellType.PowerShell) {
                                userSettingsDispatch({
                                    type: UserSettingsActionType.UpdateShellType,
                                    payload: ShellType.PowerShell
                                });
                            }
                        }}
                        text={t("powershellStartOption")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ShellTypeSelect;
