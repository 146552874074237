/*
############################
# Deployment In Progress Dialog #
##################

-   **Inputs**:
    -   `currentTheme`
        -   Theme object
    -   `showDeploymentInProgress`
        -   Boolean to hide and show
    -   `selectedSubscriptionName`
        -   Subscription name
    -   `storage`
        -   storage info
-   **Purpose**: Show the deployment details in a dialog
*/

// External Components
import * as React from "react";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    Theme,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import InProgressDots from "../../Util/InProgressDots";
import { Location, OnboardingInfo } from "../../../common/types";
import { getLocationList } from "../../Util/Utilities";
import { useMemo } from "react";
import { smallResolutionFullScreenDialogStyles } from "../../../common/styles";

// Interface
interface DeploymentInProgressDialogProps {
    currentTheme: Theme;
    showDeploymentInProgress: boolean; // variable to display or not
    selectedSubscriptionName: string | undefined;
    onboardingInfo: OnboardingInfo | null;
}

export const DeploymentInfo = (deploymentInfoDialogProps: {
    selectedSubscriptionName: string | undefined;
    onboardingInfo: OnboardingInfo | null;
}) => {
    const { t } = useTranslation();

    const resourceGroupName = useMemo(() => {
        if (deploymentInfoDialogProps.onboardingInfo?.resourceGroupID != "") {
            const name = deploymentInfoDialogProps.onboardingInfo?.resourceGroupID.substring(deploymentInfoDialogProps.onboardingInfo?.resourceGroupID.indexOf('/resourceGroups/') + 16);
            return name ? name : deploymentInfoDialogProps.onboardingInfo?.resourceGroupID;
        }
    }, [deploymentInfoDialogProps.onboardingInfo?.resourceGroupID]);

    const regionDisplayName = useMemo(() => {
        if (deploymentInfoDialogProps.onboardingInfo?.location != "") {
            const locationList: Array<Location> = getLocationList();
            const location = locationList.find((location) => location.name === deploymentInfoDialogProps.onboardingInfo?.location);
            return t(`${location?.displayName}`);
        }
    }, [deploymentInfoDialogProps.onboardingInfo?.location]);

    return (
            <p  style={{marginTop: '0px', marginBottom: '3px'}}>
                {
                    deploymentInfoDialogProps.selectedSubscriptionName &&
                    <>
                        <br></br>
                        {t("subscriptionLabel")}: {deploymentInfoDialogProps.selectedSubscriptionName}
                    </>
                }
                {
                    resourceGroupName &&
                    <>
                        <br></br>
                        {t("resourceGroupLabel")}: {resourceGroupName}
                    </>
                }
                {
                    deploymentInfoDialogProps.onboardingInfo?.storageAccountName &&
                    <>
                        <br></br>
                        {t("storageAccountLabel")}: {deploymentInfoDialogProps.onboardingInfo?.storageAccountName}
                    </>
                }
                {
                    deploymentInfoDialogProps.onboardingInfo?.fileShareName &&
                    <>
                        <br></br>
                        {t("fileShareLabel")}: {deploymentInfoDialogProps.onboardingInfo?.fileShareName}
                    </>
                }
                {
                    regionDisplayName &&
                    <>
                        <br></br>
                        {t("regionLabel")}: {regionDisplayName}
                    </>
                }
            </p>
    );
};

// Component
const DeploymentInProgressDialog = (deploymentInProgressDialogProps: DeploymentInProgressDialogProps) => {
    const { t } = useTranslation();

    const contentStyles = mergeStyleSets({
        container: {
            minWidth: 590,
            minHeight: 254,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            selectors: smallResolutionFullScreenDialogStyles,
        },
        header: [
            {
                flex: "1 1 auto",
                fontSize: 18,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "25px 5px 0px 24px",
            },
        ],
        body: {
            padding: "0 22px 20px 26px",
            overflowY: "hidden",
        },
    });

    return (
        <Modal
            allowTouchBodyScroll={true}
            titleAriaId={"titleId"}
            isOpen={deploymentInProgressDialogProps.showDeploymentInProgress}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={"titleId"}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                        <InProgressDots />
                        </div>
                        {t("deploymentInProgressHeader")}
                    </div>
                </header>
            </div>

            <div className={contentStyles.body}>
                <DeploymentInfo 
                    selectedSubscriptionName={deploymentInProgressDialogProps.selectedSubscriptionName}  
                    onboardingInfo={deploymentInProgressDialogProps.onboardingInfo}
                />
            </div>
        </Modal>
    );
};

export default DeploymentInProgressDialog;