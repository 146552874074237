import React from "react";
import EditorDirtySVG from "./EditorDirtySVG";

interface EditorDirtyProps {
    dirty: boolean;
}

const EditorDirty = (editorDirtyProps: EditorDirtyProps) => {
    return (
        <span style={{display: editorDirtyProps.dirty ? "flex" : "none", alignItems: "center", paddingLeft: "3px"}}>
            <EditorDirtySVG />
        </span>
    )
};

export default EditorDirty;