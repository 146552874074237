/*
##################
# TokenContext #
##################

-   **Purpose**: This component is used to get the access token from Portal.
*/

import React, { FC, useContext, useRef, useState } from "react";
import { AccessTokenContextType, SshCertificate, ParentMessageContextType, customJwtPayload, CommandInjectionBody, Token } from "../../common/types";
import jwt_decode from "jwt-decode";
import { getQueryParameter, parseQueryParams, postMessageHelper } from  "../Util/Utilities";
import { PostMessageHelperType, ShellType, allowedParentFrameAuthorities, validAudienceList } from "../../common/consts";
import { handleCommandEvtBody } from "../Util/CommandInjection";

export const AccessTokenContext = React.createContext<AccessTokenContextType>({
  accessToken: "",
  puid: "",
  tenantId: ""
});

export const ParentMessageContext = React.createContext<ParentMessageContextType>({
  token: {
    tokenValue: "",
    updateTime: null
  },
  cert: {
    errorMessage: "",
    expiresAt: 0,
    header: "",
    tokenType: ""
  },
  commands: "",
  setCommands: () => {},
  setToken: () => {},
  setCert: () => {},
  commandShellType: ShellType.Bash,
  setCommandShellType: () => {}
})

export const useAccessTokenContext = () => useContext(AccessTokenContext);
export const useParentMessageContext = () => useContext(ParentMessageContext);

export const AccessTokenProvider: FC = (props) => {
    const [accessToken, setAccessToken] = useState("");
    const [token, setToken] = useState<Token>({
      tokenValue: "",
      updateTime: null
    });
    const [puid, setPuid] = useState("");
    const [cert, setCert] = useState<SshCertificate>({
      errorMessage: "",
      expiresAt: 0,
      header: "",
      tokenType: ""
    });
    const [tenantId, setTenantId] = useState("");
    const [commands, setCommands] = useState("");
    const [commandShellType, setCommandShellType] = useState(ShellType.Bash);

    const trustedParentOrigin = useRef<string | null>(null);

    function getTokens() {
      postMessageHelper(PostMessageHelperType.GetToken, "");
    }

    function getConfig() {
      postMessageHelper(PostMessageHelperType.GetConfig, "");
    }
    
    function getCommands() {
      postMessageHelper(PostMessageHelperType.GetCommand, "");
    }

  function handleToken(authToken: any, tokenAudience: string) {
    if (!authToken) {
      console.error("No auth token provided");
      return;
    } else if (validAudienceList.indexOf(tokenAudience) >= 0) {
      console.log("Audience: " + tokenAudience)
      if (tokenAudience == 'arm' || tokenAudience == "") {
        setAccessToken(authToken);
        const claims = jwt_decode<customJwtPayload>(authToken);
        const tenantId = claims["tid"];
        setTenantId(tenantId);
        const puid = (claims.puid || claims.altsecid.split(",")[0].split(":")[2]).toLowerCase();
        setPuid(puid);
      }
      else if (tokenAudience.includes('ssh')) {
        setCert(authToken as SshCertificate);
      }
      else {
        setToken({tokenValue: authToken, updateTime: new Date()});
      }
    } else {
      console.error("Audience '" + tokenAudience + "' cannot be handled.");
    }
  }

  function handleCommandInjection(evt: MessageEvent) {
    const commandBody = evt.data.message;
    let cliType = evt.data.cliType;
    if(cliType.cliType) {
      cliType = cliType.cliType
    }
    if (cliType === "powershell") {
      cliType = ShellType.PowerShell;
    }
    setCommandShellType(cliType);
    
    setCommands(handleCommandEvtBody(commandBody as CommandInjectionBody));
  }

  function postMessageHandler(evt: MessageEvent) {
    if (process.env.NODE_ENV === 'production') {
      if (evt.origin == null || evt.origin !== trustedParentOrigin.current) {
        return;
      }
    }
    const data = evt.data || {};
    if (data.signature === "portalConsole") {
      switch (data.type) {
        case "postToken":
          if (!data.message) {
            console.error("No auth token in event, event: " + JSON.stringify(evt));
            getTokens();
            return;
          }
          handleToken(data.message, data.audience);
          break;
        case "postCommand":
          handleCommandInjection(evt);
          break;
        case "postConfig":
          handleToken(data.token, data.audience);
          break;
        default:
          console.log("message received " + data.message);
      }
    }
  }

    React.useEffect(() => {
        parseQueryParams();

        trustedParentOrigin.current = getQueryParameter("trustedAuthority") || null;

        const isTrustedOrigin = (function () {
          const trustedAuthority = (trustedParentOrigin.current?.split("//")[1] || "").toLowerCase();
          return allowedParentFrameAuthorities.some(function (origin: string) {
            // Verify that the requested trusted authority is an allowed origin
            return origin === trustedAuthority;
          });
        })();

        if (!isTrustedOrigin) {
          const errorMessage = "The origin '" + trustedParentOrigin + "' is not trusted.";
          console.error(errorMessage);
          throw new Error(errorMessage);
        }

        window.addEventListener("message", postMessageHandler, false);
        getTokens();

        getConfig();

        getCommands();

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
          window.removeEventListener("message", postMessageHandler)
        }
    },[]);
    
  return <ParentMessageContext.Provider value={{ token, setToken, cert, setCert, commands, setCommands, commandShellType, setCommandShellType }}><AccessTokenContext.Provider value={{ accessToken, puid, tenantId }} {...props}>
    </AccessTokenContext.Provider>
  </ParentMessageContext.Provider>
}

