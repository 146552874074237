import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { HttpMethod, RequestLoggerEnum, RetryLimit, armApiVersion, storageApiVersion } from '../../common/consts';
import { getARMEndpoint } from './Utilities';
import { useLogger } from './Logger';
import { useAccessTokenContext } from '../DataProvider/EventProvider';

const useFetch= <T>(resourceArg: string, eventName: RequestLoggerEnum) => {
  const { accessToken } = useAccessTokenContext();
  const language = useTranslation().i18n.language;
  const logger = useLogger(getARMEndpoint(), {});
  
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': accessToken,
      'Accept-Language': language
  };

  const getTargetUri = () => {
    let targetUri = "";
    const armEndpoint = getARMEndpoint();
    switch (eventName) {
        case RequestLoggerEnum.ResourceGroup_List:
            targetUri = `${armEndpoint}/subscriptions/${resourceArg}/resourceGroups?api-version=2017-05-10`
            break;
        case RequestLoggerEnum.StorageAccount_List:
            targetUri = `${armEndpoint}/${resourceArg}/providers/Microsoft.Storage/storageAccounts?api-version=${storageApiVersion}`
            break;
        case RequestLoggerEnum.FileShare_List:
            targetUri = `${armEndpoint}/${resourceArg}/fileServices/default/shares?api-version=2023-01-01`
            break;
        case RequestLoggerEnum.VirtualNetwork_List:
            targetUri = `${armEndpoint}/${resourceArg}/providers/Microsoft.Network/virtualNetworks?api-version=${armApiVersion}`
            break;
        case RequestLoggerEnum.NetworkProfile_List:
            targetUri = `${armEndpoint}/${resourceArg}/providers/Microsoft.Network/networkProfiles?api-version=${armApiVersion}`
            break;
        case RequestLoggerEnum.RelayNamespace_List:
            targetUri = `${armEndpoint}/${resourceArg}/providers/Microsoft.Relay/namespaces?api-version=2017-04-01`
            break;
    }
    return targetUri;
  };

  const getNewItems = (data: any) => {
    let newItems = [];
    switch (eventName) {
        case RequestLoggerEnum.ResourceGroup_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name,
                    location: item.location               
                }
            });
            break;
        case RequestLoggerEnum.StorageAccount_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name,
                    location: item.location,
                    sku: {
                        name: item.sku.name,
                        tier: item.sku.tier
                    }              
                }
            });
            break;
        case RequestLoggerEnum.FileShare_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name
                }
            });
            break;
        case RequestLoggerEnum.VirtualNetwork_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name,
                    location: item.location
                }
            });
            break;
        case RequestLoggerEnum.NetworkProfile_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name,
                    location: item.location,
                    containerNetworkInterfaceConfigurations: JSON.stringify(item.properties.containerNetworkInterfaceConfigurations).toLowerCase()
                }
            });
            break;     
        case RequestLoggerEnum.RelayNamespace_List:
            newItems = data.map((item: any)=>{
                return {
                    id: item.id,
                    name: item.name,
                }
            });
            break;    
    }
    return newItems;
  };

  const targetUri = useMemo(() => {
    if (resourceArg != "") {
        return getTargetUri();
    } else {
        return "";
    }
  }, [resourceArg]);

  const fetchDataHelper = (targetUri: string, retryCount = 0, items: T[] = []): Promise<any> => {
    const start = Date.now();

    return axios.get(targetUri, { headers })
        .then(res => {
            const newItems = getNewItems(res.data.value);
            items.push(...newItems);

            if (res.data.nextLink) {
                return fetchDataHelper(res.data.nextLink, 0 , items);
            } else {
                return {items, error: null};
            }
        }).catch((error: any) => {
            if (retryCount < RetryLimit.RequestRetryLimit) {
                return fetchDataHelper(targetUri, retryCount + 1 , items);
            } else {
                logger.clientRequest(eventName, {}, Date.now() - start, HttpMethod.Get, targetUri || "", "", "", "", 0, error.respnse?.status);
                return { items: [], error };
            }
        }); 
  }

    useEffect(() => {
        let ignore = false;
        const fetchData = async () => {
            const {items, error} = await fetchDataHelper(targetUri);
            setLoading(false);
            if (!ignore) {
                if (error) {
                    setError(error);
                } else {
                    setData(items);
                }
            }
        };

        setData([]);

        if (resourceArg != "") {
            setLoading(true);
            fetchData();
        }

        return () => {
            ignore = true; //avoid race condition
        }
    }, [resourceArg]); 

  return { data, loading, error };
};

export default useFetch;
