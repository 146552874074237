import React from 'react';
import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';

const InProgressDots = () => {
    const wrapperStyle = { display: 'flex' };

    const getCustomElements = () => {
        return (
            <div style={wrapperStyle}>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, height: 6, width: 6 },
                        { type: ShimmerElementType.gap, width: 6, height: 6 },
                        { type: ShimmerElementType.line, height: 6, width: 6 },
                        { type: ShimmerElementType.gap, width: 6, height: 6 },
                        { type: ShimmerElementType.line, height: 6, width: 6 },
                    ]}
                />
            </div>
        );
    };

    return (
        <div style={{ display: 'flex' }}>
            <Shimmer customElementsGroup={getCustomElements()} />
        </div>
    );
};

export default InProgressDots;