import * as React from "react";
import { SelectableOptionMenuItemType } from "@fluentui/react/lib/SelectableOption";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown, IDropdownOption, IDropdownProps, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownOptionKey, FieldType, Filter, maxDropdownListSize } from "../../../common/consts";
import { Announced, IconButton, Stack } from "@fluentui/react";

interface FilterableDropdownProps extends IDropdownProps {
    options: any;
    ariaLabel: string;
}

// Component
const FilterableDropdown = (filterableDropdownProps: FilterableDropdownProps) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState<Filter>(""); // filter variable

    const headerStyles = {
        // Styles for the dropdown header
        dropdownItemHeader: {
            fontWeight: 600,
            color: "rgb(1, 92, 218)",
            height: "36px",
            lineHeight: "36px",
            padding: "0 8px",
            userSelect: "none",
        } as React.CSSProperties,
        dropdownOptionText: {
            margin: "1px",
            textOverflow: "ellipsis",
            overflow: "hidden",
        } as React.CSSProperties,
    };

    function defaultOptionsFilterMethod(options: IDropdownOption[], filterText: string): IDropdownOption[] {
        const matchingOptions: IDropdownOption[] = [];
        const lowercaseFilter = filterText.toLocaleLowerCase();
      
        for (const option of options) {
          if (option.itemType != SelectableOptionMenuItemType.Header && option.itemType != SelectableOptionMenuItemType.Divider) {
            if (defaultOptionFilter(option, lowercaseFilter) && matchingOptions.length < maxDropdownListSize) {
              matchingOptions.push(option);
            }
          }
        }
        return matchingOptions;
      }
      
    function defaultOptionFilter(option: IDropdownOption, lowercaseFilterText: string): boolean {
        if (option.data && option.data.subscriptionId) {
            //if it's subscription dropdown, filter by both subscription name and subscription id
            return option.text.toLocaleLowerCase().indexOf(lowercaseFilterText) >= 0 || option.key.toLocaleString().indexOf(lowercaseFilterText) >= 0;
        } else if (option.data && option.data.regionalDisplayName) {
            //if it's location dropdown, filter by location name, location display name and location regional display name
            const fields = [t(option.data.regionalDisplayName), t(option.data.displayName), option.data.name];
            return fields.some(f => f.toLocaleLowerCase().indexOf(lowercaseFilterText) >= 0);
        } else {
            //if other dropdowns, filter by name only
            return option.text.toLocaleLowerCase().indexOf(lowercaseFilterText) >= 0;
        }
    }

    const filterBox = (
        <TextField
            onChange={(_, filter) => setFilter(filter)}
            styles={{ root: { margin: "10px 16px 5px" } }}
            key="Filter"
            placeholder={t("filterItems")}
        />
    );

    const filteredItems = useMemo(() => {
        if (!filter) {
            return filterableDropdownProps.options.slice(0, maxDropdownListSize);
        }
        return defaultOptionsFilterMethod(filterableDropdownProps.options, filter);
    }, [filterableDropdownProps.options, filter]);

    const matchingResultCount = filteredItems.length;

    const announcementMessage = useMemo(() => {
        let message: string;
        if (matchingResultCount > 1) {
            message = t("multipleResultsFound", { matchingResultCount: matchingResultCount })
        } else if (matchingResultCount === 1) {
            message = t("oneResultsFound");
        } else {
            message = t("noResultsFound");
        }
        return message;
    }, [matchingResultCount]);

    const currentOptions = useMemo<IDropdownOption[]>(() => {
        const result: IDropdownOption[] = [
            { key: DropdownOptionKey.Filter, text: "", data: FieldType.filter },
            { key: DropdownOptionKey.Divider, text: "", itemType: SelectableOptionMenuItemType.Divider },
        ];

        if (filteredItems.length) {
            result.push(...filteredItems);
        } else {
            result.push({
                key: DropdownOptionKey.NoOptions,
                text: "",
                data: FieldType.noOption,
            });
        }
        return result;
    }, [filter, filteredItems]);

    const onRenderLabel = React.useCallback(() => {
        return filter ? <Announced message={announcementMessage} aria-live="assertive" /> : null;
    }, [announcementMessage, filter]);

    const onRenderItem = (option: any, defaultRender: any) => {
        switch (option.key) {
            case DropdownOptionKey.Filter:
                return filterBox;
            case DropdownOptionKey.NoOptions:
                return (
                    <p key="noOptions" style={{ textAlign: "center", margin: "5px" }}>
                        {t("noResultsFound")}
                    </p>
                );
            case DropdownOptionKey.RecommendedHeader:
            case DropdownOptionKey.OtherHeader:
                return (
                    <Stack
                        key={option.key}
                        className="ms-Dropdown-header"
                        horizontal
                        verticalAlign="center"
                        style={headerStyles.dropdownItemHeader}
                    >
                        <span className="ms-Dropdown-optionText">{option.text}</span>
                        <IconButton
                            iconProps={{ iconName: "Info" }}
                            styles={{ root: { marginBottom: -3 } }}
                            title={
                                    option.key === DropdownOptionKey.RecommendedHeader
                                    //Todo: update info
                                    ? "recommended"
                                    : option.key === DropdownOptionKey.OtherHeader
                                    ? t("other")
                                    : ""
                            }
                        />
                    </Stack>
                );
            default:
                return defaultRender(option);
        }
    };

    return (
        <>
            <Dropdown
                {...filterableDropdownProps}
                placeholder={filterableDropdownProps.placeholder || t("select")}
                onRenderLabel={onRenderLabel}
                onRenderItem={onRenderItem} // Renders dropdown items (adds in filter box)
                onDismiss={() => setFilter("")} // Resets filter on click away
                options={currentOptions}
                ariaLabel={filterableDropdownProps.ariaLabel}
                aria-required
            /> 
        </>
    );
};

export default FilterableDropdown; 