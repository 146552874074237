/*
############################
# Close Confirmation Dialog #
##################

-   **Inputs**:
    -   `hideCloseDialog`
        -   Boolean to hide and show
    -   `toggleCloseDialog`
        -   Toggle the hide show state
-   **Purpose**: Confirm quit
-   **To do**:
    -   Listener event

*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { postMessageHelper } from "../../Util/Utilities";
import { PostMessageHelperType } from "../../../common/consts";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface CloseConfirmDialogProps {
    // These are the call back functions and data from parent
    hideCloseDialog: boolean;
    toggleCloseDialog: () => void;
}

// Component
const CloseConfirmDialog = (closeConfirmDialogProps: CloseConfirmDialogProps) => {
    const { t } = useTranslation();
    const labelId: string = useId("closeDialogLabel");
    const subTextId: string = useId("closeSubTextLabel");
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
        }),
        [labelId, subTextId]
    );

    return (
        // Normal dialog screen
        <>
            <Dialog
                hidden={closeConfirmDialogProps.hideCloseDialog} //variable to hide
                onDismiss={closeConfirmDialogProps.toggleCloseDialog} // function that hides
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("closeConfirmation"),
                    closeButtonAriaLabel: t("cancelButton"),
                    subText: t("UnsavedEditsWillBeDiscarded"),
                }}
                modalProps={modalProps}
            >
            <DialogFooter className={dialogFooterStyles}>
                    <PrimaryButton
                        onClick={() => postMessageHelper(PostMessageHelperType.Close, '')} // Close the application
                        text={t("closeButton")}
                    />
                    <DefaultButton
                        onClick={closeConfirmDialogProps.toggleCloseDialog}
                        text={t("cancelButton")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default CloseConfirmDialog;
