/* 
#########################
# Storage Account Dropdown #
#########################
-   **Uses user specific data**: `true`
    -   Storage Account list should be unique to every user.
-   **Allows user to create new element**: `false`
-   **Fetches based on**: Bearer token
-   **Inputs**:
    -   `storageAccountList`
        -   An ajax response json of storageAccounts
    -   `selectedStorageAccountList`
        -   current selected storage account
    -   `setSelectedStorageAccountList`
        -   Call back function to set the selected storage account
-   **Purpose**: Let users select and filter a storage account from a dropdown lit
-   **Todo**:
    -   Backend communication
*/

// Libraries
import * as React from "react";
import { useEffect, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import FilterableDropdown from "./FilterableDropdown";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { useNonInitialEffect } from "../../Util/useNonInitialEffect";
import { StorageAccount } from "../../../common/types";
import { useTranslation } from "react-i18next";
import useFetch from "../../Util/useFetch";
import { MountState, RequestLoggerEnum } from "../../../common/consts";

interface StorageAccountDropdownProps {
    selectedResourceGroup: string;
    setSelectedStorageAccount: (storageAccount: StorageAccount | null) => void;
    updateDeploymentTypeAndShowError: (deploymentType: MountState, error: any) => void;
}

const StorageAccountDropdown = React.memo((storageAccountProps: StorageAccountDropdownProps) => {
    const { t } = useTranslation();
    const { data: storageAccountList, loading: isStorageAccountsLoading, error: storageAccountListError } = useFetch<StorageAccount>(storageAccountProps.selectedResourceGroup, RequestLoggerEnum.StorageAccount_List);
    const storageAccounts = useMemo(() => storageAccountList || [], [storageAccountList]);
    const [selectedKey, setSelectedKey] = React.useState<string>("");
    // Build the set of dropdown items
    const options = useMemo(() => {
        storageAccounts.sort((a,b)=> ( a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0));
        const items: IDropdownOption[] = [];

        for (const storageAccount of storageAccounts) {
            items.push({
                key: storageAccount.id,
                text: storageAccount.name,
                data: storageAccount
            });
        }

        return items;
    }, [storageAccounts]);

    useEffect(() => {
        if (storageAccountListError) {
            storageAccountProps.updateDeploymentTypeAndShowError(MountState.AdvancedMount, storageAccountListError);
        }
    }, [storageAccountListError]); 

    useNonInitialEffect(() => {
        // Make sure we clear the previous selection for a new subscription or resource group
        storageAccountProps.setSelectedStorageAccount(null);
        setSelectedKey("");
    }, [storageAccountProps.selectedResourceGroup]);

    return (
        <>
            <Shimmer isDataLoaded={!isStorageAccountsLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={selectedKey}
                    onChange={(_event: React.FormEvent<HTMLDivElement>, storageAccountOption: any) => {
                        setSelectedKey(storageAccountOption.key as string);
                        const storageAccount = storageAccountList.find(storage => storage.id === storageAccountOption.key);
                        storageAccountProps.setSelectedStorageAccount(storageAccount || null);
                    }}
                    placeholder={t("provideExistingStorageAccount")}
                    disabled={storageAccountProps.selectedResourceGroup === ""}
                    ariaLabel={t("storageAccountName")}
                />
            </Shimmer>
        </>
    );
});

StorageAccountDropdown.displayName = "StorageAccount-Dropdown"; // export name

export default StorageAccountDropdown;
