import { useAccessTokenContext } from "../DataProvider/EventProvider";
import { getARMEndpoint, getAzureConsoleProviderLocation, waitForDelay } from "./Utilities";
import axios from "axios";
import { useLogger } from "./Logger";
import { FontStyle, HttpMethod, RequestLoggerEnum, ProvisioningState, ResourceNotFound, ResourceProviderNamespace, RetryLimit, SubscriptionState, armApiVersion, armApiversion2022, consoleApiVersion, storageApiVersion } from "../../common/consts";
import { Subscription, UserSetting } from "../../common/types";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CorrelationIdContext } from "../DataProvider/IdProviders";

export function useARMHelper() {
    const { accessToken } = useAccessTokenContext();
    const logger = useLogger(getARMEndpoint(), {});
    const { correlationId }  = useContext(CorrelationIdContext);
    const language = useTranslation().i18n.language;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': accessToken,
        'Accept-Language': language
    };

    const getResourceGroup = (targetUri: string) => {
        return axios.get(targetUri, { headers })
            .then(res => {
                return res.data;
            }).catch(error => {
                throw error;
            });
    }

    const getStorageAccount = (storageAccountResourceId: string, retriesForGetStorageAccount?: number, startTime?: number): Promise<any> => {
        const start = startTime || Date.now();

        const targetUri = getARMEndpoint() + storageAccountResourceId + '?api-version=' + storageApiVersion;

        return axios.get(targetUri, { headers })
            .then(async res => {
                if (retriesForGetStorageAccount != undefined) {
                    //if it's utilized to confirm the completion of the provisioning process for a new storage account
                    if (res.data.properties.provisioningState === ProvisioningState.Succeeded) {
                        return { storageAccountData: ProvisioningState.Succeeded, error: null };
                    } else if (res.data.properties.provisioningState === ProvisioningState.Failed) {
                        logger.clientTelemetry(RequestLoggerEnum.StorageAccount_Put_Failure, { storageAccount: storageAccountResourceId, provisioningState: res.data.properties.provisioningState }, { retryCount: retriesForGetStorageAccount }, Date.now() - start);
                        return { storageAccountData: ProvisioningState.Failed, error: null };
                    } else {
                        if (retriesForGetStorageAccount >= RetryLimit.GetStorageAccount) {
                            logger.clientTelemetry(RequestLoggerEnum.StorageAccount_Put_Timeout, { storageAccount: storageAccountResourceId, provisioningState: res.data.properties.provisioningState }, { retryCount: retriesForGetStorageAccount }, Date.now() - startTime!);
                            return { storageAccountData: res.data.properties.provisioningState, error: null };
                        }
                        else {
                            await waitForDelay();
                            return getStorageAccount(storageAccountResourceId, retriesForGetStorageAccount + 1, start);
                        }
                    }
                } else {
                    //if it's utilized to get the storage account properties
                    return { storageAccountData: res.data, error: null };
                }
            }).catch(async error => {
                if (retriesForGetStorageAccount != undefined) {
                    if (retriesForGetStorageAccount >= RetryLimit.GetStorageAccount) {
                        logger.clientRequest(RequestLoggerEnum.StorageAccount_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, null);
                        return { storageAccountData: null, error: error };
                    }
                    else {
                        await waitForDelay();
                        return getStorageAccount(storageAccountResourceId, retriesForGetStorageAccount + 1, start);
                    }
                } else {
                    if (error.response.status != 404 && error.response.data?.error?.code !== ResourceNotFound) {
                        logger.clientRequest(RequestLoggerEnum.StorageAccount_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, null);
                    }
                    return { storageAccountData: null, error: error };
                }
            });
    };

    const putResourceGroup = (targetUri: string, data: string) => {
        const start = Date.now();

        return axios.put(targetUri, data, { headers })
            .then(res => {
                return res;
            }).catch(error => {
                logger.clientRequest(RequestLoggerEnum.ResourceGroup_Create, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, error.response.status, null);
                throw error;
            });
    };

    const putStorageAccount = (targetUri: string, data: string) => {
        const start = Date.now();

        return axios.put(targetUri, data, { headers })
            .then(res => {
                return res;
            }).catch(error => {
                logger.clientRequest(RequestLoggerEnum.StorageAccount_Create, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, error.response.status, null);
                throw error;
            });
    }; 

    const getSubscriptionsHelper = (retryCount = 0, targetUri?: string, subscriptions:Subscription[] = []): Promise<any> => {
        const start = Date.now();

        targetUri = targetUri || getARMEndpoint() + '/subscriptions?api-version=' + armApiVersion;

        return axios.get(targetUri, { headers })
            .then(res=> {
                const newSubscriptions: Subscription[] = res.data.value?.map((item: any)=>{
                    return {
                        id: item.id,
                        subscriptionId: item.subscriptionId,
                        displayName: item.displayName,
                        state: item.state,
                        subscriptionPolicies: {
                            locationPlacementId: item.subscriptionPolicies?.locationPlacementId,
                            quotaId: item.subscriptionPolicies?.quotaId,
                            spendingLimit: item.subscriptionPolicies?.spendingLimit
                        }                   
                    }
                });
                subscriptions.push(...newSubscriptions);
                if (res.data.nextLink) {
                    return getSubscriptionsHelper(0, res.data.nextLink, subscriptions);
                } else {
                    subscriptions = subscriptions.filter(s => s.state != SubscriptionState.Disabled && s.state != SubscriptionState.Deleted);
                    return {subscriptions, error: null};
                }
            }).catch(error => {
                if (retryCount < RetryLimit.RequestRetryLimit) {
                    return getSubscriptionsHelper(retryCount + 1, targetUri, subscriptions);
                } else {
                    logger.clientRequest(RequestLoggerEnum.Subscription_List, {}, Date.now() - start, HttpMethod.Get, targetUri || "", "", "", "", 0, error.respnse?.status);
                    return { subscriptions: [], error };
                }
            }); 
    }

    const updateStorageAccountTags = (targetUri: string, data: string) => {
        return axios.patch(targetUri, data, { headers })
                .catch((error) => {
                    console.error('Error updating storage account tags: ' + error);
                });
    }

    const verifyCloudShellProviderRegistration = (subscriptionId: string) => {
        const resourceArg = '/subscriptions/' + subscriptionId + '/providers/' + ResourceProviderNamespace.CloudShell;
        const targetUri = getARMEndpoint() + resourceArg + '?api-version=' + armApiversion2022;

        const start = Date.now();

        return axios.get(targetUri, { headers })
            .then(res => {
                const registrationState = res.data.registrationState;
                if (registrationState != "Registered") {
                   registerCloudShellProvider(subscriptionId);
                }
                logger.clientTelemetry(RequestLoggerEnum.CloudshellProvider_Get, { "subscriptionId": subscriptionId, "SubscriptionState": registrationState }, {}, Date.now() - start)
                return res;
            }).catch(error => {
                logger.clientRequest(RequestLoggerEnum.CloudshellProvider_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, null);
                throw error;
            });
    };  

    const registerCloudShellProvider = (subscriptionId: string) => {
        const resourceArg = '/subscriptions/' + subscriptionId + '/providers/' + ResourceProviderNamespace.CloudShell + '/register';
        const targetUri = getARMEndpoint() + resourceArg + '?api-version=' + armApiversion2022;
        const start = Date.now();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        }

        axios.post(targetUri, null, { headers })
        .then(res => {
            logger.clientRequest(RequestLoggerEnum.CloudshellProviderRegistration_Success, { "subscriptionId": subscriptionId }, Date.now() - start, HttpMethod.Post, "", "", "", "", 0, res.status, null);
        }).catch(error => {
            logger.clientRequest(RequestLoggerEnum.CloudshellProviderRegistration_Failure, { "subscriptionId": subscriptionId, "armresponse": error.response.data }, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, null);
        }); 
    }

    const updateUserSettings = async (userSettings: UserSetting, isBackFill?: boolean) => {
        const start = Date.now();
        
        const targetUri = getARMEndpoint() + '/providers/Microsoft.Portal' + getAzureConsoleProviderLocation() + '/userSettings/cloudconsole?api-version=' + consoleApiVersion;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language,
            'x-ms-correlation-request-id': correlationId
        };

        //temporary fix for updating font style
        if (userSettings.terminalSettings.fontStyle !== FontStyle.Monospace && userSettings.terminalSettings.fontStyle !== FontStyle.Courier) {
            userSettings.terminalSettings.fontStyle = FontStyle.Monospace;
        }

        if (isBackFill) {
            try {
                const res = await axios.put(targetUri, { properties: userSettings }, { headers });
                logger.clientRequest(RequestLoggerEnum.BackfillSettings_Success, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, res.status, null);
                return null;
            }
            catch (err: any) {
                logger.clientRequest(RequestLoggerEnum.BackfillSettings_Failure, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, err.response.status, null);
                return err;
            }
        } else {
            try {
                const res = await axios.put(targetUri, { properties: userSettings }, { headers });
                logger.clientRequest(RequestLoggerEnum.PutUserSettings_Success, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, res.status, null);
                return null;
            }
            catch (err: any) {
                logger.clientRequest(RequestLoggerEnum.PutUserSettings_Failure, {}, Date.now() - start, HttpMethod.Put, targetUri, "", "", "", 0, err.response.status, null);
                return err;
            }
        }

        
    }

    const removeUserSettings = () => {
        const targetUri = getARMEndpoint() + '/providers/Microsoft.Portal' + getAzureConsoleProviderLocation() + '/userSettings/cloudconsole?api-version=' + consoleApiVersion;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            'Accept-Language': language,
            'x-ms-correlation-request-id': correlationId
        };
        return axios.delete(targetUri, { headers })
             .then((res) => {
                return res;
             })
             .catch((err) => {
                throw err;
             });
    }

    const getProviderRegistrationState = (targetUri: string, retryCount = 0): Promise<any> => {
        const start = Date.now();

        return axios.get(targetUri, { headers })
            .then(async res => {
                if (res.data.registrationState === "Registered" || retryCount === RetryLimit.GetProviderRegistrationStateWhileRegistering) {
                    return null;
                } else {
                    await waitForDelay(4500);
                    return getProviderRegistrationState(targetUri, retryCount + 1);
                }
            }).catch(async error => {
                if (retryCount >= RetryLimit.GetProviderRegistrationState) {
                    logger.clientRequest(RequestLoggerEnum.ResourceProvider_Get, {}, Date.now() - start, HttpMethod.Get, targetUri, "", "", "", 0, error.response.status, null);
                    return error;
                } else {
                    await waitForDelay();
                    return getProviderRegistrationState(targetUri, retryCount + 1);
                }
            });
    };

    const registerStorageResourceProvider = (subscriptionId: string): Promise<any> => {
        const armEndpoint = getARMEndpoint();
        const targetUri = armEndpoint + '/subscriptions/' + subscriptionId + '/providers/' + ResourceProviderNamespace.Storage + '/register?api-version=' + armApiVersion;
        const start = Date.now();
        
        return axios.post(targetUri, null, { headers })
        .then(async () => {
            const getProviderRegistrationStateTargetUri = armEndpoint + '/subscriptions/' + subscriptionId + '/providers/' + ResourceProviderNamespace.Storage + '?api-version=' + armApiVersion;
            await waitForDelay();
            return getProviderRegistrationState(getProviderRegistrationStateTargetUri);
        }).catch(error => {
            logger.clientRequest(RequestLoggerEnum.ResourceProvider_Register, {}, Date.now() - start, HttpMethod.Post, targetUri, "", "", "", 0, error.response.status, null);
            return error;
        });
    }

    // Make sure to return the new functions from your hook
    return { getResourceGroup, getStorageAccount, putResourceGroup, putStorageAccount, updateStorageAccountTags, getSubscriptionsHelper, verifyCloudShellProviderRegistration, registerCloudShellProvider, updateUserSettings, removeUserSettings, registerStorageResourceProvider };
}