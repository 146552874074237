/*
###################
# Download Dialog #
###################

Parent: `Session` 
Purpose: Dialog screen that allows users to pass a file path to download a file from azure to their local machine
Takes:
 - `downloadPrefix`  
    - Path from root to user terminal.
 - `downloadErrorMsg`
    - If there is an issue downloading this can be set to a non `""` string and it will display as an error to the user.
 - `hideDownloadDialog`
    - React hook for displaying the dialog
 - `toggleDownloadDialog`
    - React function to toggle the `hideDownloadDialog` boolean
 - `Download` 
    - Callback function that takes the users path and attempts to 
Returns:
 - Makes a call to `toggleResetUserDialog()` which hides and shows the dialog
 - Makes a call to `Download()` with a valid port number then previews the connected port
*/

// External Components
import * as React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { Icon, List, useTheme } from "@fluentui/react";
import axios from "axios";
import { DownloadError } from "../../../common/consts";
import { useAccessTokenContext } from "../../DataProvider/EventProvider";
import { DownloadFileLinkInfo } from "../../../common/types";
import { uploadDownloadContentPropsStyles, dialogPositionRightBottom, dialogFooterStyles } from "../../../common/styles";

// Interface
interface DownloadDialogProps {
    consoleUri: string;
    termId: string | null;
    userRootDirectory: string;
    downloadFileLinkInfoList: Array<DownloadFileLinkInfo>;
    setDownloadFileLinkInfoList: (downloadFileLinkInfoList: Array<DownloadFileLinkInfo>) => void;
    hideDownloadDialog: boolean;
    hideDownloadClickLinkDialog: boolean;
    toggleDownloadDialog: () => void;
    toggleDownloadClickLinkDialog: () => void;
    setHideUploadDialogTrue: () => void;
}

// Component
const DownloadDialog = (downloadDialogProps: DownloadDialogProps) => {
    const { t } = useTranslation();
    const [filePathValue, setfilePathValue] = React.useState("");
    const [downloadErrorMsg, setDownloadErrorMsg] = React.useState("");
    const { accessToken } = useAccessTokenContext();
    const theme  = useTheme();
    
    const onChangeFilePathValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setfilePathValue(newValue || "");
        },
        []
    );

    const downloadDialogLabelId: string = useId("downloadDialogLabel");
    const downloadClickLinkDialogLabelId: string = useId("downloadClickLinkDialogLabel");
    const subTextId: string = useId("downloadSubTextLabel");

    //modal Props
    const downloadModalProps = React.useMemo(
        () => ({
            titleAriaId: downloadDialogLabelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: { main: { maxWidth: 750 } },
        }),
        [downloadDialogLabelId, subTextId]
    );

    const handleDownloadClickLinkDialogDismissed = () => {
        downloadDialogProps.setDownloadFileLinkInfoList([]);
    }

    const downloadClickLinkModalProps = React.useMemo(
        () => ({
            titleAriaId: downloadClickLinkDialogLabelId,
            isBlocking: false,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: { 
                main: { 
                    maxWidth: '40% !important',
                    minWidth: 400,
                    maxHeight: 'calc(100% - 55px) !important',
                    minHeight: 100,
                    padding: 0,
                    margin: 0
                }
            },
            onDismissed: handleDownloadClickLinkDialogDismissed
        }),
        [downloadClickLinkDialogLabelId]
    );

    const pathNotGiven = () => {
        return filePathValue === "";
    };

    const closeDownloadDialog = () => {
        downloadDialogProps.toggleDownloadDialog(); // hide the download screen
        setDownloadErrorMsg(""); // reset error message
    }

    const Download = (filePath: string) => {
        //If there's an upload completion dialog at the right bottom, close it
        downloadDialogProps.setHideUploadDialogTrue();
        
        const url = downloadDialogProps.consoleUri + "/terminals/" + downloadDialogProps.termId + "/download";
        const filename = downloadDialogProps.userRootDirectory + "/" + filePath;
        
        const data = { filename: filename };
        const headers =  {
            'Authorization': accessToken,
            'Content-Type': 'application/json; charset=utf-8'
        };

        const options = {
            method: "POST",
            headers,
            data: JSON.stringify(data),
            url
        }

        axios(options)
        .then(() => {
            closeDownloadDialog();
        }).catch((error) => {
            const errorCode = error.response.data.error?.code;
            if (errorCode && (errorCode === DownloadError.ResolvePathFailed || errorCode === DownloadError.NotInAllowedDirectory)) {
               setDownloadErrorMsg(t("downloadFileError"));
            }
            else {
               setDownloadErrorMsg(t("downloadUnknownError"));
            }
        })
    };

    const handleDownloadLinkClick = (index?: number, downloadClickUrl?: string) => {
        if (index !== undefined) {
            window.open(downloadClickUrl, '_blank');
            const newDownloadFileLinkInfoList = [...downloadDialogProps.downloadFileLinkInfoList];
            newDownloadFileLinkInfoList[index].clicked = true;
            downloadDialogProps.setDownloadFileLinkInfoList(newDownloadFileLinkInfoList);
            //If there's only a link, clear list and close the dialog after clicking the link
            if (downloadDialogProps.downloadFileLinkInfoList.length === 1) {
                downloadDialogProps.toggleDownloadClickLinkDialog();
            }
        }
    };

    const onRenderCell = (downloadFileLinkInfo?: DownloadFileLinkInfo, index?: number) => {
        return (
            <a 
                href='#' 
                style={{ 
                    display: 'inline-flex', 
                    alignItems: 'center', 
                    color: downloadFileLinkInfo?.clicked ? theme.palette.purple: theme.semanticColors.link, 
                    textDecoration: 'none' 
                }}
                onClick={()=>handleDownloadLinkClick(index, downloadFileLinkInfo?.downloadClickUrl)}
            >
                <Icon iconName="Download" style={{ marginRight: '2px' }}/>
                {downloadFileLinkInfo?.fileName}
            </a> 
        );
    };

    const downloadClickLinkContentPropsStyles = {
        title: {
            paddingTop: '20px',
            paddingBottom: '15px', 
            fontSize: '14px',
            paddingLeft: '15px',
        },
        inner: {
            paddingLeft: '15px'
        }
    };

    React.useEffect(() => {
        //Reset file path to empty on hidden
        if (downloadDialogProps.hideDownloadDialog) {
            setfilePathValue("");
        }
    }, [downloadDialogProps.hideDownloadDialog]);

    return (
        <>
            <Dialog
                hidden={downloadDialogProps.hideDownloadDialog} //variable to replace
                onDismiss={closeDownloadDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("downloadPromptHeader"),
                    closeButtonAriaLabel: t("cancelButton"),
                    subText: t("fileDownloadPrompt"),
                    showCloseButton: false, // hide or show the cancel icon
                }}
                modalProps={downloadModalProps}
            >
                <TextField
                    title={t("downloadInputARIALabel")}
                    aria-label={t("downloadInputARIALabel")}
                    required
                    placeholder={t("requiredFieldPlaceholder")}
                    role="textbox"
                    arial-role="textbox"
                    prefix={downloadDialogProps.userRootDirectory}
                    value={filePathValue} // value displayed
                    onChange={onChangeFilePathValue}
                    errorMessage={downloadErrorMsg}
                    onKeyDown={(ev) => {
                        // if return key behavior requested
                        if (ev.key === "Enter") {
                            if (!pathNotGiven()) {
                                // if there is a path
                                Download(filePathValue); // same behavior as primary click
                            }
                            ev.preventDefault();
                        }
                    } } />
                <DialogFooter className={dialogFooterStyles}>
                    <PrimaryButton
                        onClick={() => {
                            Download(filePathValue);
                        } }
                        disabled={pathNotGiven()} // Don't show if there is not a path
                        text={t("download")} />
                    <DefaultButton
                        onClick={closeDownloadDialog}
                        text={t("cancelButton")} />
                </DialogFooter>
            </Dialog>
            
            <Dialog //Download Click Link Dialog
                hidden={downloadDialogProps.hideDownloadClickLinkDialog}
                onDismiss={downloadDialogProps.toggleDownloadClickLinkDialog}
                styles={dialogPositionRightBottom}
                dialogContentProps={{
                    title: t("downloadClickLinkUrlDialogHeader"),
                    styles: uploadDownloadContentPropsStyles && downloadClickLinkContentPropsStyles,
                    type: DialogType.normal,
                    closeButtonAriaLabel: t("cancelButton"),
                    showCloseButton: true,
                }}
                modalProps={downloadClickLinkModalProps}
            >
                <div data-is-scrollable>
                    <List items={downloadDialogProps.downloadFileLinkInfoList} onRenderCell={onRenderCell} />
                </div>
            </Dialog>
        </>
    );
};

export default DownloadDialog;
