/*
#######################
# Port Preview Dialog #
#######################

Parent: `Session` 
Purpose: Dialog screen    
Takes:
 - `hidePortDialog`
 - `togglePortDialog`
 - `OpenPort`
Returns:
 - Makes a call to `toggleResetUserDialog()` which hides and shows the dialog
 - Makes a call to `OpenPort()` with whether open a new tab and a valid port number
*/

// External Components
import * as React from "react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface PortDialogProps {
    // These are the call back functions and data from parent
    hidePortDialog: boolean;
    togglePortDialog: () => void;
    OpenPort: (launch: boolean, portNum: number) => void;
}

// Component
const PortDialog = (portDialogProps: PortDialogProps) => {
    const { t } = useTranslation();
    const [portValue, setPortValue] = React.useState(""); //stores port value for label
    const onChangePortValue = React.useCallback(
        // for user typing
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setPortValue(newValue || "");
        },
        []
    );
    const labelId: string = useId("portDialogLabel");
    const subTextId: string = useId("portDialogSubTextLabel");
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
        }),
        [labelId, subTextId]
    );

    const nonValidPort = () => {
        // checks if it should throw an error
        const parsedPort = parseInt(portValue);
        if (isNaN(parsedPort)) {
            // if not a number
            return true;
        }
        if (
            (parsedPort >= 1025 && parsedPort <= 8079) ||
            (parsedPort >= 8091 && parsedPort <= 49151)
        ) {
            return false;
        }
        return true; // if a number but not in range
    };

    const portErrorMessage = () => {
        if (isNaN(parseInt(portValue)) && portValue !== "") {
            //if non integer
            return t("portMustBeNumberError"); // no issue
        }
        if (!nonValidPort() || portValue === "") {
            return ""; // no issue
        }
        return t("notValidPortNumber");
    };

    React.useEffect(() => {
        if (portDialogProps.hidePortDialog) {
            setPortValue("");
        }
    }, [portDialogProps.hidePortDialog]);

    return (
        <Dialog
            minWidth={500} // min width set here so buttons don't spill over by default
            hidden={portDialogProps.hidePortDialog} //variable to replace
            onDismiss={portDialogProps.togglePortDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: t("proxyConfigureHeader"),
                closeButtonAriaLabel: t("cancelButton"),
                subText: t("proxyConfigurePrompt"),
            }}
            modalProps={modalProps}
        >
            <TextField
                title={t("proxyConfigureInputARIALabel")}
                aria-label={t("proxyConfigureInputARIALabel")}
                value={portValue}
                onChange={onChangePortValue}
                errorMessage={portErrorMessage()}
                required
                placeholder={t("requiredFieldPlaceholder")}
                role="textbox"
                arial-role="textbox"
            />
            <DialogFooter className={dialogFooterStyles}>
                <PrimaryButton // Button for open port
                    onClick={() => {
                        portDialogProps.togglePortDialog();
                        portDialogProps.OpenPort(false, parseInt(portValue));
                    }}
                    disabled={nonValidPort()}
                    text={t("openPortButton")}
                />
                <PrimaryButton // Button for open and browse
                    onClick={() => {
                        portDialogProps.togglePortDialog();
                        portDialogProps.OpenPort(true, parseInt(portValue));
                    }}
                    disabled={nonValidPort()}
                    text={t("openAndBrowseButton")}
                />
                <DefaultButton
                    onClick={portDialogProps.togglePortDialog}
                    text={t("cancelButton")}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default PortDialog;
