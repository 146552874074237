import { Dialog, DialogType } from "@fluentui/react";
import { useId } from "@uifabric/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";

interface OpenFileDialogProps {
    toggleOpenFileDialog: () => void;
    hideOpenFileDialog: boolean;
    onClose: () => void;
}

const OpenFileDialog = (props: OpenFileDialogProps) => {
    const { t } = useTranslation();
    const openFileLabelId: string = useId("OpenFileLabel");
    const openFileModelProps = React.useMemo(
        () => ({
            titleAriaId: openFileLabelId,
            isBlocking: true,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: {
                main: {
                    maxWidth: '40% !important',
                    minWidth: 500,
                    maxHeight: 300,
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                }
            }
        }),
        [openFileLabelId]
    );
    const openDialogContentPropsStyles = {
        content: {
            minWidth: '300px',
            paddingBottom: 0
          },
        title: { 
            paddingBottom: '10px',
            display: 'flex',
            alignItems: 'center',
        }
    };
    return (
        <Dialog
            hidden={props.hideOpenFileDialog}
            onDismiss={props.toggleOpenFileDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: t("openFileText"),
                closeButtonAriaLabel: t("closeButton"),
                styles: openDialogContentPropsStyles
            }}
            modalProps={openFileModelProps}>
                <div data-testid="openFileDialog"></div>
                <p>{t("openFileContent")} <code style={{fontFamily: "Courier New", color: "blue"}}>{`code {FILENAME}`}</code></p>
            </Dialog>
    );
};

export default OpenFileDialog;