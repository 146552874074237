/* TYPES.tsx
This file is for sharing types that across multiple files.
*/

import {
    AzureThemeDark,
    AzureThemeHighContrastDark,
    AzureThemeHighContrastLight,
    AzureThemeLight,
} from "@fluentui/azure-themes";
import { Theme } from "@fluentui/react";
import { JwtPayload } from "jwt-decode";
import { FileStatus, NetworkType, SessionType, ShellType, UserSettingsActionType } from "./consts";

export interface Location {
    regionalDisplayName: string;
    displayName: string;
    name: string;
}

export interface Subscription {
    // Based on ARM call
    id?: string;
    subscriptionId: string;
    displayName: string;
    state?: string;
    subscriptionPolicies?: {
        locationPlacementId: string;
        quotaId: string;
        spendingLimit: string;
    };
}

export interface ResourceGroup {
    id: string;
    name: string;
    location: string;
}

export interface UserSetting {
    "preferredOsType": string;
    "preferredLocation": string;
    "storageProfile": StorageProfile | null,
    "terminalSettings": {
        "fontSize": string;
        "fontStyle": string;
    },
    "userSubscription": string | undefined;
    "vnetSettings": VnetSettings | null;
    "networkType": NetworkType;
    "sessionType" : SessionType;
    "preferredShellType": ShellType
}

export interface UserSettingsState {
    properties: UserSetting
    initialized: boolean
}

export interface SshCertificate {
    errorMessage: string;
    expiresAt: number;
    header: string;
    tokenType: string
}

export interface Token {
    tokenValue: string;
    updateTime: Date | null;
}

export interface VnetSettings {
    networkProfileResourceId: string;
    relayNamespaceResourceId: string;
    isolatedStorageProfile: StorageProfile | null;
    location: string;
}

export interface StorageProfile {
    "storageAccountResourceId": string;
    "fileShareName": string;
    "diskSizeInGB": number;
}

export interface GetUserSettings {
    type: UserSettingsActionType.GetUserSettings;
    payload: UserSetting;
}

export interface PutUserSettings {
    type: UserSettingsActionType.PutUserSettings;
    payload: UserSettingsState;
}

export interface DeleteUserSettings {
    type: UserSettingsActionType.DeleteUserSettings;
}

export interface UpdateFontSize {
    type: UserSettingsActionType.UpdateFontSize;
    payload: string;
}

export interface UpdateFontStyle {
    type: UserSettingsActionType.UpdateFontStyle;
    payload: string;
}

export interface UpdateShellType {
    type: UserSettingsActionType.UpdateShellType;
    payload: ShellType;
}

export interface UpdateNetworkType {
    type: UserSettingsActionType.UpdateNetworkType;
    payload: NetworkType;
}

export interface UpdateSessionType {
    type: UserSettingsActionType.UpdateSessionType;
    payload: SessionType;
}

export type UserSettingsActions = GetUserSettings 
                                | PutUserSettings 
                                | DeleteUserSettings 
                                | UpdateFontSize 
                                | UpdateFontStyle 
                                | UpdateShellType
                                | UpdateNetworkType
                                | UpdateSessionType

export interface OnboardingInfo {
    location: string;
    resourceGroupID: string;
    fileShareName: string;
    storageAccountName: string;
    diskSize: number;
    vNetInfo?: VNetInfo;
}

export interface VNetInfo {
    networkProfileResourceId: string;
    relayNamespaceResourceId: string;
    location: string;
}

export interface UserLocation {
    name: string;
    code: string;
}

export interface ParentMessage {
    signature: string;
    type: string;
    audience: string;
    resourceId?: string | null;
    fileShare?: string | null;
    getTokenWithDetails?: boolean,
    sshTokenData?: object
}

export interface TerminalState {
    connectionTime: Date | null;
    connectionState: number | null;
}

export interface FilesInfo {
    fileName: string;
    fileStatus: FileStatus;
}

export interface DownloadFileLinkInfo {
    downloadClickUrl: string;
    fileName: string;
    clicked?: boolean;
}

export interface ErrorDetails {
    code: string;
    message: string;
}

export class CustomWebSocket extends WebSocket { 
    retryCount: number | undefined;
}

export type AccessTokenContextType = {
    accessToken: string,
    puid: string,
    tenantId: string,
}

export type ParentMessageContextType = {
    token: Token,
    setToken: React.Dispatch<React.SetStateAction<Token>>,
    cert: SshCertificate,
    setCert: React.Dispatch<React.SetStateAction<SshCertificate>>,
    commands: string,
    setCommands: React.Dispatch<React.SetStateAction<string>>,
    commandShellType: ShellType,
    setCommandShellType: React.Dispatch<React.SetStateAction<ShellType>>
}

export type UserSettingsContextType = {
    userSettingsState: UserSettingsState
    userSettingsDispatch: React.Dispatch<UserSettingsActions>
}

export type customJwtPayload = JwtPayload & { 
    tid: string,
    puid: string,
    altsecid: string,
};

export interface TerminalRef {
    focus: () => void;
    restartTerminal: (msg?: string) => void;
    resetUserTerminal: () => void;
    reloadTerminal: () => void;
}

export interface ErrorMessages {
    header: string;
    content: string;
}

export interface StorageAccount {
    id: string;
    name: string;
    location: string;
    sku: {
        name: string;
        tier: string;
    }; 
}

export interface FileShare {
    id: string;
    name: string;
}

export interface NetworkProfile {
    id: string;
    name: string;
    location: string;
    containerNetworkInterfaceConfigurations: string;
}

export interface VirtualNetwork {
    id: string;
    name: string;
    location: string;
}

export interface RelayNamespace {
    id: string;
    name: string;
}

export interface CommandInjectionBody {
    command: Command | Command[]
}

export interface Command {
    name: string;
    args?: CommandArg | CommandArg[];
}

export interface CommandArg {
    /**
         * Name of the argument
         */
    prop: string;
    /**
     * Value of the argument
     */
    value?: string;
}
/*
const ThemeTypes =  {
    const Dark  = AzureThemeDark ,
    Light = AzureThemeLight,
    HighContrastDark  = AzureThemeHighContrastDark,
    HighContrastLight  = AzureThemeHighContrastLight   
}; */