import { ARMUrl, CloudName, FontSizeMap, FontStyleMap, HostLocation, PortalUrl, SessionType, ShellType, consoleApiVersion, fileShareNameLength, locationListMap, locationListProd, storageLocationMap, storageLocationMappingProd, tokenAudiences } from "../../common/consts";
import { ParentMessage, StorageProfile, UserSetting } from "../../common/types";

export const queryParams = new Map<string, string>();

export function userBrowserFirefox() {
    return navigator.userAgent.indexOf("Firefox") > 0;
}

export function userBrowserEdge() {
  return navigator.userAgent.indexOf("Edg") > -1 || navigator.userAgent.indexOf("Edge") > -1;
}

export function addToLocalStorage(setting: string, value: string) {
    try {
      window.localStorage.setItem(setting, value);
    }
    catch (e) {
      console.log(e);
    }
}

// Wrap local storage reads and writes in try-catch in case browser security refuses access and throws error
export function getFromLocalStorage(setting: string) {
  try {
    return window.localStorage.getItem(setting);
  }
  catch (e) {
    return null;
  }
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function saveSessionConsoleShellType(shellType: ShellType) {
  try {
    window.sessionStorage.setItem('consoleShellType', shellType);
  }
  catch (e) { 
    return;
  }
}

export function getAndRemoveSessionConsoleShellType() {
  try {
    const shellType = window.sessionStorage.getItem('consoleShellType');
    window.sessionStorage.removeItem('consoleShellType');
    if (shellType != null) {
      return shellType as ShellType;
    } else {
      return null;
    }
  }
  catch (e) {
    return null;
  }
}

export function getARMEndpoint() {
  const cloudName = getCloudName();
  if (cloudName === CloudName.Fairfax) {
    return ARMUrl.Fairfax;
  }
  if (cloudName === CloudName.USNat) {
    return ARMUrl.USNat;
  }
  if (cloudName === CloudName.USSec) {
    return ARMUrl.USSec;
  }
  return ARMUrl.Public;
}


export function getPortalUrl() {
  const cloudName = getCloudName();
  if (cloudName === CloudName.Fairfax) {
    return PortalUrl.Fairfax;
  }
  if (cloudName === CloudName.USNat) {
    return PortalUrl.USNat;
  }
  if (cloudName === CloudName.USSec) {
    return PortalUrl.USSec;
  }
  return PortalUrl.Public;
}

export function getAzureConsoleProviderLocation() {
  let providerLocation = '';
  if (getQueryParameter('feature.azureconsole.providerlocation')) {
    providerLocation = '/locations/' + getQueryParameter('feature.azureconsole.providerlocation');
  }
  return providerLocation;
}

export function getQueryParameter(name: string) {
  return queryParams.get(name);
}

export function getQueryParametersPrefix(prefix: string) {
  const prefixMap = new Map<string, string>();
  queryParams.forEach(function(value: string, key: string) {
      if (key.startsWith(prefix)) {
          prefixMap.set(key, value);
      }
  });
  return prefixMap;
}

export function parseQueryParams() {
  queryParams.clear();
  const query = window.location.search.substring(1);
  const parameterList = query.split("&");
  for (let i = 0; i < parameterList.length; i++) {
    const pair = parameterList[i].split("=");
    queryParams.set(decodeURIComponent(pair[0]), decodeURIComponent(pair[1]));
  } 
}

export function getConsoleUri() {
  const resourceId = '/consoles/default';
  return getARMEndpoint() + '/providers/Microsoft.Portal' + getAzureConsoleProviderLocation() + resourceId + '?api-version=' + consoleApiVersion;
}

export function postMessageHelper(type: string, audience: string, resourceId?: string, fileShare?: string) {
  const trustedParentOrigin = getQueryParameter("trustedAuthority");
  let trustedAuthority = (trustedParentOrigin?.split("//")[1] || "").toLowerCase();
  trustedAuthority = (trustedParentOrigin?.indexOf("https") == 0 ? "https://" : "http://") + trustedAuthority;
  const message: ParentMessage = {
    signature: "portalConsole",
    type: type,
    audience: audience,
    resourceId: resourceId,
    fileShare: fileShare
  };
  window.parent.postMessage(message, trustedAuthority);
}

export function postCertMessageHelper(sshTokenData: object) {
  const trustedParentOrigin = getQueryParameter("trustedAuthority");
  let trustedAuthority = (trustedParentOrigin?.split("//")[1] || "").toLowerCase();
  trustedAuthority = (trustedParentOrigin?.indexOf("https") == 0 ? "https://" : "http://") + trustedAuthority;
  const message: ParentMessage = {
    signature: "portalConsole",
    type: "getToken",
    audience: "ssh1",
    getTokenWithDetails: true,
    sshTokenData: sshTokenData
  };
  window.parent.postMessage(message, trustedAuthority);
}

export function goToV1(subscription?: string, shellType?: ShellType) {
  let redirectUrl = window.location.href.includes('v2') ? window.location.href.replace('v2', "v1") : window.location.href.replace(window.location.origin, window.location.origin + "/v1");
  if (subscription) {
    redirectUrl += '&feature.azureconsole.usersubscription=' + subscription;
  }
  if (shellType) {
    redirectUrl += '&feature.azureconsole.ostype=' + (shellType == ShellType.Bash ? 'linux' : 'windows');
  }
  window.location.replace(redirectUrl);
}

export function validateAudience(audience: string) {
  if (tokenAudiences.has(audience)) {
    return tokenAudiences.get(audience);
  }
  else if (tokenAudiences.has(audience + '/')) {
    return tokenAudiences.get(audience + '/');
  }
  else if (audience.substr(-1) === '/') {
    if (tokenAudiences.has(audience.substr(0, audience.length - 1))) {
      return tokenAudiences.get(audience.substr(0, audience.length - 1));
    }
  }
  return null;
}

export function getStorageProfile(userSettings: UserSetting) {
  return userSettings.vnetSettings?.isolatedStorageProfile || userSettings.storageProfile;
}

export function getSubscriptionId(storageProfile: StorageProfile | null) {
  let subscriptionId;
  if (storageProfile != null) {
    const storageAccountResource = storageProfile.storageAccountResourceId.split("/");
    const storageAccountIds = storageProfile.storageAccountResourceId.split("/");
    subscriptionId = storageAccountIds[storageAccountResource.indexOf("subscriptions") + 1];
  }
  else {
    console.log("storageProfile Null, SubscriptionId will be Null too.")
    subscriptionId = null;
  }
  return subscriptionId;
}

export function getUserSettingsSubscriptionId(userSettings: UserSetting) {
  let subscriptionId;
  const storageProfile = getStorageProfile(userSettings);
  const storageSubscriptionId = getSubscriptionId(storageProfile);
  switch (userSettings.sessionType) {
    case SessionType.Ephemeral:
      subscriptionId = userSettings.userSubscription?.trim();
      break;
    case SessionType.Mounted:
      subscriptionId = (userSettings.userSubscription?.trim().length != null && userSettings.userSubscription?.trim().length != 0) ? userSettings.userSubscription?.trim() : storageSubscriptionId;
      break;
    default:
      subscriptionId = storageSubscriptionId;
  }
  return subscriptionId;
}

export const getUserNameFromClaims = (claims: any) => {
  let userName;
  if (claims.upn && claims.upn.match(/\S/)) {
    userName = claims.upn;
  }
  else {
    userName = claims.unique_name;
    if (userName && userName.indexOf('#') != -1 && userName.indexOf('#') != userName.length - 1) {
      userName = userName.substring(userName.indexOf('#') + 1);
    }
  }
  return userName;
}

export const buildStorageFileShareName = (claims: any, puid:string) => {
  const userName = getUserNameFromClaims(claims);
  const prefix = ('cs-' + userName + '-').toLowerCase().replace(/[^a-z|0-9|-]/g, '-').replace(/-+/g, '-');
  const fileShareName = prefix + puid;
  //note if fileShareName is too long, we truncate one extra char from prefix and then add back the dash to preserve the dash
  return fileShareName.length <= fileShareNameLength ? fileShareName : (prefix.substring(0, fileShareNameLength - 1 - puid.length) + '-' + puid).replace(/-+/g, '-');
}

export function getCloudName(): string {
  const hostLocation = window.location.host;
  if (hostLocation == HostLocation.Prod) {
    return CloudName.Prod;
  }
  if (hostLocation == HostLocation.MPac) {
    return CloudName.MPac;
  }
  if (hostLocation == HostLocation.RC) {
    return CloudName.RC;
  }
  if (hostLocation == HostLocation.Fairfax) {
    return CloudName.Fairfax;
  }
  if (hostLocation == HostLocation.USNat) {
    return CloudName.USNat;
  }
  if (hostLocation == HostLocation.USSec) {
    return CloudName.USSec;
  }
  return CloudName.Other;
}

export const isSecureCloud = () => {
  const cloudName = getCloudName();
  return cloudName == CloudName.USNat || cloudName == CloudName.USSec;
}

export const getFontStyle = (fontStyle: string) => {
  return FontStyleMap.get(fontStyle.toLowerCase());
}

export const getFontSize = (fontSize: string) => {
  return FontSizeMap.get(fontSize.toLowerCase())
}

export const waitForDelay = async (timeout?: number) => {
  return new Promise((resolve => setTimeout(resolve, timeout || 3000)));
}

export const getStorageLocationMapping = () => {
  const cloudName = getCloudName();
  return storageLocationMap.get(cloudName) || storageLocationMappingProd;
}

export const getLocationList = () => {
  const cloudName = getCloudName();
  return locationListMap.get(cloudName) || locationListProd;
}

export const isCloudNonProd = () => {
  const cloudName = getCloudName();
  return (cloudName === CloudName.RC || cloudName === CloudName.MPac || cloudName === CloudName.Other);
};