/*
###########
# App.tsx #
###########
-   **Inputs**: None
-   **Purpose**: `App.tsx` is the main application.

This is the main app for the node application. It does not directly create any visuals for the user.
Instead, it contains the global states and logic that allows communication between mounting and the user experience. 
*/

// Import external components
import React, { useState } from "react";
// Import handmade components
import "./App.css"; // Theme

import { AccessTokenProvider } from "./components/DataProvider/EventProvider";
import "./static/styles/fonts.css";
import {  UserSettingsProvider } from "./components/DataProvider/UserSettingsProvider";

export const App: React.FunctionComponent =  () => {
    return (
        <AccessTokenProvider>
            <UserSettingsProvider />
        </AccessTokenProvider>
    );
};
