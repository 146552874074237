/*
#############
# SWITCH SHELLTYPE #
#############

Parent: `Session`
Purpose: User confirmation screen for switching  
Takes:
 - `currentShellType`
 - `hideSwitchShellTypeDialog`
 - `toggleSwitchShellTypeDialog`
 - `ChangeShellType`
Returns:
 - Makes a call to `toggleSwitchShellTypeDialog()` which hides and shows the dialog
 - Makes a call to `ChangeShellType()` that will switch the shell type from Bash to PowerShell or vice versa

*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { ShellType } from "../../../common/consts";
import { useTranslation } from "react-i18next";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface SwitchShellTypeDialogProps {
    // These are the call back functions and data from parent
    currentShellType: ShellType; // "bash" or "powershell"
    hideSwitchShellTypeDialog: boolean; // Variable for hiding or showing dialog
    toggleSwitchShellTypeDialog: () => void; // Callback function to hide and show
    ChangeShellType: () => void; // Callback function to switch shell type
}

// Component
const SwitchShellTypeDialog = (switchShellTypeDialogProps: SwitchShellTypeDialogProps) => {
    const { t } = useTranslation();
    const labelId: string = useId("restartDialogLabel");
    const subTextId: string = useId("restartSubTextLabel");

    const modalProps = React.useMemo(
        // Overrides default dialog styles
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { 
                main: { 
                    maxWidth: '40% !important',
                }
            },
        }),
        [labelId, subTextId]
    );

    const CurrentTerminalName = () => {
        return switchShellTypeDialogProps.currentShellType === ShellType.Bash
            ? t("bashSelectionOption")
            : t("powershellSelectionOption");
    };

    const SwitchToTerminalName = () => {
        return switchShellTypeDialogProps.currentShellType === ShellType.PowerShell
            ? t("bashSelectionOption")
            : t("powershellSelectionOption");
    };

    return (
        <>
            <Dialog
                hidden={switchShellTypeDialogProps.hideSwitchShellTypeDialog} //variable to hide
                onDismiss={switchShellTypeDialogProps.toggleSwitchShellTypeDialog} // function that hides
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("switchOSPrompt", { SwitchToTerminalName: SwitchToTerminalName() }),
                    closeButtonAriaLabel: t("cancelButton"),
                    subText: t("switchOSPromptSubtext", {
                        SwitchToTerminalName: SwitchToTerminalName(),
                        CurrentTerminalName: CurrentTerminalName(),
                    }),
                }}
                modalProps={modalProps}
            >
                <DialogFooter className={dialogFooterStyles}>
                    <PrimaryButton
                        onClick={switchShellTypeDialogProps.ChangeShellType} // Callsback for restart, does not hide by itself
                        text={t("switchButtonTitle")}
                    />
                    <DefaultButton
                        onClick={switchShellTypeDialogProps.toggleSwitchShellTypeDialog}
                        text={t("cancelButton")}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default SwitchShellTypeDialog;
