import React from 'react';
import EditorTitlebarMenu from './EditorTitlebarMenu';
import EditorDirty from "./EditorDirty";
interface EditorTitlebarProps {
    closeEditor: () => void;
    saveFileClicked: (isSaveAs: boolean) => void;
    openFileClicked: () => void;
    fileName: string;
    isDirty: boolean;
}
const EditorTitlebar = (editorTitlebarProps: EditorTitlebarProps) => {
    return (
        <div data-testid="editor-titlebar"
            style={{
                height: "25px",
                backgroundColor: "#434343",
                border: "1px solid #434343",
                display: "flex",
                alignItems: "center",
                color: "#afafaf"
            }}>
            <div id="editor-titlebar-left"
                style={{
                    flex: 1,
                    display: 'flex'
                }}></div>
            <div id="editor-titlebar-center"
                style={{
                    flex: 0,
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <span id="editor-title" style={{height: "16px", display: "flex", alignItems: "center", justifyContent: "center"}}>{editorTitlebarProps.fileName}</span>
                    <EditorDirty dirty={editorTitlebarProps.isDirty} />
            </div>
            <div id="editor-titlebar-right"
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '10px'
                }}>
                <EditorTitlebarMenu 
                    closeEditor={editorTitlebarProps.closeEditor} 
                    saveFileClicked={editorTitlebarProps.saveFileClicked}
                    openFileClicked={editorTitlebarProps.openFileClicked} />
            </div>
        </div>
    );
}
export default EditorTitlebar