/* 
#########################
# Relay Namespace Dropdownn #
#########################
-   **Fetches based on**: Bearer token
-   **Inputs**:
    -   `selectedResourceGroupID`
        -   current selected resource group ID
    -   `setRelayNamespaceID`
        -   Call back function to set the selected relay namespace ID
    -   `setError`
        -   Call back function to show error message while fetching list of relay namespaces
-   **Purpose**: Let users select and filter a relay namespace from a dropdown list
*/

// Libraries
import * as React from "react";
import { useEffect, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import FilterableDropdown from "./FilterableDropdown";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { RelayNamespace } from "../../../common/types";
import { useTranslation } from "react-i18next";
import useFetch from "../../Util/useFetch";
import { RequestLoggerEnum } from "../../../common/consts";

interface RelayNamespaceDropdownProps {
    selectedResourceGroupID: string;
    setRelayNamespaceID: (relayNamespaceID: string) => void;
    setError: (error: any) => void;
}

const RelayNamespaceDropdown = React.memo((props: RelayNamespaceDropdownProps) => {
    const { t } = useTranslation();
    const { data: relayNamespaceList, loading: isRelayNamespaceListLoading, error: relayNamespaceListError } = useFetch<RelayNamespace>(props.selectedResourceGroupID, RequestLoggerEnum.RelayNamespace_List);
    const relayNamespaces = useMemo(() => relayNamespaceList || [], [relayNamespaceList]);

    const [selectedKey, setSelectedKey] = React.useState<string>("");

    useEffect(() => {
        if (relayNamespaceListError) {
            props.setError(relayNamespaceListError);
        }
    }, [relayNamespaceListError]); 

    useEffect(() => {
        props.setRelayNamespaceID("");
        setSelectedKey("");
    }, [props.selectedResourceGroupID]); 
    
    // Build the set of dropdown items
    const options = useMemo(() => {
        relayNamespaces.sort((a,b)=> ( a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0));
        
        const items: IDropdownOption[] = [];

        for (const relayNamespace of relayNamespaces) {
            items.push({
                key: relayNamespace.id,
                text: relayNamespace.name,
                data: relayNamespace
            });
        }

        return items;
    }, [relayNamespaces]);

    return (
        <>
            <Shimmer isDataLoaded={!isRelayNamespaceListLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={selectedKey}
                    onChange={(_event: React.FormEvent<HTMLDivElement>, relayNamespaceOption: any) => {
                        setSelectedKey(relayNamespaceOption.key as string);
                        props.setRelayNamespaceID(relayNamespaceOption.key);
                    }}
                    placeholder={t("provideExistingRelayNamespace")}
                    disabled={props.selectedResourceGroupID === ""}
                    ariaLabel={t("relayNamespaceTitle")}
                />
            </Shimmer>
        </>
    );
});

RelayNamespaceDropdown.displayName = "RelayNamespace-Dropdown"; // export name

export default RelayNamespaceDropdown;