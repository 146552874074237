import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

interface EditorTerminalSeparatorProps {
    currentEditorHeight: number;
    setEditorHeight: (height: number) => void;
    maxHeight: number;
}
const EditorTerminalSeparator = (editorTerminalSeparatorProps: EditorTerminalSeparatorProps) => {
    const { t } = useTranslation();
    const editorTerminalSeparatorId = "editor-terminal-separator";
    const [startY, setStartY] = useState<number>(0);
    const [canResizeY, setCanResizeY] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
        isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (canResizeY) {
            document.addEventListener('mousemove', dragResizeHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        } else {
            document.removeEventListener('mousemove', dragResizeHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        }
    }, [canResizeY]);

    const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setStartY(e.clientY);
        document.addEventListener('mousemove', dragResizeHandler);
        document.addEventListener('mouseup', mouseUpHandler);
        setCanResizeY(true);
    };

    const mouseUpHandler = () => {
        document.removeEventListener('mousemove', dragResizeHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        if(isMounted.current) {
            setCanResizeY(false);
        }
    };

    const dragResizeHandler = (e: MouseEvent) => {
        if (!canResizeY) {
            return;
        }

        const newY = e.clientY;
        const diffY = startY - newY;
        const newHeight = Math.min(editorTerminalSeparatorProps.currentEditorHeight - diffY, editorTerminalSeparatorProps.maxHeight);
        editorTerminalSeparatorProps.setEditorHeight(newHeight);
    };

    return (
        <div data-testid="editor-terminal-separator"
            id={editorTerminalSeparatorId}
            tabIndex={0}
            role="separator"
            style={{
                height: "5px",
                width: '100%',
                backgroundColor: "#c5c5c5",
                cursor: 'ns-resize'
            }}
            onMouseDown={mouseDownHandler}
            aria-label={t("editorTerminalSeparatorLabel")}
        ></div>
    );
};

export default EditorTerminalSeparator;