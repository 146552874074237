/*
#######################
# Terminal Timeout Dialog #
#######################

Parent: `Session` 
Purpose: If the terminal is idle for too long or timeout, a dialog will appear giving the user the option to reconnect or close.  
Takes:
 - `hideTermTimeoutDialog`
 - `toggleTermTimeoutDialog`
Returns:
 - Makes a call to `toggleTermTimeoutDialog()` which hides and shows the dialog
Todo:
 - Handle click function for the 'connect' button
*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton, BaseButton, Button } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { postMessageHelper, userBrowserFirefox } from "../Util/Utilities";
import { Link } from "@fluentui/react";
import { useAccessTokenContext } from "../DataProvider/EventProvider";
import { PostMessageHelperType } from "../../common/consts";
import { dialogFooterStyles } from "../../common/styles";

// Interface
interface TermTimeoutProps {
    // These are the call back functions and data from parent
    hideTermTimeoutDialog: boolean;
    toggleTermTimeoutDialog: () => void;
    setTerminalVisible: (terminalVisible: boolean) => void;
}

// Component
const TermTimeoutDialog = (termTimeoutProps: TermTimeoutProps) => {
    const { t } = useTranslation();
    const connectBtn = React.useRef<BaseButton>(null);
    const closeBtn = React.useRef<BaseButton>(null);
    const labelId: string = useId("termTimeoutLabel");
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            isBlocking: true, //a blocking Dialog disables all other actions and commands on the page behind it
        }),
        [labelId]
    );

    function handleKeyPress(e: React.KeyboardEvent<BaseButton | HTMLButtonElement | HTMLDivElement | HTMLAnchorElement | Button | HTMLSpanElement>) {
        if (e.which === 39) {
        closeBtn.current?.focus();
        }
        if (e.which === 37) {
        connectBtn.current?.focus();
        }
    }

    return (
        <Dialog
            minWidth={500} // min width set here so buttons don't spill over by default
            hidden={termTimeoutProps.hideTermTimeoutDialog} //variable to replace
            onDismiss={termTimeoutProps.toggleTermTimeoutDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: t("timeoutHeader"),
                showCloseButton: false,
            }}
            modalProps={modalProps}
        >
            <div style={{ paddingBottom: '16px' }}>
                {userBrowserFirefox() && <p>{t("timeoutNotificationFirefox")} <Link href="https://aka.ms/cloudshell/fftimeout" target="_blank" aria-label='Firefox timeout details'>{t("timeoutNotificationFirefoxLink")}</Link></p>}
                <p>{t("timeoutPrompt")}</p>
            </div>

            <DialogFooter className={dialogFooterStyles}>
                <PrimaryButton // Button for reconnect
                    componentRef={connectBtn}
                    onClick={() => {
                        termTimeoutProps.toggleTermTimeoutDialog();
                        termTimeoutProps.setTerminalVisible(true);
                        postMessageHelper(PostMessageHelperType.GetToken, "");
                    }}
                    onKeyDown={e => handleKeyPress(e)}
                    text={t("connectButton")}
                    autoFocus
                />
                <DefaultButton
                    componentRef={closeBtn}
                    onClick={() => postMessageHelper(PostMessageHelperType.Close, '')}
                    text={t("closeButton")}
                    onKeyDown={e => handleKeyPress(e)}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default TermTimeoutDialog;