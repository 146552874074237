import React from "react";
import { ContextualMenu, IContextualMenuItem, IContextualMenuItemStyles, ThemeProvider, createTheme } from "@fluentui/react";
import EditorTitlebarMenuIcon from "./EditorTitlebarMenuIcon";
import { useBoolean } from "@uifabric/react-hooks";
import { useTranslation } from "react-i18next";

interface EditorTitlebarMenuProps {
    closeEditor: () => void;
    saveFileClicked: (isSaveAs: boolean) => void;
    openFileClicked: () => void;
}

const EditorTitlebarMenu = (editorTitlebarMenuProps: EditorTitlebarMenuProps) => {
    const { t } = useTranslation();
    const [hideContextualMenu, { toggle: toggleContextualMenu }] = useBoolean(true);
    const backgroundColor = "#2d2f31";

    const getItemStyles = (): Partial<IContextualMenuItemStyles> => {
        return {
            root: {color: "#4d9de2", width: "200px", backgroundColor: backgroundColor},
            rootHovered: {backgroundColor: "#3a3a3a"},
            secondaryText: {color: "#afafaf"}
        }
    }

    const userTheme = createTheme({
        palette: {
            neutralLight: backgroundColor,
        },
    });

    const menuItems: IContextualMenuItem[] = [
        {
            key: "saveButton",
            text: t("saveButton"),
            secondaryText: "Ctrl+S",
            onClick: () => editorTitlebarMenuProps.saveFileClicked(false),
            itemProps: { styles: getItemStyles() },
            ariaLabel: t("saveButton")
        },
        {
            key: "saveAsButton",
            text: t("saveAsButton"),
            secondaryText: "Ctrl+Shift+S",
            onClick: () => editorTitlebarMenuProps.saveFileClicked(true),
            itemProps: { styles: getItemStyles() },
            ariaLabel: t("saveAsButton")
        },
        {
            key: "closeEditorButton",
            text: t("closeEditorButton"),
            secondaryText: "Ctrl+Q",
            onClick: () => editorTitlebarMenuProps.closeEditor(),
            itemProps: { styles: getItemStyles() },
            ariaLabel: t("closeEditorButton"),
        },
        {
            key: "openFileButton",
            text: t("openFileButton"),
            secondaryText: "Ctrl+P",
            onClick: () => editorTitlebarMenuProps.openFileClicked(),
            itemProps: { styles: getItemStyles() },
        },
    ];

    return (
        <div>
            <style>{`
                .ms-ContextualMenu-container .ms-FocusZone {
                    background-color: ${backgroundColor};
                }
        `}</style>
            <div 
                id="editorTitlebarMenuIcon" 
                role="button"
                aria-haspopup="true"
                aria-expanded={!hideContextualMenu}
                onClick={toggleContextualMenu} 
                style={{cursor: "pointer", height: "27px", padding: "0", marginLeft: "20px"}}>
                <EditorTitlebarMenuIcon />
            </div>
        <ThemeProvider theme={userTheme}>
            <ContextualMenu
                id="editorTitlebarMenu"
                items={menuItems}
                hidden={hideContextualMenu}
                onItemClick={toggleContextualMenu}
                onDismiss={toggleContextualMenu}
                target={`div[id="editorTitlebarMenuIcon"]`}
                calloutProps={{
                    styles: { 
                        root: { backgroundColor: backgroundColor },
                        calloutMain: { backgroundColor: backgroundColor },
                    },
                }}/>
        </ThemeProvider>
        </div>
    );
};
export default EditorTitlebarMenu;
