/*
###################
# Upload Dialog #
###################

Parent: `Session` 
Purpose: Dialog screen that allows users to upload single/multiple files
Takes:
 - `hideUploadDialog`  
    - React hook for displaying the dialog
 - `toggleUploadDialog`
    - React function to toggle the `hideUploadDialog` boolean
 - `hideUploadInProgressDialog`  
    - React hook for displaying the dialog
 - `keepUploadInProgressDialogOpen` 
    - Another variable to show/hide progress bar
    - The reason why we add one more variable to control the display of the progress bar is 
    - that we have 'quickUpload' variable under 'onUploadProgress' function in the Session.tsx
    - The 'quickUpload' variable is used to avoid flickering
    - If we don't add this variable
    - the progress bar will keep poping up since the 'hideUploadInProgressDialog' will be set to false again and again in the 'onUploadProgress' function
 - `setKeepUploadInProgressDialogOpen` 
    - toggle the `setKeepUploadInProgressDialogOpen` boolean
 - `percentComplete`
    - Progress bar value
 - `currentFileIndex`
    - To know which file is uploading and used for the UploadInProgress Dialog header
 - `fileCompleteStatusList`
    - A list which records the file name and file complete status. 
 - `uploadDestination`
    - The file upload path
 - `uploadHasErrors` 
    - If the upload has issues
 - `fileTooLargeErrorExists` 
    - If there is any file that is too large
 - `allFilesTooLarge` 
    - If all of the upload problems are caused by the size of the file
 - `uploadSucceedCount` 
    - How many files are successfully uploaded
 - `uploadInProgressTotalCount` 
    - How many files will be uploaded and used for the UploadInProgress Dialog header
 - `handleUploadCompleteOnDismissed` 
    - Called after the dialog is dismissed and the animation is complete
*/

// External Components
import * as React from "react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { DefaultButton, IButtonStyles, Icon, List, mergeStyles, mergeStyleSets, ProgressIndicator, registerIcons } from "@fluentui/react";
import { useState } from "react";
import { FilesInfo } from "../../../common/types";
import { FileStatus } from "../../../common/consts";
import { uploadDownloadContentPropsStyles, dialogPositionRightBottom } from "../../../common/styles";

// Interface
interface UploadDialogProps {
    hideUploadDialog: boolean;
    toggleUploadDialog: () => void;
    hideUploadInProgressDialog: boolean;
    percentComplete: number;
    currentFileIndex: number;
    fileCompleteStatusList: Array<FilesInfo>;
    uploadDestination: string;
    uploadHasErrors: boolean;
    fileTooLargeErrorExists: boolean;
    allFilesTooLarge: boolean;
    uploadSucceedCount: number;
    uploadInProgressTotalCount: number;
    keepUploadInProgressDialogOpen: boolean
    setKeepUploadInProgressDialogOpen: (setKeepUploadInProgressDialogOpen: boolean) => void;
    handleUploadCompleteDismissed: () => void;
}

registerIcons({
    icons: {
        "Success": (
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                x="0px" 
                y="0px" 
                width="14" 
                height="14" 
                viewBox="0 0 16 16"
            >
                <circle fill="#57A300" cx="8" cy="8" r="8" />
                <path fill="#FFFFFF" d="M3.553,8.291C3.479,8.212,3.441,8.108,3.445,8c0.004-0.109,0.05-0.209,0.13-0.284L4.41,6.944c0.076-0.069,0.173-0.107,0.274-0.107c0.112,0,0.22,0.047,0.296,0.129l2.136,2.292l3.807-4.875C11,4.284,11.116,4.226,11.242,4.226c0.09,0,0.175,0.029,0.247,0.084l0.906,0.699c0.173,0.128,0.21,0.377,0.08,0.554l-4.868,6.233c-0.189,0.242-0.55,0.258-0.76,0.033L3.553,8.291z" />
            </svg>
        ),
        "Failed": (
            <svg id="af9ee292-6669-414e-bb01-e448ae574cd6" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8" fill="#a4262c" />
                <path d="M12.694,4.728,11.272,3.306,8,6.578,4.728,3.306,3.306,4.728,6.578,8,3.306,11.272l1.422,1.422L8,9.422l3.272,3.272,1.422-1.422L9.422,8Z" fill="#fff" />
            </svg>
        ),
        "Dismiss": (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                <path d="M10 1.32359L8.91176 0.235352L5 4.14712L1.08824 0.235352L0 1.32359L3.91176 5.23535L0 9.14712L1.08824 10.2354L5 6.32359L8.91176 10.2354L10 9.14712L6.08824 5.23535L10 1.32359Z" fill="#A4262C"/>
            </svg>            
        ),
        "Check": (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                <path d="M10.8 0L3.6 7.5L1.2 5L0 6.25L3.6 10L12 1.25L10.8 0Z" fill="#57A300"/>
            </svg>
        )
    },
});

// Component
const UploadDialog = (uploadDialogProps: UploadDialogProps) => {
    const { t } = useTranslation();
    const uploadInProgressLabelId: string = useId("uploadInProgressDialogLabel");
    const uploadCompleteLabelId: string = useId("uploadCompleteDialogLabel");
    const filesCount = uploadDialogProps.fileCompleteStatusList.length;
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const uploadFailedCount = filesCount - uploadDialogProps.uploadSucceedCount;

    //Styles:
    const classNames = mergeStyleSets({
        //style the content of list items for displaying the results of multi-file uploads
        itemContent: {
            maxWidth: '95% !important',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
        },
    });

    //Show more/less button style
    const buttonStyles: IButtonStyles = {
        root: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#0078d4',
            cursor: 'pointer',
            padding: '0px',
            margin: '-8px'
        },
        flexContainer: {
          flexDirection: 'row-reverse',
        },
        rootHovered: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#0078d4',
            cursor: 'pointer',
        },
        rootPressed: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#0078d4',
            cursor: 'pointer',
        },
        rootFocused: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#0078d4',
            cursor: 'pointer',        
        }
    };

    const uploadInProgressDialogContentPropsStyles = {
        content: {
          minWidth: '380px',
          paddingBottom: 0
        },
        title: { 
            paddingBottom: '10px',
            fontSize: '14px'
        }
    };

    const fileItemContentContainerStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
    };

    const iconStyle: React.CSSProperties = {
        marginRight: '8px',
        marginTop: '2px'
    };

    const handleUploadInProgressDialogOnDismiss = () => {
        uploadDialogProps.setKeepUploadInProgressDialogOpen(false);
    }

    //called after the dialog is dismissed and the animation is complete
    const handleUploadCompleteOnDismissed = () => {
        setShowDetails(false);
        uploadDialogProps.handleUploadCompleteDismissed();
    }

    //modal Props
    const uploadInProgressModalProps = React.useMemo(
        () => ({
            titleAriaId: uploadInProgressLabelId,
            isBlocking: false,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: { 
                main: { 
                    maxWidth: '40% !important',
                    minWidth: 400,
                    maxHeight: 300,
                    minHeight: 80,
                    padding: 0,
                    margin: 0,
                }
            }
        }),
        [uploadInProgressLabelId]
    );

    const uploadCompleteModalProps = React.useMemo(
        () => ({
            titleAriaId: uploadCompleteLabelId,
            isBlocking: false,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: { 
                main: { 
                    maxWidth: '40% !important',
                    minWidth: 400,
                    maxHeight: 'calc(100% - 55px) !important',
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                    selectors: {
                        '@media screen and (max-width: 400px)': {
                            minWidth: '90%',
                            position: 'relative' as any,
                            left: '43%',
                            transform: 'translateX(-50%)'
                        }
                    }
                }
            },
            onDismissed: handleUploadCompleteOnDismissed
        }),
        [uploadCompleteLabelId]
    );

    //display each individual file item from the results of a multi-file upload
    const onRenderCell = (item?: FilesInfo) => {
        return (
            <div style={fileItemContentContainerStyle}>
                {
                    item?.fileStatus === FileStatus.Success
                    ?
                    <Icon iconName="Check" style={iconStyle} />
                    :
                    <Icon iconName="Dismiss" style={iconStyle}/>
                }
                <span className={classNames.itemContent}>{item?.fileName} {" "} {item?.fileStatus === FileStatus.TooLarge && <span style={{color: "#A4262C"}}>({t("fileSizeTooLarge")})</span>}</span>
            </div>    
        );
    };

    const fileInProgressHeader = () => {
        if (uploadDialogProps.uploadInProgressTotalCount === 1) {
            return t("uploadingSingleFileInProgressHeader");
        } else {
            return t("uploadingMutiFilesInProgressHeader", {CurrentFile: uploadDialogProps.currentFileIndex + 1, FilesCount: uploadDialogProps.uploadInProgressTotalCount});
        }
    }

    const fileCompleteHeader = () => {
        if (filesCount != 1) {
            if (uploadDialogProps.uploadHasErrors) {
                return <div style={fileItemContentContainerStyle}><Icon iconName="Failed" style={iconStyle}/><span>{t("fileUploadErrorHeader")}</span></div>;
            } else {
                return <div style={fileItemContentContainerStyle}><Icon iconName="Success" style={iconStyle}/><span>{t("multipleFileUploadSuccessHeader", {FilesCount:filesCount})}</span></div>;
            }
        } else {
            if (uploadDialogProps.uploadHasErrors) {
                return <div style={fileItemContentContainerStyle}><Icon iconName="Failed" style={iconStyle}/><span>{t("fileUploadErrorHeader")}</span></div>;
            } else {
                return <div style={fileItemContentContainerStyle}><Icon iconName="Success" style={iconStyle}/><span>{t("singleFileUploadSuccessHeader")}</span></div>;
            }
        }
    }

    const fileCompleteContent = () => {
        if (filesCount === 1) {
            return (
                <>
                    {
                        uploadDialogProps.fileCompleteStatusList[0]?.fileStatus === FileStatus.Success 
                        &&
                        <p>{t("uploadDestination")}{" "}{uploadDialogProps.uploadDestination}</p>               
                    } 
                        <p>{t("file") + " " + uploadDialogProps.fileCompleteStatusList[0]?.fileName}</p>
                    { 
                        uploadDialogProps.fileCompleteStatusList[0]?.fileStatus === FileStatus.TooLarge 
                        && 
                        <p>{t("fileSizeTooLarge")}.</p>
                    }                            
                </>
            )
        } else {
            return (
                showDetails
                ?
                (
                    
                    <>
                        {!uploadDialogProps.uploadHasErrors && <p><strong style={{color: "#605E5C"}}>{t("uploadDestination")}</strong>{" "}{uploadDialogProps.uploadDestination}</p>}
                        {!uploadDialogProps.uploadHasErrors && <br />}
                        <strong style={{color: "#605E5C"}}>{t("files")}</strong>
                        <div data-is-scrollable>
                            <List items={uploadDialogProps.fileCompleteStatusList} onRenderCell={onRenderCell} />
                        </div>
                    </> 
                )
                :
                (
                    <div style={{ paddingBottom: '0px' }}>
                        {
                            uploadDialogProps.uploadHasErrors 
                            && 
                            <p>
                                {t("fileFailedNumber", {UploadFailedCount: uploadFailedCount, FilesCount: filesCount})}
                                {' '}
                                {uploadDialogProps.allFilesTooLarge && t("fileSizeTooLargeMsg")}
                                {!uploadDialogProps.allFilesTooLarge && uploadDialogProps.fileTooLargeErrorExists && t("fileUploadFailedMixedErrorMsg")}
                            </p>
                        }
                    </div>
                )
            )
        }       
    }
    const toggleDetailButtonStyle = mergeStyles({
        display: 'flex',
        justifyContent: 'start'
    });

    const fileCompleteFooter = () => {
        if (filesCount != 1) {
            return (
                <DialogFooter className={toggleDetailButtonStyle}>
                    <DefaultButton
                        onClick={() => {
                            showDetails ? setShowDetails(false) : setShowDetails(true);
                        }}
                        styles={buttonStyles}
                        text={showDetails ? t("showLess") : t("showMore")}
                        iconProps={showDetails ? {iconName: 'ChevronUp'} : {iconName: 'ChevronDown'}}
                    />
                </DialogFooter>
            )
        }
    }

    return (
        <>
            {           
                uploadDialogProps.keepUploadInProgressDialogOpen && 
                <Dialog
                    hidden={uploadDialogProps.hideUploadInProgressDialog}
                    onDismiss={handleUploadInProgressDialogOnDismiss}
                    styles={dialogPositionRightBottom}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: fileInProgressHeader(),
                        closeButtonAriaLabel: t("cancelButton"),
                        showCloseButton: true, // hide or show the cancel icon,
                        styles: uploadInProgressDialogContentPropsStyles
                    }}
                    modalProps={uploadInProgressModalProps}

                >
                    <ProgressIndicator 
                        description={`${uploadDialogProps.percentComplete}%`} 
                        percentComplete={uploadDialogProps.percentComplete} 
                        styles={
                            {itemProgress: {
                                width: uploadDialogProps.percentComplete + "%"
                            }}
                        }
                    />
                </Dialog>
            }
            <Dialog
                hidden={uploadDialogProps.hideUploadDialog || filesCount < 1} 
                onDismiss={uploadDialogProps.toggleUploadDialog}
                styles={dialogPositionRightBottom}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: fileCompleteHeader(),
                    closeButtonAriaLabel: t("cancelButton"),
                    showCloseButton: true, // hide or show the cancel icon
                    styles: uploadDownloadContentPropsStyles,  
                }}
                modalProps={uploadCompleteModalProps}
            >
                {fileCompleteContent()}
                {fileCompleteFooter()}
            </Dialog>
        </>
    );
};

export default UploadDialog;