/* 
#########################
# Virtual Network Dropdown #
#########################
-   **Uses user specific data**: `true`
    -   Virtual Network list should be unique to every user.
-   **Fetches based on**: Bearer token
-   **Inputs**:
    -   `selectedResourceGroupID`
        -   current selected resource group ID
    -   `setSelectedVirtualNetworkID`
        -   Call back function to set the selected virtual network
    -   `setError`
        -   Call back function to show error message while fetching list of virtual networks
-   **Purpose**: Let users select and filter a virtual network from a dropdown list
*/

// Libraries
import * as React from "react";
import { useEffect, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import FilterableDropdown from "./FilterableDropdown";
import { Shimmer } from "@fluentui/react/lib/Shimmer";
import { VirtualNetwork } from "../../../common/types";
import { useTranslation } from "react-i18next";
import useFetch from "../../Util/useFetch";
import { RequestLoggerEnum } from "../../../common/consts";

interface VirtualNetworkDropdownProps {
    selectedResourceGroupID: string;
    setVirtualNetwork: (virtualNetwork: VirtualNetwork | null) => void;
    setError: (error: any) => void;
}

const VirtualNetworkDropdown = React.memo((props: VirtualNetworkDropdownProps) => {
    const { t } = useTranslation();
    const { data: virtualNetworkList, loading: isVirtualNetworksLoading, error: virtualNetworkListError } = useFetch<VirtualNetwork>(props.selectedResourceGroupID, RequestLoggerEnum.VirtualNetwork_List);
    const virtualNetworks= useMemo(() => virtualNetworkList || [], [virtualNetworkList]);

    const [selectedKey, setSelectedKey] = React.useState<string>("");

    useEffect(() => {
        if (virtualNetworkListError) {
            props.setError(virtualNetworkListError);
        }
    }, [virtualNetworkListError]); 

    useEffect(() => {
        props.setVirtualNetwork(null);
        setSelectedKey("");
    }, [props.selectedResourceGroupID]); 

    // Build the set of dropdown items
    const options = useMemo(() => {
        virtualNetworks.sort((a,b)=> ( a.name < b.name ? -1 : (a.name > b.name) ? 1 : 0));
        const items: IDropdownOption[] = [];

        for (const virtualNetwork of virtualNetworks) {
            items.push({
                key: virtualNetwork.id,
                text: virtualNetwork.name,
                data: virtualNetwork
            });
        }

        return items;
    }, [virtualNetworks]);

    return (
        <>
            <Shimmer isDataLoaded={!isVirtualNetworksLoading}>
                <FilterableDropdown
                    options={options}
                    selectedKey={selectedKey}
                    onChange={(_event: React.FormEvent<HTMLDivElement>, virtualNetworkOption: any) => {
                        setSelectedKey(virtualNetworkOption.key as string);
                        const virtualNetwork = virtualNetworkList.find(virtualNetwork => virtualNetwork.id === virtualNetworkOption.key);
                        props.setVirtualNetwork(virtualNetwork || null);
                    }}
                    placeholder={t("provideExistingVirtualNetwork")}
                    disabled={props.selectedResourceGroupID === ""}
                    ariaLabel={t("virtualNetworkTitle")}
                />
            </Shimmer>
        </>
    );
});

VirtualNetworkDropdown.displayName = "VirtualNetwork-Dropdown"; // export name

export default VirtualNetworkDropdown;