/*
#####################
# Reset User Dialog #
#####################

Parent: `Session` 
Purpose: User confirmation screen for unmounting user settings   
Takes:
 - `hideResetUserDialog`
 - `toggleResetUserDialog`
 - `ResetUserSettings` 
Returns:
 - Makes a call to `toggleResetUserDialog()` which hides and shows the dialog
 - Makes a call to `ResetUserSettings()` that will unmount user settings.
*/

// External Components
import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { dialogFooterStyles } from "../../../common/styles";

// Interface
interface ResetUserDialogProps {
    // These are the call back functions and data from parent
    hideResetUserDialog: boolean;
    toggleResetUserDialog: () => void;
    ResetUserSettings: () => void;
}

// Component
const ResetUserDialog = (resetUserDialogProps: ResetUserDialogProps) => {
    const { t } = useTranslation();
    const labelId: string = useId("resetUserDialogLabel");
    const subTextId: string = useId("resetUserTextLabel");
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: { main: { minwidth: 450 } },
        }),
        [labelId, subTextId]
    );

    return (
        <Dialog
            hidden={resetUserDialogProps.hideResetUserDialog} //variable to hide
            onDismiss={resetUserDialogProps.toggleResetUserDialog} // function that hides
            dialogContentProps={{
                type: DialogType.normal,
                title: t("resetUserDialogHeader"),
                closeButtonAriaLabel: t("cancelButton"),
                subText: t("resetUserDialogMessage1"),
            }}
            modalProps={modalProps}
        >
            <DialogFooter className={dialogFooterStyles}>
                <PrimaryButton
                    onClick={resetUserDialogProps.ResetUserSettings} // Callsback for restart, does not hide by itself
                    text={t("resetUserButtontitle")}
                />
                <DefaultButton
                    onClick={resetUserDialogProps.toggleResetUserDialog}
                    text={t("cancelButton")}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default ResetUserDialog;
