/* 
#####################
# Location Dropdown #
#####################

-   **Based on**
    -   React View Location dropdown
        -   [Code](https://msazure.visualstudio.com/One/_git/AzureUX-PortalFx?path=/src/SDK/Framework.ReactView/azureportal-reactview/src/LocationDropdown.tsx&_a=contents&version=GBdev)
        -   [Demo](https://ms.portal.azure.com/?Microsoft_Azure_Playground=true#view/Microsoft_Azure_Playground/ReactControlsBlade/~/LocationDropdown)
-   **Allows user to create new element**: `false`
-   **Fetched Based on**: Subscription ID
-   **Inputs**
    -   `selectedStorageLocation`
        -   selected location
    -   `setSelectedStorageLocation`
        -   Callback function - update selected location for usersettings
*/

import * as React from "react";
import { SelectableOptionMenuItemType } from "@fluentui/react/lib/SelectableOption";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { useMemo } from "react";
import { Location } from "../../../common/types";
import { Trans, useTranslation } from "react-i18next";
import { DropdownOptionKey, storageLocationCostMapping} from "../../../common/consts";
import { ReactComponent as RegionalNudgeIcon } from "../../../common/images/RegionalNudge.svg";
import { Link } from "@fluentui/react";
import { getLocationList, getStorageLocationMapping } from "../../Util/Utilities";
import FilterableDropdown from "./FilterableDropdown";

interface LocationProps {
    selectedStorageLocation: string;
    setSelectedStorageLocation: (storageLocation: string) => void;
}

// Component
const LocationDropdown = (locationProps: LocationProps) => {
    const { t } = useTranslation();
    const showRegionalNudge = false; //Note: remove it after V2 fully rollout
    const storageLocationMapping = getStorageLocationMapping();
    const locationList: Array<Location> = getLocationList();

    const lowCostRegion = useMemo(() => {
        if (storageLocationCostMapping.has(locationProps.selectedStorageLocation.toLowerCase())) {
            const lowCostRegionName = storageLocationCostMapping.get(locationProps.selectedStorageLocation.toLowerCase());
            const lowCostRegion = locationList.find(location => location.name === lowCostRegionName);
            return {name: lowCostRegionName, displayName: "(" + t(`${lowCostRegion?.regionalDisplayName}`) + ") " + t(`${lowCostRegion?.displayName}`) };
        } else {
            return null;
        }
    }, [locationProps.selectedStorageLocation]);
    
    const options = useMemo(() => {
        return groupAndMapLocations(locationList);
    }, [locationList]);

    function groupAndMapLocations(visibleLocations: ReadonlyArray<Location>): IDropdownOption[] {
        const groupedAndMappedOptions: IDropdownOption[] = []; // Full list of options

        //Lists of grouped options
        const recommendedLocations: Array<Location> = []; // have clusters available
        const otherLocations: Array<Location> = []; // no clusters available

        const recommendedHeader = {
            key: DropdownOptionKey.RecommendedHeader,
            text: t("recommended"),
            itemType: SelectableOptionMenuItemType.Header,
        };

        const otherHeader = {
            key: DropdownOptionKey.OtherHeader,
            text: t("other"),
            itemType: SelectableOptionMenuItemType.Header,
        };

        visibleLocations.forEach((location: Location) => {
            if (location.name === storageLocationMapping[location.name]) {
                recommendedLocations.push(location);
            } else {
                otherLocations.push(location);
            }
        });

        if (recommendedLocations.length) {
            groupedAndMappedOptions.push(recommendedHeader);
            groupedAndMappedOptions.push(...mapLocationsToOptions(recommendedLocations));
            groupedAndMappedOptions.push({
                key: "recommendedDivider",
                text: "-",
                itemType: SelectableOptionMenuItemType.Divider,
            });
        }
        if (otherLocations.length) {
            groupedAndMappedOptions.push(otherHeader);
            groupedAndMappedOptions.push(...mapLocationsToOptions(otherLocations));
        }

        return groupedAndMappedOptions;
    }

    function mapLocationsToOptions(locations: Location[]): IDropdownOption[] {
        // sort groups or lists within section
        return locations
            .sort((locationA: Location, locationB: Location) => {
                if (locationA.regionalDisplayName === locationB.regionalDisplayName) {
                    return locationA.displayName < locationB.displayName ? -1 : 1;
                }
                return locationA.regionalDisplayName < locationB.regionalDisplayName ? -1 : 1;
            })
            .map((location: Location) => {
                return {
                    key: location.name,
                    // prettier-ignore
                    text: "(" + t(`${location.regionalDisplayName}`) + ") " + t(`${location.displayName}`), // clean name. Ex: `(US) Central US`
                    data: location
                };
            });
    }

    const handleLowCostRegionClick = () => {
        locationProps.setSelectedStorageLocation(lowCostRegion?.name || "");
    }
 
    return (
        <>
            <FilterableDropdown
                options={options}
                selectedKey={locationProps.selectedStorageLocation}
                onChange={(_event: React.FormEvent<HTMLDivElement>, locationOption: any) => {
                    locationProps.setSelectedStorageLocation(locationOption.key);
                }}
                ariaLabel={t("regionLabel")}
            />
            {
                showRegionalNudge && lowCostRegion && lowCostRegion.name != "" && lowCostRegion.displayName != ""
                ?
                <div style={{
                        display: 'flex', 
                    }}
                >
                    <RegionalNudgeIcon style={{marginRight: '2px'}}/>
                    <Trans i18nKey="regionalDemandShapingNudge">
                        <p style={{marginTop: '0px', fontSize: '12px'}}>
                            Consider selecting{" "}
                            <Link onClick={handleLowCostRegionClick}>
                                {{dispalyname: lowCostRegion.displayName}}
                            </Link>{" "}
                            {`to help reduce your costs and conserve energy where it&apos;s needed most.`}
                        </p>
                    </Trans>
                </div>
                :
                <div style={{ width: "100%", display: "inline-block" }} />
            }    
        </>
    );
};

export default LocationDropdown; // Export function