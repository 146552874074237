/*
##############
# Form Label #
##############

-   **Based on**:
    -   [React view](https://msazure.visualstudio.com/One/_git/AzureUX-PortalFx?path=/src/SDK/Framework.ReactView/azureportal-reactview/src/FormLabel.tsx&_a=contents&version=GBdev)
-   **Inputs**:
    -   `displayValue`
        -   Label name
    -   `nested`
        -   Optional boolean for nested arguments.
    -   `tooltip`
        -   Optional argument for a tooltip
    -   `ariaLabel`
        -   Optional argument
    -   `stackVertical`
        -   Stack the label above the dropdown
    -   There are others. check the file for a full list of arguments
-   **Purpose**: This component can wrap around another component to provide a consistent azure themed label.
*/

import * as React from "react";
import { Label, ILabelProps } from "@fluentui/react/lib/Label";
import { Stack, IStackItemStyles } from "@fluentui/react/lib/Stack";
import { Icon } from "@fluentui/react/lib/Icon";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { TooltipHost, ITooltipHostProps } from "@fluentui/react/lib/Tooltip";

import { useId } from "@uifabric/react-hooks/lib/useId";

const F1_KEY = "F1";

const classNames = mergeStyleSets({
    formLabel: {
        maxWidth: "calc(100% - 13px)",
        display: "inline-block",
    },
    formContainerInfoIcon: {
        cursor: "pointer",
        verticalAlign: "middle",
        paddingLeft: "5px",
    },
    formLabelContainer: {
        flex: "auto",
        minWidth: "230px",
        width: "100%",
    },
    formNestedLabelContainer: {
        flex: "initial",
        maxWidth: "207px",
        width: "100%",
    },
    formContainer: {
        width: "inherit !important",
        maxWidth: "calc(100% - 40px)",
        minWidth: "250px",
        flex: "auto",
    },
});

type LabelTooltipType = Pick<FormLabelProps, "tooltip"> &
    Pick<ITooltipHostProps, "componentRef" | "onTooltipToggle"> & { tabIndex: number };

/**
 * Returns Tooltip component
 */
const LabelTooltip = ({ componentRef, onTooltipToggle, tooltip, tabIndex }: LabelTooltipType) => {
    const tooltipId = useId("tooltip");
    return (
        <TooltipHost
            id={tooltipId}
            componentRef={componentRef}
            onTooltipToggle={onTooltipToggle}
            closeDelay={500}
            calloutProps={{ styles: { root: { borderStyle: "none" } } }}
            content={tooltip}
        >
            <Icon
                tabIndex={tabIndex}
                iconName="Info"
                aria-describedby={tooltipId}
                className={classNames.formContainerInfoIcon}
            />
        </TooltipHost>
    );
};

/**
 * Interface of a FormLabel control
 */
export type FormLabelProps = ILabelProps & FormLabelDefaultProps;

/**
 * Props for FormLabel framework Component
 */
export type FormLabelDefaultProps = {
    /**
     * Value of the label.
     */
    displayValue: string;
    /**
     * Optional. Shifts Label to the right to make it nested.
     */
    nested?: boolean;
    /**
     * Optional. Tooltip for the label.
     */
    tooltip?: ITooltipHostProps["content"];
    /**
     * @deprecated
     * Optional. Additional styles for the label container.
     */
    labelContainerStyles?: IStackItemStyles;
    /**
     * @deprecated
     * Optional. Additional styles for the form container.
     */
    formContainerStyles?: IStackItemStyles;
    /**
     * Optional. Label that the narrator will state. By default, will be the same as the displayValue.
     */
    ariaLabel?: string;
    /**
     * Optional. Stacks form label and form container vetically if set to true.
     */
    stackVertical?: boolean;
    /**
     * Optional. Additional styles for the label container.
     */
    labelContainerStyle?: React.CSSProperties;
    /**
     * Optional. Additional styles for the form container.
     */
    formContainerStyle?: React.CSSProperties;
};

/**
 * Type for FormLabelContext
 */
type FormLabelContextType = {
    /**
     * Id of label that identifies the form component.
     */
    ariaLabelledBy: string;
    /**
     * Boolean to describe if form component is required for accessibility.
     */
    ariaRequired: boolean;
    /**
     * Function that sets the disabled state of the form component.
     */
    setIsFormDisabled: (isFormDisabled: boolean) => void;
    /**
     * Identifier that signifies if the context has been set with values.
     */
    isEmptyContext: boolean;
};

const FormLabelContext = React.createContext<FormLabelContextType>({
    ariaLabelledBy: "",
    ariaRequired: false,
    setIsFormDisabled: () => {},
    isEmptyContext: true,
});

export const useFormLabelContext: (ignoreError?: boolean) => FormLabelContextType = (
    ignoreError?: boolean
): FormLabelContextType => {
    const context = React.useContext(FormLabelContext);
    if (context.isEmptyContext && !ignoreError) {
        console.error(
            "useFormLabelAria called incorrectly. No context was provided. Is this being called correctly by a child of FormLabel? If this error does not apply, use the ignoreError argument."
        );
    }
    return context;
};

export const FormLabel = (props: React.PropsWithChildren<FormLabelProps>) => {
    const tooltipRef: any = React.useRef();
    const tooltipVisibilityRef = React.useRef(false);
    const [isFormDisabled, setIsFormDisabled] = React.useState(false);
    const labelId = useId("label");
    const ariaInfo: FormLabelContextType = {
        ariaLabelledBy: labelId,
        ariaRequired: false,
        setIsFormDisabled,
        isEmptyContext: false,
    };

    const onTooltipToggle = (tooltipVisible: boolean) => {
        tooltipVisibilityRef.current = tooltipVisible;
    };

    const toggleTooltip = (e: React.KeyboardEvent) => {
        if (e.key === F1_KEY) {
            e.preventDefault(); // prevent browser help screen from showing
            if (tooltipVisibilityRef.current) {
                (tooltipRef.current as any).dismiss();
            } else {
                (tooltipRef.current as any).show();
            }
        }
    };

    return (
        <Stack
            onKeyDown={toggleTooltip}
            style={{ flexFlow: `wrap ${props.stackVertical ? "column" : "row"}` }}
        >
            {props.nested && <i className={"reactview-nestedresource"} />}
            <Stack.Item
                className={
                    props.nested
                        ? classNames.formNestedLabelContainer
                        : classNames.formLabelContainer
                }
                style={props.labelContainerStyle}
            >
                <Label
                    id={labelId}
                    styles={{ root: { "::after": { paddingRight: "0px" } } }}
                    className={classNames.formLabel}
                    {...props}
                >
                    {props.displayValue}
                </Label>
                {props.tooltip && (
                    <LabelTooltip
                        {...props}
                        tabIndex={isFormDisabled ? 0 : -1}
                        componentRef={tooltipRef}
                        onTooltipToggle={onTooltipToggle}
                    />
                )}
            </Stack.Item>
            <Stack.Item
                className={classNames.formContainer}
                align={props.stackVertical ? "start" : "center"}
                style={props.formContainerStyle}
            >
                <FormLabelContext.Provider value={ariaInfo}>
                    {props.children}
                </FormLabelContext.Provider>
            </Stack.Item>
        </Stack>
    );
};

/**
 * FormLabel framework component
 */
export default FormLabel;
