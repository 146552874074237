import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "../../localization/localizationResources";

const getLanguage = () => {
    const languageList = ["cs", "de", "es", "fr", "hu", "id", "it", "ja", "ko", "nl", "pl", "pt-br", "pt-pt", "ru", "sv", "tr", "zh-hans", "zh-hant"];
    const params = new URLSearchParams(window.location.search);
    const languageParams = params.get('l')?.split('.');
    let language = 'en';
    languageParams?.forEach(lang => {
      lang = lang.toLowerCase();
      if (languageList.indexOf(lang) != -1) {
        language = lang;
      }
    });
    return language;
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getLanguage() || "en",
        ns: ["cloudShellResource", "aciConnectResource", "serialConsoleResource"],
        defaultNS: "cloudShellResource",
        fallbackLng: {
            "de-CH": ["fr", "it"],
            "zh-Hant": ["zh-Hans", "en"],
            "es": ["fr"],
            "tr": ["de"],
            "default": ["en"]
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
