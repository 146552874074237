import { DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";

interface SaveDialogProps {
    hideSaveDialog: boolean;
    toggleSaveDialog: () => void;
    onSave: (fileName: string) => void;
    onClose: () => void;
}

const SaveDialog = (props: SaveDialogProps) => {
    const { t } = useTranslation();
    const [fileNameValue, setFileNameValue] = React.useState<string>("");
    const onChangeFileNameValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setFileNameValue(newValue || "");
        },
        []
    );
    const saveLabelId: string = useId("SaveDialogLabel");
    const saveModalProps = React.useMemo(
        () => ({
            titleAriaId: saveLabelId,
            isBlocking: true,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: {
                main: {
                    maxWidth: '40% !important',
                    minWidth: 500,
                    maxHeight: 300,
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                }
            }
        }),
        [saveLabelId]
    );

    const saveDialogContentPropsStyles = {
        content: {
            minWidth: '300px',
            paddingBottom: 0
          },
        title: { 
            paddingBottom: '10px',
            display: 'flex',
            alignItems: 'center',
        }
    };

    function save() {
        props.onSave(fileNameValue);
        props.toggleSaveDialog();
    }

    function close() {
        props.onClose();
        props.toggleSaveDialog();
    }
    return (
        <Dialog
            hidden={props.hideSaveDialog}
            onDismiss={props.toggleSaveDialog}
            dialogContentProps={{
                type: DialogType.normal,
                title: t("saveNewFileText"),
                closeButtonAriaLabel: t("dontSaveButton"),
                styles: saveDialogContentPropsStyles
            }}
            modalProps={saveModalProps}>
                <div data-testid="saveDialog"></div>
                <TextField label={t("saveNewFilePrompt")} value={fileNameValue} onChange={onChangeFileNameValue}/>
                <DialogFooter>
                    <PrimaryButton onClick={save} text={t("saveButton")} role="button" aria-label={t("saveButton")} disabled={!fileNameValue} data-testid="saveButtonSaveDialog"/>
                    <DefaultButton onClick={close} text={t("dontSaveButton")} role="button" aria-label={t("dontSaveButton")} />
                </DialogFooter>
            </Dialog>
    )
};
export default SaveDialog;