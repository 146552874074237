/*
############################
# General Error Dialog #
##################

-   **Inputs**:
    -   `hideErrorDialog`
        -   Boolean to hide and show
    -   `quit`
        -   Function to close this dialog
    -   `errorMessages`
        -   error messages to display
-   **Purpose**: Error dispaly dialog
*/

// External Components
import * as React from "react";
import {
    DialogType,
    Dialog,
    Link
} from "@fluentui/react";
import { ReactComponent as ErrorIcon } from "../../common/images/Error.svg";
import { useTranslation } from "react-i18next";

import { CorrelationIdContext } from "../../components/DataProvider/IdProviders";
import { useId } from "@fluentui/react-hooks";
import { postMessageHelper } from "../Util/Utilities";
import { PostMessageHelperType } from "../../common/consts";
import { ErrorMessages } from "../../common/types";

// Interface
interface ErrorDialogProps {
    hideErrorDialog: boolean;
    quit?: () => void;
    errorMessages?: ErrorMessages
}

// Component
const ErrorDialog= (errorDialogProps: ErrorDialogProps) => {
    const { t } = useTranslation();
    const ErrorLabelId: string = useId("ErrorDialogLabel");
    const correlationId = React.useContext(CorrelationIdContext);
    //modal Props
    const ErrorModalProps = React.useMemo(
        () => ({
            titleAriaId: ErrorLabelId,
            isBlocking: true,
            isModeless: true,
            forceFocusInsideTrap: false,
            styles: { 
                main: { 
                    maxWidth: '40% !important',
                    minWidth: 500,
                    maxHeight: 300,
                    minHeight: 100,
                    padding: 0,
                    margin: 0,
                }
            }
        }),
        [ErrorLabelId]
    );

    const ErrorDialogContentPropsStyles = {
        content: {
          minWidth: '300px',
          paddingBottom: 0
        },
        title: { 
            paddingBottom: '10px',
        }
    };

    const errorHeaderContainerStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    const ErrorHeader = () => {
        return <div style={errorHeaderContainerStyle}><ErrorIcon height={20} width={20} style={{marginRight: '8px'}}/><span>{errorDialogProps.errorMessages?.header || t("errorHeader")}</span></div>;
    }

    const quit = () => {
        if (errorDialogProps.quit) {
            errorDialogProps.quit();
        } else {
            postMessageHelper(PostMessageHelperType.Close, '');
        }
    }

    return (
        <Dialog
            hidden={errorDialogProps.hideErrorDialog}
            onDismiss={quit}
            dialogContentProps={{
                type: DialogType.normal,
                title: ErrorHeader(),
                closeButtonAriaLabel: t("cancelButton"),
                showCloseButton: true,
                styles: ErrorDialogContentPropsStyles
            }}
            modalProps={ErrorModalProps}

        >
            <div>
                <p>{errorDialogProps.errorMessages?.content || t("unknownFailureContent")}</p>
                {   
                    errorDialogProps.errorMessages?.header == t("noSubscriptionHeader")  
                    &&
                    <Link href="https://aka.ms/cloudshell/free" target="_blank">
                        {t("noSubscriptionPrompt")}
                    </Link>
                }
                <p>{t("correlationId")}: {correlationId.correlationId}</p>
            </div>
        </Dialog>
    );
};

export default ErrorDialog;
