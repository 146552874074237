/*
#############
# index.tsx #
#############

-   **Inputs**: None
-   **Purpose**: This is the main index element. It contains the render call to `App.tsx` as well as the setup for `i18n` (localization).
-   **To do**: 
    -   `i18n` should be initialized in this component
-   **Note**: This component should be modified as little as possible

*/

// Imports
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { mergeStyles } from "@fluentui/react";
import "./components/Util/i18n";

// Inject some global styles
mergeStyles({
    ":global(body,html,#root,.body-110,#root>div)": {
        margin: 0,
        padding: 0,
        height: "100%",
        width: "auto",
    },
});

ReactDOM.render(<App />, document.getElementById("root"));
