/*
##################
# Storage Creation #
##################

-   **Inputs**:
    -   `currentTheme`
        -   A theme object that contains the current color variables
    -   `showStorageCreation`
        -   A boolean variable for displaying or hiding the `MountStorage` component
    -   `setMountState`
        -   Show a different modal
    -   `Quit`
        -   A call back function that can close the application
        -   It should be the same as `OSSelect`
-   **Purpose**: This component lets users to create a storage account.
-   **To do**:
    -   Backend communication
*/

// Imported packages
import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
    mergeStyleSets,
    FontWeights,
    Modal,
    Stack,
    StackItem,
    Theme,
    IStackItemStyles,
} from "@fluentui/react";
import { IconButton, PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";

// Handmade components
import LocationDropdown from "../Selector/LocationDropdown";
import SubscriptionDropdown from "../Selector/SubscriptionDropdown";
import ResourceGroupDropdown from "../Selector/ResourceGroupDropdown";
import { MountState, TelemetryLoggerEnum } from "../../../common/consts";
import FormLabel from "../FormLabel";
import { useTranslation } from "react-i18next";
import { OnboardingInfo, ResourceGroup, Subscription } from "../../../common/types";
import FileShare from "../TextField/FileShareField";
import { useState } from "react";
import StorageAccount from "../TextField/StorageAccount";
import { useLogger } from "../../Util/Logger";
import { getARMEndpoint } from "../../Util/Utilities";
import { smallResolutionDialogStyles } from "../../../common/styles";

// Interface components
interface StorageCreationProps {
    currentTheme: Theme;
    isResourceGroupsLoading: boolean;
    subscriptionsList: Subscription[];
    resourceGroupList: ResourceGroup[];
    showStorageCreation: boolean; // variable to display or not
    setMountState: (state: MountState) => void;
    Quit: () => void; // call back function that can close the application
    selectedSubscription: Subscription | null;
    selectedResourceGroup: string;
    setSelectedResourceGroup: (resourceGroup: string) => void;
    setSelectedSubscription: (subscription: Subscription | null) => void;
    applyOnboardingInfo: (mountState: MountState, onboardingInfo: OnboardingInfo) => void;
}

const customStackItemStyles: Partial<IStackItemStyles> = {
    root: {
        // Make the items take up the full width of the parent by default
        flex: "1 1 100%",
        minWidth: 0,
        selectors: {
            // 600px is an arbitrary width, use whatever is best for your use case
            "@media screen and (min-width: 600px)": {
                // At larger viewport widths allow the items to be side-by-side
                flex: 1, // shorthand for '1 1 0%'
            },
        },
    },
};

export const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        alignItems: "stretch",
        maxWidth: '800px',
        maxHeight: "100%",
        width: "70%",
        selectors: smallResolutionDialogStyles,
    },
    header: [
        {
            flex: "1 1 auto",
            fontSize: 18,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "0px 0px 0px 24px",
        },
    ],
    body: {
        flex: "4 4 auto",
        padding: "0 0px 5px 26px",
        overflowY: "hidden",
        selectors: {
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
});

// Component
const StorageCreation = (storageCreationProps: StorageCreationProps) => {
    const titleId = useId("StorageCreation");
    const { t } = useTranslation();
    const logger = useLogger(getARMEndpoint(), {});
    const [createdResourceGroup, setCreatedResourceGroup] = useState<ResourceGroup | null>(null);
    const [selectedStorageLocation, setSelectedStorageLocation] = React.useState("");
    const [createdStorageAccount, setCreatedStorageAccount] = useState("");
    const [createdFileShare, setCreatedFileShare] = useState("");
    const [valid, setValid] = useState<boolean>(false);
    
    return (
        <Modal
            titleAriaId={titleId}
            isOpen={storageCreationProps.showStorageCreation}
            onDismiss={storageCreationProps.Quit}
            isBlocking={true}
            containerClassName={contentStyles.container}
        >
            <div className={contentStyles.header}>
                <header role="heading" id={titleId}>{t("createStorageAccount")}</header>
                <IconButton
                    styles={{
                        root: {
                            marginLeft: "auto",
                            color: storageCreationProps.currentTheme.semanticColors.bodyText,
                            marginRight: "2px",
                        },
                        rootHovered: {
                            color: storageCreationProps.currentTheme.semanticColors.bodyBackground,
                            background: "#E00B1C",
                        },
                    }}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel={t("cancelButton")}
                    onClick={storageCreationProps.Quit}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack horizontal wrap>
                    <StackItem grow={1} styles={customStackItemStyles}>
                        <Stack>
                            <StackItem>
                                <FormLabel
                                    aria-label="subscriptionLabel"
                                    displayValue={t("subscriptionLabel")}
                                    required
                                >
                                    <SubscriptionDropdown
                                        subscriptionList={storageCreationProps.subscriptionsList}
                                        selectedSubscription={storageCreationProps.selectedSubscription}
                                        setSelectedSubscription={storageCreationProps.setSelectedSubscription}
                                    />
                                </FormLabel>
                            </StackItem>
                            <StackItem>
                                <FormLabel
                                    aria-label="resourceGroupLabel"
                                    displayValue={t("resourceGroupLabel")}
                                    required={true}
                                    disabled={false}
                                >
                                    <ResourceGroupDropdown
                                        allowRGCreation={true}
                                        isResourceGroupsLoading={storageCreationProps.isResourceGroupsLoading}
                                        createdResourceGroup={createdResourceGroup}
                                        setCreatedResourceGroup={setCreatedResourceGroup}
                                        resourceGroupList={storageCreationProps.resourceGroupList}
                                        selectedResourceGroupID={storageCreationProps.selectedResourceGroup}
                                        selectedSubscriptionID={storageCreationProps.selectedSubscription?.subscriptionId || ""}
                                        setSelectedResourceGroup={storageCreationProps.setSelectedResourceGroup}
                                    />
                                </FormLabel>
                            </StackItem>
                            <StackItem>
                                <FormLabel
                                    aria-label="regionLabel"
                                    displayValue={t("regionLabel")}
                                    required
                                >
                                    <LocationDropdown
                                        selectedStorageLocation={selectedStorageLocation}
                                        setSelectedStorageLocation={setSelectedStorageLocation}
                                    />
                                </FormLabel>
                            </StackItem>
                        </Stack>
                    </StackItem>
                    <StackItem grow={1} styles={customStackItemStyles}>
                        <Stack>
                            <StorageAccount
                                selectedSubscriptionID={storageCreationProps.selectedSubscription?.subscriptionId || ""}
                                createdStorageAccount={createdStorageAccount}
                                setCreatedStorageAccount={setCreatedStorageAccount}
                                setValid={setValid}
                            />
                            <FileShare 
                                fileShare={createdFileShare}
                                setFileShare={setCreatedFileShare}
                            />
                        </Stack>
                    </StackItem>
                </Stack>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        marginTop: "5px",
                    }}
                >
                    <PrimaryButton
                        onClick={() => {
                            const onboardingInfo = {
                                location: selectedStorageLocation,
                                resourceGroupID: storageCreationProps.selectedResourceGroup,
                                fileShareName: createdFileShare,
                                storageAccountName: createdStorageAccount,
                                diskSize: 5
                            }
                            logger.clientTelemetry(TelemetryLoggerEnum.Onboarding_StorageCreation, { region: selectedStorageLocation }, {}, 0, null);
                            storageCreationProps.applyOnboardingInfo(MountState.StorageCreation, onboardingInfo);
                        }}
                        disabled={storageCreationProps.selectedSubscription === null || selectedStorageLocation === "" || storageCreationProps.selectedResourceGroup === "" || createdFileShare === "" || createdStorageAccount === "" || !valid} 
                        text={t("createButton")}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <DefaultButton
                        onClick={() => {
                            storageCreationProps.setMountState(MountState.IntermediateMount);
                        }}
                        text={t("previousButton")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default StorageCreation;
