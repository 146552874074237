import axios from "axios";

export const upload = (options: any): Promise<any> => {
    return axios(options);
};

const FileManager = {
    upload
};

export default FileManager;