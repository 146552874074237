import EditorTitlebar from './Titlebar/EditorTitlebar';
import Editor, { MonacoEditorRef } from './Editor';
import React from 'react';
import EditorTerminalSeparator from "./EditorTerminalSeparator";
import { useTranslation } from "react-i18next";
import { AxiosHttpClient } from "../../Util/HttpClient";
import { IEditorRef } from "./IEditorRef";

interface EditorWrapperProps {
    editorHeight: string;
    consoleUri: string;
    rootDirectory: string;
    maxHeight: number;
    onEditorHeightChange: (height: number) => void;
    displayFileName: string;
    displayFilepath: string;
    focusTerminal: (isTerminalFocused: boolean) => void;
}
const EditorWrapper = (editorWrapperProps: EditorWrapperProps) => {
    const { t } = useTranslation();
    const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
    const [saveAsClicked, setSaveAsClicked] = React.useState<boolean>(false);
    const resetSaveClicked = () => {setSaveClicked(false); setSaveAsClicked(false);}
    const [fileName, setFileName] = React.useState<string>(t("untitledFileHeader"));
    const [newFileName, setNewFileName] = React.useState<string>(t("untitledFileHeader"));
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [isClosing, setIsClosing] = React.useState<boolean>(false);
    const [editorHeight, setEditorHeight] = React.useState<number>(100);
    const axiosInstance = new AxiosHttpClient();
    const editorRef = React.useRef<IEditorRef>(null) as React.MutableRefObject<IEditorRef>;
    const monacoRef = React.useRef<MonacoEditorRef>(null) as React.MutableRefObject<MonacoEditorRef>;
    const [openFileDialogVisible, setOpenFileDialogVisible] = React.useState<boolean>(false);
    const resetOpenFileDialog = () => setOpenFileDialogVisible(false);
    
    React.useEffect(() => {
        editorWrapperProps.onEditorHeightChange(editorHeight);
    }, [editorHeight]);

    React.useEffect(() => {
        setNewFileName(editorWrapperProps.displayFileName);
    }, [editorWrapperProps.displayFileName]);
    

    return (
        <div id='editor-wrapper' style={{
            flex: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            fontFamily: 'Segoe,"Segoe UI Emoji", Arial, Helvetica, sans-serif',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0px',
            overflow: 'hidden'
        }}>
            <EditorTitlebar 
                closeEditor={() => setIsClosing(true)}
                saveFileClicked={(isSaveAs: boolean) => {if(isSaveAs) setSaveAsClicked(true); else setSaveClicked(true);}}
                openFileClicked={() => setOpenFileDialogVisible(true)}
                fileName={fileName}
                isDirty={isDirty}></EditorTitlebar>
            <Editor 
                height={editorWrapperProps.editorHeight}
                saveClicked={saveClicked}
                saveAsClicked={saveAsClicked}
                resetSaveClicked={resetSaveClicked}
                consoleUri={editorWrapperProps.consoleUri}
                rootDirectory={editorWrapperProps.rootDirectory}
                onFileNameChange={(fileName) => {
                    setFileName(fileName);
                } }
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                isClosing={isClosing}
                setIsClosing={setIsClosing}
                displayFileName={newFileName}
                displayFilepath={editorWrapperProps.displayFilepath}
                axiosInstance={axiosInstance}
                editorRef={editorRef}
                monacoRef={monacoRef}
                openFileDialogVisible={openFileDialogVisible}
                resetOpenFileDialog={resetOpenFileDialog}
                focusTerminal={(isTerminalFocused: boolean) => editorWrapperProps.focusTerminal(isTerminalFocused)}></Editor>
            <EditorTerminalSeparator
                currentEditorHeight={editorHeight}
                setEditorHeight={setEditorHeight}
                maxHeight={editorWrapperProps.maxHeight}
            />
        </div>
    );
}
export default EditorWrapper;